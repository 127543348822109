@charset "utf-8";

/* modBlock
------------------------------------------------------------*/

.modBlockInner{
    padding:70px 0;
    @include tabletP{
        padding:40px 0;
    }
    
    @include sphoneP{
        padding:20px 0;
    }
}

.modBlockFrame{
    background-image:url(../img/bgPattern01.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame02{
    background-color:#FFFDF8;
}

.modBlockFrame03{
    background-color:#F6F4F0;
}

.modBlockFrame04{
    background-image:url(../img/bgPattern03.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}

.modBlockFrame05{
    background-image:url(../img/bgPattern02.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}

.modBlockFrame06{
    background-image:url(../img/bgPattern04.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}

.modBlockFrame07{
    background-color:#FFE5D5;
}

.modBlockFrame08{
    background-image:url(../img/bgPattern05.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame09{
    background:#f8f7f4;
}
.modBlockFrame10{
    background-image:url(../img/bgPattern06.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame11{
    background-image:url(../img/bgPattern07.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame12{
    background-image:url(../img/bgPattern08.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame13{
    background-image:url(../img/bgPattern09.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}
.modBlockFrame14{
    background-image:url(../img/bgPattern10.jpg);
    background-size: 100% auto;
    background-position:center;
    background-repeat: repeat-y;
}

.modBlockNews{
    overflow:hidden;
    
    &_link{
        margin-bottom: 15px;
        text-align: right;

        .modTitle02 {
          margin-bottom:10px;
        }
    }
    
    .container{
        max-width:980px;
    }
    
    .tabMenu{
        margin-bottom:30px;
        
        ul{
            display:table;
            width:100%;
            @include sphoneP{
                display:block;
                text-align:center;
            }
        }
        
        li{
            display:table-cell;
            vertical-align:middle;
            border-right:solid 1px #000;
            @include sphoneP{
                display:inline-block;
                border-right:none;
                &:after{
                    content:"\7C";
                }
            }
            &:last-child{
                border-right:none;
            }
        }
        
        a{
            display:block;
            text-align:center;
            @include fz(18);
            line-height:1.3;
			text-decoration:none;
            @include tabletP{
                @include fz(16);
            }
            @include sphoneP{
                display:inline-block;
                padding:8px;
                @include fz(14);
            }
            &:hover{
                color:$subColor;
            }
        }
        
        .active{
            a{
                color:$subColor;
            }
        }
    }
    
    .tabContents{
    }
    
    .sliderWrap{
        position:relative;
        @include tabletP{
            padding:0 30px;
        }
    }
    
    .slick-arrow{
        border:none;
        position:absolute;
        top:0;
        bottom:0;
        margin:auto;
        background-color:transparent;
        background-position:0 0;
        background-repeat:no-repeat;
        background-size:50px auto;
        width:50px;
        height:50px;
        text-indent:-9999px;
        z-index:100;
        @include sphoneP{
        	background-size:40px auto;
            width:40px;
            height:40px;
        }
        &:hover{
            opacity:0.8;
        }
        &:focus{
            outline:none;
        }
    }
    
    .slick-next{
        background-image:url(../img/arrow01.svg);
        right:-60px;
        @include tabletP{
            right:-30px;
        }
    }
    
    .slick-prev{
        background-image:url(../img/arrow01L.svg);
        left:-60px;
        @include tabletP{
            left:-30px;
        }
    }
    
    .slide{
        width:245px;
        
        a{
            display:block;
            background-color:#F2F2F2;
            border-right:solid 1px #eee;
            position:relative;
            text-decoration:none;
            @include sphoneP{
                border-right:none;
            }
            &:hover{
                .body{
                    background-color:#fff;
                }
            }
            
            &:after{
                content:"";
                position: absolute;
                bottom: 0;
                right: 0;
                height: 0;
                width: 0px;
                margin: auto;
                border: 10px solid transparent;
                border-bottom: 10px solid #ccc;
                border-right: 10px solid #ccc;
            }
        }
        &:nth-child(even){
            a{
                @include tabletP{
                	border-right:none;
                }
            }
        }
        
        &:nth-child(4n){
            a{
                border-right:none;
            }
        }
        
        .image{
            img{
                width:100%;
            }
        }

        &.new{
            .image:before{
                content:"";
                background-image: url(../img/iconNew.png);
                background-position: 0 0;
                background-repeat: no-repeat;
                width: 58px;
                height: 58px;
                position: absolute;
                top:0;
                left:0;
            }
        }
        
        
        .body{
            padding:10px 14px 30px;
            color:#666;
            @include tabletP{
            	padding:10px 10px 15px;
            }
        }
        
        .head{
            display:table;
            width:100%;
            table-layout:fixed;
            margin-bottom:15px;
            .date,.category{
                display:table-cell;
                vertical-align:middle;
            }
            
            .date{
                width:6em;
            }
        }
        
        .date{
            @include fz(13);
        }
        
        .category{
            text-align:right;
         }
        
        .title{
            margin-bottom:8px;
            overflow:hidden;
            height:44px;
            line-height:22px;
            @include fz(16);
            font-weight:bold;
            span{
                @include line-clamp(2)
            }
        }
        
        .txt{
            overflow:hidden;
            height:40px;
            line-height:20px;
            @include fz(13);
            span{
                @include line-clamp(2)
            }
        }
    }
/* .modBlockNews */}

.modBlockEntryDetail{
    
    .body{
        background-color:#fff;
        border-radius:5px 5px 0 0;
        padding:40px 0;
        @include sphoneP{
            padding:20px 0;
        }
    }
    
    
    .head{
        background-image:url(../img/line.png);
        background-position:left bottom;
        background-repeat:repeat-x;
        display:table;
        width:100%;
        table-layout:fixed;
        max-width:710px;
        margin:0 auto 46px;
        padding: 0 0 18px;
        @include sphoneP{
            display:block;
            width:auto;
            padding:0 10px 15px;
            background-size:auto 2px;
        }
        
        @include widhtWide{
        	max-width:910px;
        }
        
        .left,.right{
            display:table-cell;
            vertical-align:bottom;
            @include sphoneP{
                display:block;
            }
        }
        
        .top{
            margin-bottom:6px;
        }
        
        .date{
            display:inline;
            margin:0 10px 0 0;
            color:#666666;
            font-weight:bold;
            @include fz(18);
            @include sphoneP{
                @include fz(14);
            }
        }
        
        .category{
            @include inline-block;
            font-size:0;
            vertical-align:super;
        }
        
        .title{
            color:#4f4f4f;
            font-weight:bold;
            @include fz(23);
            line-height:1.5;
            @include sphoneP{
                @include fz(18);
            }
        }
        
        .right{
            width:220px;
            text-align:right;
            @include sphoneP{
                width:auto;
            }
        }
        
        .sns{
            font-size:0;
            
            li{
                display:inline;
                margin:0 0 0 8px;
            }
            
            img{
                width:40px;
                @include sphoneP{
                    width:30px;
                }
            }
            
            a{
                &:hover{
                    opacity:0.8;
                }
            }
        }
    }
    
    .main{
        max-width:700px;
        margin: 0 auto;
        padding: 0 10px;
        @include sphoneP{
        }
        
        .modPartsTxt{
            max-width:700px;
            margin-right:auto;
            margin-left:auto;
            @include fz(18);
            @include sphoneP{
                @include fz(15);
            }
        }
        
        .btnPdf{
            max-width:402px;
            margin:75px auto 0;
            @include sphoneP{
                margin-top:30px;
            }
        }
    }
    
    .foot{
        background-color:#F2EBE9;
        padding:30px 0;
        margin:0 0 15px;
        border-radius:0 0 5px 5px;
        color:#4d4d4d;
        text-align:center;
        @include sphoneP{
            padding:15px 0;
        }
        
        .txt{
            margin-bottom:10px;
        }
        
        .sns{
            font-size:0;
            
            li{
                display:inline;
                margin:0 8px;
                @include sphoneP{
                    margin:0 4px;
                }
            }
            
            img{
                width:40px;
                @include sphoneP{
                    width:30px;
                }
            }
            
            a{
                &:hover{
                    opacity:0.8;
                }
            }
        }
        
    }
    
    .btnFoot{
        text-align:right;
        
        
        @include sphoneP{
            text-align:center;
            a{
                display:block;
            }
        }
    }
/* .modBlockEntryDetail */}

.modBlock01 {
	@include fz(18);
    font-size: 1.8rem;
    border: 1px solid #f17083;
    border-radius: 10px;
    background-color: #fff;
	padding:40px 60px;
	text-align:center;
	margin-bottom:40px;
	@include sphoneP{
		@include fz(16);
		padding:20px 30px;
	}
	&_type02 {
		border: none;
	}
/* .modBlock01 */}



.modBlockCaseBox {
  
  ul {
    @include clearfix;
  }
  
 .box {
   width:47%;
   float:left;
   border:1px solid $mainColor04;
   border-radius:5px;
   margin-bottom:40px;
   background:#fff;

    @include sphoneP{
      float:none;
      width:100%;
      margin-bottom:20px;
    }
   
   &:nth-child(even) {
     float:right;

      @include sphoneP{
        float:none;
      }
   }
   
   .title {
     color:$mainColor04;
     font-family:$fontMincho;
     @include fz(27);
     text-align:center;
     background:url(../img/bgPattern02.jpg) repeat top left;
     padding:15px 5px;
     border-radius:4px 4px 0 0;

    @include sphoneP{
      @include fz(18);
    }
     
     .small {
       @include fz(22);
       margin-left:5px;

        @include sphoneP{
          @include fz(12);
        }

     }
   }
   .txt {
     padding:20px 25px;

      @include sphoneP{
        padding:15px;
      }
   }
 }
 .boxLong {
   width:100%;
   float:none;
 }
}






























