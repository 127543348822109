@charset "utf-8";

/* wysiwyg
------------------------------------------------------------*/
%modParts{
    max-width:710px;
	margin: 0 auto 40px;
    
    @include widhtWide{
        max-width:910px;
    }
    @include sphoneP{
        margin-bottom:20px;
    }
}

.modPartsImage{
    @extend %modParts;
	text-align:center;
	color:#959595;
	@include fz(11);
	.caption{
		margin:5px 0 0;
	}
	a:hover{
		opacity:0.8;
	}
}

.modPartsTitle{
    // background-image:url(../img/line.png);
    // background-position:left bottom;
    // background-repeat:repeat-x;
    border-bottom: solid 1px #333;
    padding: 0 0 4px;
    margin:0 0 20px;
    @include fz(18);
    font-weight: bold;
    @include sphoneP{
        @include fz(14);
    }
}

.modPartsImageList{
    @extend %modParts;
    overflow:hidden;
    text-align:center;
    
    ul{
        @include clearfix;
        margin:0 -1%;
    }
    
    li{
        float:left;
        width:48%;
        margin:0 1%;
        @include sphoneP{
            margin:0 0 10px;
            float:none;
            width:auto;
        }
    }
    
    &_column03{
        li{
            width:31.3%;
            @include sphoneP{
                width:auto;
                margin:0 0 10px;
            }
        }    
    }


}

.modPartsYoutube {
	max-width:560px;
	margin-left:auto;
	margin-right:auto;
    @extend %modParts;

	@include tabletP{
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		iframe {
		  position: absolute;
		  top: 0;
		  right: 0;
		  width: 100% !important;
		  height: 100% !important;
		}
	
	}
}

.modPartsTxt{
    @extend %modParts;
	iframe{
		width:100%;
		height:400px;
		@include tabletP{
			height:250px;
		}
	}

	ul {
		margin-left: 2em;
	
		li {
			list-style: disc;
			
		}
	}
	ol {
		margin-left: 2.2em;
	li {
		list-style: decimal;
		
	}
	}
	strong,
	th {
		font-weight:bold;
	}
	
	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	img.alignright {
		padding: 4px;
		margin: 0 0 2px 7px;
		display: inline;
	}
	img.alignleft {
		padding: 4px;
		margin: 0 7px 2px 0;
		display: inline;
	}
	.alignright {
		float: right;
	}
	.alignleft {
		float: left;
	}
	strong{
		font-weight:bold;
	}

}

.modPartsSet02{
    max-width:710px;
    margin:0 auto;
    
    @include widhtWide{
        max-width:910px;
    }
    
    .inner{
        @include clearfix;
        margin:0 -2%;    
        @include sphoneP{
            margin: 0;
        }
    }
    
    .box{
        float:left;
        width:46%;
        margin:0 2%;
        @include sphoneP{
            float: none;
            width: auto;
            margin: 0 0 20px;
        }
    }
}