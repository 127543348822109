@charset "utf-8";

/* modForm
------------------------------------------------------------*/
.modFormTbl{
    table-layout:fixed;
    @include sphoneP{
        display:block;
    }
    
    tr,tbody,th,td{
        @include sphoneP{
            display:block;
        }
    }
    
    th,td{
        padding-bottom:30px;
        vertical-align:top;
        @include sphoneP{
            padding-bottom:0;
        }
    }
    
    th{
        width:260px;
        padding-top:0.5em;
        @include sphoneP{
            width:auto;
            padding:0;
            margin:0 0 10px;
        }
    }
    
    td{
        @include sphoneP{
            margin-bottom:20px;
        }
    }
/* .modFormTbl */}

.modFormRequired{
    @include inline-block;
    background-image:url(../img/iconCloverRequired.png);
    background-position: right center;
    background-repeat:no-repeat;
    background-size:22px auto;
    padding:0 30px 0 0;
    @include sphoneP{
        background-size:15px auto;
        padding-right:20px;
    }
}

.modFormName{
    display:table;
    width:100%;
    table-layout:fixed;
    
    li{
        display:table-cell;
        vertical-align:middle;
        padding:0 7px 0 0;
        &:last-child{
            padding:0 0 0 7px;
        }
    }
/* .modFormName */}

.modFormDate{
    display:table;
    width:100%;
    table-layout:fixed;
    
    li{
        display:table-cell;
        vertical-align:middle;
    }
    
    select{
        @include sphoneP{
            padding:6px 3px;
        }
    }
    
    .txt{
        width:46px;
        text-align:center;
        @include sphoneP{
            width:30px;
        }
    }
/* .modFormDate */}

.modFormTel{
    @include clearfix;
    ul{
        margin:0 -1%;
    }
    
    li{
        float:left;
        width:31.3%;
        margin:0 1%;
    }
/* .modFormTel */}

.modFormAddr{
    li{
        margin-bottom:15px;
    }
    
    .zip{
        display:table;
        .input,.btn,.txt{
            display:table-cell;
            vertical-align:middle;
        }
        
        .input{
            width:227px;
            padding:0 17px 0 0;
            @include tabletP{
                width:150px;
            }
            @include sphoneP{
                width:100px;
                padding-right:8px;
            }
        }
        
        .btn{
            width:150px;
            padding:0 17px 0 0;
            @include sphoneP{
                width:100px;
                padding-right:8px;
            }
            a{
                display:block;
                padding:4px 0;
                background-color:#A3A3A3;
                border-radius:5px;
                box-shadow: #828282 0 4px 0 ;
                color:#fff;
                text-decoration:none;
                @include fz(16);
                text-align:center;
                @include sphoneP{
                    @include fz(13);
                }
                &:hover{
                    background-color:#828282;
                }
            }
        }
        
        .txt{
            @include sphoneP{
                @include fz(12);
            }
        }
    }
    
    
    
/* .modFormAddr */}

.modFormCheck{
    @include fz(16);
    @include sphoneP{
        @include fz(14);
    }
    
    a:hover{
        opacity:0.6;
    }
    
    label{
        display:block;
        position:relative;
        background-color:#fff;
        padding:10px ;
        border-radius:5px;
    //     .icon{
    //         display:block;
    //         position:absolute;
    //         top:0;
    //         left:10px;
    //         bottom:0;
    //         margin:auto;
    //         width:30px;
    //         height:30px;
    //         border-radius:5px;
    //         background-color:#EF7F87;
    //     }
    }
    // input:checked + label{
    //      .icon{
    //          &:after{
    //              content:$fa-check;
    //              position:absolute;
    //              top:0;
    //              bottom:0;
    //              width:100%;
    //              text-align:center;
    //              @include iconFont;
    //              height:1em;
    //              margin:auto;
    //              color:#fff;
    //              @include fz(18);
    //          }
    //      }
    // }
    
    input{
        //display:none;
        margin-right: 5px;
    }
/* .modFormCheck */}

.modFormSubTxt{
    margin:5px 0 0;
}

.modFormErrorTxt{
    margin:6px 0 0;
    color:#ff4a4b;
    font-weight:bold;
}

.modFormConf{
    
    dt{
        border-bottom:dashed 2px #F3DAD1;
        margin:0 0 10px;
        padding:0 0 4px;
    }
    
    dd{
        padding:0 22px;
        margin:0 0 50px;
        @include sphoneP{
            padding:0 ;
            margin:0 0 20px;
        }
    }
    
/* .modFormConf */}