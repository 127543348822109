@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}

input[type="submit"],input[type="button"],button,label,select{
	cursor:pointer;
}

input[type="text"],input[type="email"],input[type="password"],input[type="button"],textarea,input[type="submit"],input[type="password"],button,label{
	-webkit-appearance: none;
}


.clearfix{
	@include clearfix;
}

/* link
------------------------------------------------------------*/
a { color: $baseLinkColor; text-decoration: underline;
	&:hover {
        opacity: 0.7;
        @include tabletP{
            opacity: 1;
        }
    }
    &:active{
        opacity: 0.7; 
        @include tabletP{
            opacity: 1;
        }       
    }
}

/* Fonts
------------------------------------------------------------*/
html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word; 
}
body>div,input,button,textarea,select
{
	@include fz;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
    @include sphoneP{
        @include fz(14);
        line-height:1.5;
    }
}


/* form
------------------------------------------------------------*/
input[type="text"],input[type="email"],input[type="password"],textarea,select { 
	background-color:#fff;
    border:solid 3px #fff;
	padding:8px 20px;
	@include box-sizing;
    @include transition(all 0.1s linear);
    border-radius:5px;
	@include fz(15);
    @include sphoneP{
        border-width:1px;
    }
    
    &:focus{
        border-color:#A3A3A3;
        outline:none;
    }
    
    &.inline{
        width:auto;
    }
	
	&.small{
		max-width:240px;
	}
	
	@include tabletP{
		@include fz(12);
	}
	&.error{
		border-color:#ff4a4b!important;
	}
}

input[type="text"],input[type="email"],input[type="password"],textarea,select{ 
	width:100%;
}

select{
}

$placeholderColor :#999999;

textarea{
	vertical-align:top;

	&::-webkit-input-placeholder{
		color:$placeholderColor;
	}
	&::-moz-placeholder{
		color:$placeholderColor;
	}
	&:-ms-input-placeholder{
		color:$placeholderColor;
	}
}

input{
	&::-webkit-input-placeholder{
		color:$placeholderColor;
	}
	&::-moz-placeholder{
		color:$placeholderColor;
	}
	&:-ms-input-placeholder{
		color:$placeholderColor;
	}
}

input[type="text"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
textarea[disabled]{
    background-color:#ECEBEB;
}

input[type="text"][readonly],
input[type="email"][readonly],
input[type="password"][readonly],
textarea[readonly]{
}

/* color
------------------------------------------------------------*/
.baseTestColor{
    margin-bottom:6px;
    
    li{
        display:inline-block;
        padding:3px 10px;
        margin:0 0 4px;
    }
    .mainColor{
        background-color:$mainColor;
        color:#fff;
    }
    .mainColor02{
        background-color:$mainColor02;
    }
    .mainColor03{
        background-color:$mainColor03;
        color:#fff;
    }
    .mainColor04{
        background-color:$mainColor04;
        color:#fff;
    }
    .subColor{
        background-color:$subColor;
        color:#fff;
    }
    .subColor02{
        background-color:$subColor02;
        color:#fff;
    }
    .subColor03{
        background-color:$subColor03;
        color:#fff;
    }
    .hoverColor{
        background-color:$hoverColor;
        color:#fff;
    }
    .hoverColor02{
        background-color:$hoverColor02;
    }
    .hoverColor03{
        background-color:$hoverColor03;
    }

    .newCateColor{
        color:#fff;
        $categoryLen:6;

        @for $i from 1 through $categoryLen {
          &0#{$i} {
            @extend .newCateColor;
            background-color:nth($newCateColor,$i);
          }
        }
    }

}