@charset "utf-8";
/* CSS Document */

.container {
	width:auto;
	max-width:$baseWidth;
	
	@include widhtWide{
		max-width:$baseWidthWide;
	
	}
	@include tabletL{
		max-width:$bpTabletP;
	
	}
	@include tabletP{
		width:auto;
		padding-right: 10px;
		padding-left: 10px;
	
	}
}

.container02 {
	width:auto;
	max-width:$baseWidth;
    box-sizing:border-box;
    padding:0 70px;
	
	@include widhtWide{
		max-width:$baseWidthWide;
	}
    
	@include tabletL{
		max-width:$bpTabletP;
	
	}
	@include tabletP{
		width:auto;
		padding-right: 10px;
		padding-left: 10px;
	
	}
}

.container03 {
	width:auto;
	max-width:$baseWidth;
    box-sizing:border-box;
    padding:0 120px;
	
	@include widhtWide{
		max-width:$baseWidthWide;
	}
    
	@include tabletL{
		max-width:$bpTabletP;
	
	}
	@include tabletP{
		width:auto;
		padding-right: 10px;
		padding-left: 10px;
	
	}
}

.modPcOnly {
	@include tabletP {
		display:none;
	}
}

.modSpOnly {
	display:none;
	@include tabletP {
		display: block;
	}
}