
@charset "utf-8";
/* layout */
#header {
    position:relative;
    z-index:1010;
    @include tabletP{
        height:50px;
    }    
    
    .body{
        display:table;
        width:100%;
        table-layout:fixed;
        @include tabletP{
            display:block;
            width:auto;
        }
    }
    
    .siteTitle,.gNav{
        display:table-cell;
        vertical-align:middle;
    }
    
    .siteTitle{
        width:270px;
    }
    
    .gNav{
        @include tabletP{
            display:none;
        }
    }
    
    $spHeaderIconW : 50;
    $spHeaderIconH : 50;
    
    .contact{
        position:absolute;
        top:0;
        bottom:0;
        right:50px;
        width:($spHeaderIconW+12)+px;
        height:($spHeaderIconH)+px;
        a{
            display:block;
            height:($spHeaderIconH)+px;
			color:#f1a524;
            &:after{
                content:"";
                background-image:url(../img/iconMail.png);
                background-position:center;
                background-repeat:no-repeat;
                background-size:15px auto;
                height:30px;
                position:absolute;
                top:6px;
                left:0;
                right:0;
                margin:auto;
            }
            span{
                position:absolute;
                bottom:3px;
                left:0;
                width:100%;
                text-align:center;
                @include fz(10);
            }
        }
    }
    
    .access{
        position:absolute;
        top:0;
        bottom:0;
        right:50+($spHeaderIconW+8)+px;
        width:($spHeaderIconW)+px;
        height:($spHeaderIconH)+px;
        a{
            display:block;
            height:($spHeaderIconH)+px;
			color:$mainColor;
            &:after{
                content:$fa-map-marker;
                @include fz(20);
                @include iconFont;
                width:1em;
                height:30px;
                line-height:30px;
                position:absolute;
                top:6px;
                left:0;
                right:0;
                margin:auto;
                text-align:center;
            }
            span{
                position:absolute;
                bottom:3px;
                left:0;
                width:100%;
                text-align:center;
                @include fz(10);
            }
        }
    }
    
    .resev{
        position:absolute;
        top:0;
        bottom:0;
        right:50+($spHeaderIconW*2+5)+px;
        width:($spHeaderIconW)+px;
        height:($spHeaderIconH)+px;
        a{
            display:block;
            height:($spHeaderIconH)+px;
			color:#80ab45;
            &:after{
                content:"";
                background-image:url(../img/iconReservation.png);
                background-position:center;
                background-repeat:no-repeat;
                background-size:15px auto;
                height:30px;
                position:absolute;
                top:6px;
                left:0;
                right:0;
                margin:auto;
            }
            span{
                position:absolute;
                bottom:3px;
                left:0;
                width:100%;
                text-align:center;
                @include fz(10);
            }
        }
    }
    
    .btnMenu{
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        width:50px;
        height:50px;
        cursor:pointer;
        z-index:1000;
        
        .open,.close{
            position:absolute;
            top:0;
            bottom:0;
            right:0;
            left:0;
            margin:auto;
			color:$mainColor;
            &:after{
                @include fz(20);
                @include iconFont;
                width:1em;
                height:30px;
                line-height:30px;
                position:absolute;
                top:6px;
                left:0;
                right:0;
                margin:auto;
                text-align:center;
            }
            span{
                position:absolute;
                bottom:3px;
                left:0;
                width:100%;
                text-align:center;
                @include fz(10);
            }
        }
        
        .open{
            display:block;
            &:after{
                content:$fa-bars;
            }
        }
        
        .close{
            display:none;
            &:after{
                content:$fa-close;
            }
        }
        
        &.select{
            .open{
                display:none;
            }
            .close{
                display:block;
            }
        }
    }
    .default{
        @include transition(all 0.2s linear);
        @include tabletP{
            position:static;
            display: none;
        }
    
        .hNav{
            position:absolute;
            top:0;
            right:40px;
            background-image:url(../img/imgHeader01.png);
            background-position:center top;
            background-repeat:no-repeat;
            padding:50px 0 0;
            @include tabletP{
                display:none;
            }
            a{
                display: block;
                background-position: 0 0;
                background-repeat: no-repeat;
                text-decoration: none;
                text-indent: -9999px;
                &:hover{
                    opacity: 1;
                }
            }

            .navAccess {
                a{
                    background-image: url(../img/btnAccess.png);
                    width: 102px;
                    height: 101px;
                    &:hover{
                        background-position: 0 -101px;
                    }
                }
            }

            .navResev{
                a{
                    background-image: url(../img/btnReservation.png);
                    width: 97px;
                    height: (200/2)+px;
                    &:hover{
                        background-position: 0 -((200/2)+px);
                    }
                }

            }

            .navContact{
                a{
                    background-image: url(../img/btnContact.png);
                    width: 112px;
                    height: (218/2)+px;
                    &:hover{
                        background-position: 0 -((218/2)+px);
                    }
                }

            }
        }
    }
    
    .second{
        background-color:#fff;
        position:fixed;
        width:100%;
        top:-360px;
        left:0;
        @include transition(all 0.2s linear);
        @include tabletP{
            position: static;
        }
        
        .siteTitle{
            width:204px;
            @include tabletP{
                display:block;
                width:100px;
                position:absolute;
                top:14px;
                left:10px;
            }
        }
        
        .gNav{
            padding-right:0;
            padding-left:15px;
            @include tabletP{
                padding-left: 0;
            }

            >ul{
                >li{
                    >a{
                        >span{
                            padding:0 6px;
                            @include tabletP{
                                padding:15px 10px;
                            }
                        }
                    }
                }
            }
        }
        .hNav{
            display:table-cell;
            width:200px;
            padding:3px 0;
            @include widhtWide{
            	width:268px;
            }
            @include tabletP{
                display: none;
            }
            ul{
                display:table;
                width:100%;
                table-layout:fixed;
            }
            
            li{
                display:table-cell;
                vertical-align:middle;
                padding:0 0 0 3px;
                height:88px;
            }
        }
        
    }
    
    .container{
        position:relative;
    }
    
    
    .mMenuBlock{
        position:absolute;
        top:88px;
        left:0;
        right:0;
        width:100%;
        display:none;
        padding:40px 0 0;
        @include tabletP{
            position: static;
            padding: 0;
        }
        
        .mMenuBody{
            background-color:#fff;
            border-radius:20px;
			padding:50px;
            @include tabletP{
                padding: 0;
                border-radius:0;
            }
            
            .inner{
                display:table;
                width:100%;
                table-layout:fixed;
                @include tabletP{
                    display: block;
                    width: auto;
                }
            }
            
            .image,.detail{
                display:table-cell;
                vertical-align:top;
                @include tabletP{
                    display: block;
                }
            }
            
            .image{
                width:230px;
                @include tabletP{
                    display: none;
                }
            }
            
            .detail{
                padding:0 0 0 30px;
                @include tabletP{
                    padding: 0;
                }
            }
            
            a{
                color:#665352;
                &:hover{
                    opacity:0.6;
                }
            }
            
            .title{
                margin-bottom:16px;
                font-weight: 500;
                @include tabletP{
                //    display: none;
                    margin-bottom: 0;
                }

                a{
                    background-image:url(../img/arrow01.svg);
                    background-position:left 0.2em;
                    background-repeat:no-repeat;
                    background-size:16px 16px;
                    padding:0 0 0 20px;
                    @include fz(17);
                    text-decoration:underline;
                    @include tabletP{
                        display: block;
                        border-top:solid 1px #ccc;
                        text-decoration: none;
                        padding: 15px 20px 15px 10px;
                        margin-bottom: 0;
                        background-position:98% center;
                        background-size:13px 13px;
                        @include fz(14);
                    }
                }
            }
            
            .subTitle{
                background-image:url(../img/arrow01B.svg);
                background-position:left 0.4em;
                background-repeat:no-repeat;
                background-size:13px 13px;
                margin-bottom:6px;
                padding:0 0 0 20px;
                @include fz(14);
                font-weight: 500;
                @include tabletP{
                    display: none;
                    border-top:solid 1px #ccc;
                    background-color: #faf9f9;
                    margin-bottom: 0;
                    padding: 15px 20px 15px 10px;
                    background-position:98% center;
                    cursor: pointer;
                    &.on{
                        background-image:url(../img/arrow01T.png);
                    }
                }
            }

            .subTitle02,.subTitle03{
                a{
                    display: block;
                    background-image:url(../img/arrow01.svg);
                    background-position:left 0.4em;
                    background-repeat:no-repeat;
                    background-size:13px 13px;
                    margin-bottom:6px;
                    padding:0 0 0 20px;
                    font-weight: 500;
                    @include fz(14);
                    text-decoration: none;
                    @include tabletP{
                        border-top:solid 1px #ccc;
                        padding: 15px 20px 15px 10px;
                        margin-bottom: 0;
                        background-position:98% center;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            .subTitle03{
                display: none;
                @include tabletP{
                    display: block;
                }
            }
            
            .list{
                display: table;
                @include tabletP{
                    display: block;
                }

                .box{
                    display: table-cell;
                    vertical-align: top;
                    padding: 0 20px ;
                    border-right:dotted 1px #ccc;
                    @include tabletP{
                        display: block;
                        padding: 0;
                    }

                    &:first-child{
                        padding-left: 0;
                    }

                    &:last-child{
                        border-right:none;
                        padding-right: 0;
                    }
                }
                ul{
                    padding:0 0 0 20px;
                    @include tabletP{
                        padding: 0;
                        display: none;
                    }
                }
                li{
                    margin-bottom: 10px;
                    @include tabletP{
                        border-top:dotted 1px #ccc;
                        margin-bottom: 0;
                    }

                    a{
                        display: block;
                        position:relative;
                        padding:0 0 0 14px;
                        @include fz(12);
                        text-decoration:none;
                        font-weight: 500;
                        @include tabletP{
                            padding: 10px 20px;
                        }
                        &:hover{
                            text-decoration: underline;
                        }
                        &:before{
                            content:$fa-angle-right;
                            @include iconFont;
                            position:absolute;
                            top:0;
                            bottom:0;
                            height: 1em;
                            left:0;
                            margin: auto;
                            color:#A7A7A7;
                            @include fz(12);
                            @include tabletP{
                                right:10px;
                                left:auto;
                            }
                        }
                    }
                }
            }
            
                
        }
    }
    


/* #header */}

.mMenuModal{
    background-color:rgba(#000,0.2);
    position:fixed;
    top:88px;
    left:0;
    bottom:0;
    right:0;
    z-index:1010;
    display:none;
    @include tabletP{
        display: none!important;
    }
}

.gNav{
    padding:0 124px 0 30px;
    @include tabletP{
        padding:0;
        position:absolute;
        top:50px;
        left:0;
        width:100%;
    }
    
    >ul{
        @include clearfix;
    }
    
    >ul>li{
        float:left;
        min-width:50px;
        @include widhtWide{
            min-width:90px;
        }
        @include tabletP{
            float:none;
            border-bottom:solid 1px #ccc;

            &.acc{
                >a{
                    >span{
                        &:after{
                            content:$fa-angle-down;
                        }
                    }
                }
            }
            &.on{
                >a{
                    >span{
                        &:after{
                            content:$fa-angle-up;
                        }
                    }
                }
            }
        }
    }
    >ul>li>a{
        display:table;
        width:100%;
        background-image:url(../img/bgGnav.png);
        background-position:center;
        background-repeat:no-repeat;
        background-size:40px auto;
		text-decoration:none;
        @include widhtWide{
            background-size:auto;
        }
        @include tabletP{
            display:block;
            width:auto;
            background-image:none;
        	background-color:#fff9f6;
        }
        >span{
            display:table-cell;
            vertical-align:middle;
            padding:0 10px;
        	height:88px;
            font-family:"游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;
            @include fz(13);
            font-weight: 500;
            text-align:center;
            line-height:1.3;

            @include widhtWide{
                @include fz(15);
                padding:0 14px;
            }
            @media only screen and (max-width: 1172px) {
                @include fz(12);
            }

            @include tabletP{
                display:block;
                position:relative;
                height:auto;
                padding:15px 10px;
                text-align:left;
                @include fz(14);
                &:after{
                    content:$fa-angle-right;
                    @include iconFont;
                    position:absolute;
                    top:0;
                    right:10px;
                    bottom:0;
                    height:1em;
                    margin:auto;
                    color:$subColor;
                    @include fz(18);
                }
            }
            span{
                display:block;
                @include tabletP{
                    display:inline;
                }
            }
        }
        
        &:hover{
            >span{
                color:$subColor;
                @include tabletP{
                    color:$baseColor;
                }
            }
        }
    }
}

#contents { }

.section{
    margin-bottom: 100px;
    &:last-child{
        margin-bottom: 0;
    }
    
    @include sphoneP{
        margin-bottom: 50px;
    }
    
    .section{
        margin-bottom: 60px;
        @include sphoneP{
            margin-bottom: 30px;
        }
    }
}

.container {
	width:$baseWidth;
	@include margin-center;
}

.container02{
	width:$baseWidth;
	@include margin-center;
}

.container03{
	width:$baseWidth;
	@include margin-center;
}


.topicpath{
    background-color: #fff;
    padding:10px 0;
    @include fz(14);
    
    @include tabletP{
    }
	
	ul{
		@include clearfix;
	}
	
	li{
		float:left;
		position:relative;
		padding-left:18px;
        @include tabletP{
        }
		&:before{
			content:$fa-angle-right;
			position:absolute;
			left:8px;
			top:0;
			bottom:0;
			height:1em;
			margin:auto;
			@include iconFont;
            .IE &{
                top:-5px;
            }
		}
	}
	a{
		display:block;
        color:#333;
        &:hover{
            opacity:0.6;
        }
	}
	.home{
		padding-left:0;
		&:before{
			display:none;
		}
		a{
/*			
			&:before{
				content:$fa-home;
                @include iconFont;
                margin:0 4px 0 0;
			}*/
		}
	}
}

#footer {
    
    .underMenu{
        background-image:url(../img/bgMenu01.jpg);
        background-position:center top;
        padding:50px 0;
        @include sphoneP{
            padding: 0;
        }
        
        .menu{
        }
        
        ul{
            display:table;
            width:100%;
            table-layout:fixed;
            @include sphoneP{
                display:block;
                width:auto;
                margin:0 -10px;
            }
        }
        
        li{
            display:table-cell;
            vertical-align:middle;
            border-right:dotted 2px #E8C1B2;
            @include sphoneP{
                display:block;
            	border-bottom:dotted 2px #E8C1B2;
            	border-right:none;
            }
            &:first-child{
                border-left:dotted 2px #E8C1B2;
                @include sphoneP{
                    border-left:none;
                }
            }
            &:last-child{
                @include sphoneP{
                    border-bottom:none
                }
            }
            
        }
        
        a{
            display:block;
            padding:10px 0;
            text-align:center;
            color:$mainColor;
            font-family:$fontMincho;
            @include fz(17);
            text-decoration:none;
            @include sphoneP{
                padding:10px;
                @include clearfix;
                @include fz(15);
                text-align:left;
            }
            &:hover{
                background-color:rgba(#fff,0.4);
            }
            
            img{
                @include sphoneP{
                    float:left;
                    width:60px;
                }
            }
            
            .txt{
                margin:10px 0 0;
                @include sphoneP{
                    margin:0 0 0 70px;
                    line-height:38px;
                }
            }
        }
    }
    
    
	.sitemap{
        background-color:$mainColor02;
        padding:0 0 20px;
        
		.btn{
            position:relative;
            padding:20px 0 15px;
			cursor:pointer;
            font-family:$fontMincho;
            color:#D6A124;
            text-align:center;
            @include fz(18);
            
			&:before{
				content:$fa-angle-down;
                @include iconFont;
                position:absolute;
                bottom:0;
                left:0;
                right:0;
                margin:auto;
                width:1em;
                @include fz(22);
			}
			&.active{
                
				&:before{
                    content:$fa-angle-up;
				}
			}
		}
		
		.menu{
			display:none;
			padding:20px 0 30px;
            @include sphoneP{
                padding-bottom:0;
            }
			
			.inner{
				display:table;
				width:100%;
                @include sphoneP{
                    display:block;
                    width:auto;
                }
			}
			
			.box{
				display:table-cell;
                padding:0 20px 0 0;
                @include sphoneP{
                    padding:0;
                }
                &.type02{
                    border-left:dotted 1px #ccc;
                    padding-left:20px;
                    @include sphoneP{
                        padding-left:0;
                        border-left:none;
                    }
                }
                @include sphoneP{
                    display:block;
                }
                >ul>li{
                    >a{
                        display:block;
                        background-image:url(../img/iconCloverBlue01.png);
                        background-position:left 0.3em;
                        background-repeat:no-repeat;
                        span{
                            display:block;
                        }
                        @include sphoneP{
                            padding-top:8px;
                            padding-bottom:8px;
                            background-position:left 14px;
                        }
                    }
                    
                    &.group02{
                        margin-bottom:40px;
                        @include sphoneP{
                            margin-bottom:6px;
                        }
                        >a{
                            background-image:url(../img/iconCloverPink01.png);
                        }
                    }
                    &.group03{
                        >a{
                            background-image:url(../img/iconCloverGreen01.png);
                        }
                    }
                }
			}
            
            li{
                margin-bottom:6px;
                li{
                    position: relative;
                    &:before{
                        content:$fa-angle-right;
                        position: absolute;
                        top:0.2em;
                        margin: auto;
                        left:24px;
                        @include iconFont;
                        @include fz(18);
                    }
                    a{
                        padding-left: 38px;
                    }
                }
            }
            
            a{
                display:block;
				padding:0 0 0 24px;
                color:#665352;
				text-decoration:none;
                &:hover{
                    opacity:0.6;
                    text-decoration:underline;
                }
            }
		}
	}
    
    .info{
        background-color: #FFFFF4;
        padding:60px 0;
        .container{
            display:table;
            width:100%;
            table-layout:fixed;
            @include tabletP{
                display:block;
                width:auto;
            }
        }
        .detail,.map{
            display:table-cell;
            vertical-align:top;
            @include tabletP{
                display:block;
            }
        }
        
        .detail{
            padding:0 55px 0 0;
            @include tabletP{
                padding:0;
                margin-bottom:20px;
            }
        }
        
        .logo{
            margin:0 0 15px;
        }
        
        address{
            border-bottom:dotted 2px #C3DFD3;
            padding:0 0 12px;
            margin:0 0 20px;
            @include fz(14);
        }
        
        .department{
            @include clearfix;
            margin-bottom:25px;
            
            li{
                float:left;
                background-image:url(../img/iconCloverBlue01.png);
                background-position:left 0.3em;
                background-repeat:no-repeat;
                padding:0 0 0 20px;
                margin:0 10px 0 0;
                color:#665352;
            }
            
        }
        
        .tel{
            background-image:url(../img/iconTel.svg);
            background-position:left 0.2em;
            background-repeat:no-repeat;
            background-size:38px 38px;
            padding:0 0 0 50px;
            margin:0 0 18px;
            min-height:42px;
            
            .num{
                height:40px;
                line-height:40px;
                @include fz(34);
                color:$subColor;
                font-family:$fontMincho;
                @include sphoneP{
                    @include fz(24);
                }
                
                .sub{
                    margin-left:4px;
                    @include fz(18);
                    vertical-align:top;
                }
            }
            
            a{
                text-decoration:none;
            }
            
            .time{
                @include fz(16);
            }
        }
        
        .btn{
            max-width:320px;
            margin:0 0 16px;
            @include tabletP{
                margin:0 auto 20px;
            }
        }
        
        .caution{
            background-image:url(../img/iconCloverGreen02.png);
            background-position:left 0.2em;
            background-repeat:no-repeat;
            padding:0 0 0 24px;
            color:#665352;
            @include fz(14);
        }
        
        .map{
            width:544px;
            padding:0 26px 0 0;
            @include tabletP{
                width:auto;
                padding:0;
                text-align:center;
            }
        }
    }
    
    .foot{
        background-color:$mainColor02;
        padding:22px 0;
        @include fz(14);
        color:#665352;
        text-align:center;
        
        a{
            padding:0 5px;
        	color:#665352;
            text-decoration:none;
            &:hover{
                text-decoration:underline;
                opacity:0.6;
            }
        }
        
        ul{
            margin-bottom:8px;
        }
        
        li{
            display:inline;
            line-height:1;
            &:after{
                content:"\20\7C\20";
            }
            
            &:first-child{
                &:before{
                    content:"\20\7C\20";
                }
            }
        }
        
        .copyright{
        }
    }
    
    .pageTop{
        display:none;
        background-color: #fff;
        border-radius:50%;
        position:fixed;
        bottom:80px;
        right:30px;
        width:50px;
        height:50px;
        @include sphoneP{
            right:20px;
            bottom:20px;
        }
    }
    
    
}

#cboxClose{
    bottom:auto;
    top:8px;
    right:8px!important;
    background-image: url(../img/iconClose.png)!important;
    background-position: 0 0!important;
    background-size: 30px auto!important;
    width: 30px!important;
    height: 30px!important;
}

#cboxPrevious,#cboxNext{
    background-position: 0 0!important;
    width: 51px!important;
    height: 51px!important;
    top:0;
    margin: auto!important;
    @include sphoneP{
        background-size: 30px auto!important;
        width: 30px!important;
        height: 30px!important;
    }
}

#cboxPrevious{
    background-image: url(../img/arrow03L.png)!important;
    left: 10px!important;
}
#cboxNext{
    background-image: url(../img/arrow03.png)!important;
    right: 10px;
    left:auto!important;
}

#cboxCurrent{
    left:auto!important;
    right:10px;
    color:#fff!important;
    text-shadow: #333 1px 1px 0;
}