@charset "utf-8";

/* modTitle
------------------------------------------------------------*/
.modPageTitle{
    background-image:url(../img/bgTitle.jpg);
    background-position:center;
    background-repeat:no-repeat;
    background-size:auto 100%;
    padding:15px 0;
    text-align:center;
    @include sphoneP{
    	background-image:url(../img/bgTitleSp.jpg);
        background-size:cover;
    }
    
    
    .main,.sub{
        display:block;
    }
    
    .main{
        @include fz(38);
        font-family:$fontMincho;
        line-height:1.5;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(24);
        }
    }
    
    .sub{
        @include fz(17);
        font-family:$fontMincho;
        line-height:1.5;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(14);
        }
    }

/* .modPageTitle */}

.modPageTitle02{
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    height:343px;
    text-align:center;
    @include tabletP{
        height:auto;
    }
    
    
    &.voice{
        background-image:url(../img/voice/bgPageTitle.jpg);
    }
    
    &.qa{
        background-image:url(../img/qa/bgPageTitle.jpg);
    }
    
    &.nursery{
        background-image:url(../img/taiyou/bgPageTitle.jpg);
    }

    &.counsel{
        background-image:url(../img/counsel/bgPageTitle.jpg);
    }
    
    &.kensyu{
        background-image:url(../img/kensyu/bgPageTitle.jpg);
    }
    
    &.keisei{
        background-image:url(../img/keisei/bgPageTitle.jpg);
    }
    
    &.fujinka{
        background-image:url(../img/fujinka/bgPageTitle.jpg);
    }
    
    &.child{
        background-image:url(../img/child/index/bgPageTitle.jpg);
    }

    &.childKensin{
        background-image:url(../img/child/kensin/bgPageTitle.jpg);
    }

    &.repro{
        background-image:url(../img/repro/index/bgPageTitle.jpg);
    }

    &.sanka{
        background-image:url(../img/sanka/index/bgPageTitle.jpg);
    }
    &.kodawari{
        background-image:url(../img/kodawari/bgPageTitle.jpg);
    }
    &.about{
        background-image:url(../img/about/index/bgPageTitle.jpg);
    }
    &.rec{
        background-image:url(../img/rec/bgPageTitle.jpg);
    }
    &.lohas{
        background-image:url(../img/lohas/index/bgPageTitle.jpg);
    }
    
    &.class{
        background-image:url(../img/class/index/bgPageTitle.jpg);
    }
    
    @include sphoneP{
    }
    
    .title{
        background-color:rgba(#fff,0.5);
        padding:10px 0;
        @include tabletP{
            padding:30px 0;
        }
        @include sphoneP{
            padding:15px 0;
            
        }
    }
    
    .main,.sub{
        display:block;
    }
    
    .main{
        @include fz(38);
        font-family:$fontMincho;
        line-height:1.5;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(24);
        }
    }
    
    .sub{
        @include fz(17);
        font-family:$fontMincho;
        line-height:1.5;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(14);
        }
    }

/* .modPageTitle02 */}

.modTitle01{
    margin-bottom:40px;
    text-align:center;
    font-family:$fontMincho;
    @include fz(30);
    @include sphoneP{
        margin-bottom:20px;
        @include fz(22);
    }
	span{
	display:block;
		@include sphoneP{
			display:inline;
		}
    }

    &_line{
      margin:0 0 50px;
      padding-top: 30px;
      position:relative;

      @include sphoneP{
        padding-top:15px;
        margin-bottom:25px;        
        @include fz(20);
      }

      &:after {
        content:"";
        display:block;
        position:absolute;
        width:85px;
        height:1px;
        top:0;
        left:50%;
        margin-left:-42px;
        background-color:#000;
      }    
    }

    &_pink {
      color:$mainColor03;
    }
    &_blue {
      color:$mainColor04;
    }

    &_type02{
        font-family: $baseFontFamily;
        font-weight: 500;
    }

/* .modTitle01 */}

.modTitle02{
    margin:0px 0 50px;
    text-align:center;
    @include tabletP{
        margin-top:0;
    }
    @include sphoneP{
        margin-bottom:30px;
    }
    
    .main,.sub{
        display:block;
    }
    .main{
        @include fz(36);
        font-family:$fontMincho;
        line-height:1.5;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(20);
        }
    }
    
    .small{
		margin-left: 8px;
        @include fz(22);
        @include sphoneP{
            @include fz(14);
        }
    }
    
    .small02{
        display: block;
        @include fz(22);
        @include sphoneP{
            @include fz(14);
        }
    }
    
    .sub{
        @include fz(17);
        font-family:$fontMincho;
        line-height:1.5;
        color:$mainColor;
        letter-spacing:0.1em;
        @include sphoneP{
            @include fz(13);
        }
    }
    
    .icon{
        display:block;
        background-image:url(../img/contact/iconContact.png);
        background-position:0 0;
        background-repeat:no-repeat;
        width:50px;
        height:50px;
        margin:-30px auto 8px;
        @include sphoneP{
            width:30px;
            height:30px;
            background-size:30px auto;
            margin-top:0;
        }
    }
    &_khaki{
		.sub{
			color:$subColor04;
		}
	}
	
    &_line{
      margin:0 0 50px;
      padding-top: 30px;
      position:relative;
      
      &:after {
        content:"";
        display:block;
        position:absolute;
        width:85px;
        height:1px;
        top:0;
        left:50%;
        margin-left:-42px;
        background-color:#000;
      }    
    }
    
    &_il{
        
        .title{
        	position:relative;
            @include inline-block;
            padding:0 160px;
            @include tabletP{
                padding:0;
            }
            
            &:before,&:after{
                position: absolute;
                background-repeat:no-repeat;
                @include tabletP{
                    display:none;
                }
            }
            
            &:before{
                content:"";
                background-image:url(../img/contact/titleDeco01.png);
                width:73px;
                height:48px;
                left:0;
                bottom:20px;
            }
            
            &:after{
                content:"";
                background-image:url(../img/contact/titleDeco02.png);
                width:136px;
                height:86px;
                right:-20px;
                bottom:0;
            }
        }
    }

    &_ilQa{

        .icon {
          background-image:url(../img/repro/index/decoQa03.png);
        }
        
        .title{
        	position:relative;
            @include inline-block;
            padding:0 153px;
            @include tabletP{
                padding:0;
            }
            
            &:before,&:after{
                position: absolute;
                background-repeat:no-repeat;
                @include tabletP{
                    display:none;
                }
            }
            
            &:before{
                content:"";
                background-image:url(../img/repro/index/decoQa01.png);
                width:136px;
                height:60px;
                left:0;
                bottom:15px;
            }
            
            &:after{
                content:"";
                background-image:url(../img/repro/index/decoQa02.png);
                width:136px;
                height:60px;
                right:0px;
                bottom:15px;
            }
        }
    }

    
/* .modTitle02 */}

.modTitle03{
	margin-bottom:0;
    text-align:center;
    font-family:$fontMincho;
    @include fz(30);
	border-top:1px solid #ffa1a5;
	padding:28px 0;
	background-image:url(../img/bgTitle02.jpg);
	background-position:left top;
	background-repeat:repeat;
	
    @include sphoneP{
        @include fz(22);
    }
/* .modTitle03 */}

.modTitle04{
    margin-bottom:40px;
    text-align:center;
    font-family:$fontMincho;
    @include fz(30);
	color:#666;
	padding:15px 0;
	background-image:url(../img/line.png);
	background-position:center bottom;
	background-repeat:no-repeat;
    
    &_type02{
		background-image:url(../img/line02.png);
        background-repeat:repeat-x;  
        background-size: auto 5px;  
    }
	
    @include sphoneP{
        margin-bottom:20px;
        @include fz(22);
    }
/* .modTitle04 */}

.modTitle05{
	margin:0 0 40px;
    padding-top: 30px;
    text-align:center;
    color:$mainColor03;
    @include fz(30);
    font-family:$fontMincho;
	position:relative;
	@include sphoneP{ margin-bottom: 20px; }
	&:after {
		content:"";
		display:block;
		position:absolute;
		width:85px;
		height:1px;
		top:0;
		left:50%;
		margin-left:-42px;
		background-color:$mainColor;
	}    
    @include sphoneP{
		margin:60px 0 20px;
		@include fz(22);
	}
    span{
        display:block;
        @include sphoneP{
        display:inline;
        }
    }
/* .modTitle05 */}

.modTitle06{
    margin-bottom: 20px;
    @include fz(25);
    text-align: center;
    font-weight: bold;
    letter-spacing:0.1em;
}