@charset "UTF-8";
@import url(font-awesome.min.css);
/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
em {
  font-style: normal;
}

strong,
th {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
}

hr,
legend {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
fieldset {
  border: 0;
}

img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

li {
  list-style-type: none;
}

input[type="submit"], input[type="button"], button, label, select {
  cursor: pointer;
}

input[type="text"], input[type="email"], input[type="password"], input[type="button"], textarea, input[type="submit"], input[type="password"], button, label {
  -webkit-appearance: none;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/* link
------------------------------------------------------------*/
a {
  color: #333;
  text-decoration: underline;
}

a:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  a:hover {
    opacity: 1;
  }
}

a:active {
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  a:active {
    opacity: 1;
  }
}

/* Fonts
------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

/* 10px base */
body {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

body > div, input, button, textarea, select {
  font-size: 15px;
  font-size: 1.5rem;
  font-family: "游ゴシック", YuGothic, "メイリオ", Verdana, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  line-height: 1.8;
  color: #333;
}

@media only screen and (max-width: 480px) {
  body > div, input, button, textarea, select {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

/* form
------------------------------------------------------------*/
input[type="text"], input[type="email"], input[type="password"], textarea, select {
  background-color: #fff;
  border: solid 3px #fff;
  padding: 8px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border-radius: 5px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 480px) {
  input[type="text"], input[type="email"], input[type="password"], textarea, select {
    border-width: 1px;
  }
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
  border-color: #A3A3A3;
  outline: none;
}

input[type="text"].inline, input[type="email"].inline, input[type="password"].inline, textarea.inline, select.inline {
  width: auto;
}

input[type="text"].small, input[type="email"].small, input[type="password"].small, textarea.small, select.small {
  max-width: 240px;
}

@media only screen and (max-width: 768px) {
  input[type="text"], input[type="email"], input[type="password"], textarea, select {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

input[type="text"].error, input[type="email"].error, input[type="password"].error, textarea.error, select.error {
  border-color: #ff4a4b !important;
}

input[type="text"], input[type="email"], input[type="password"], textarea, select {
  width: 100%;
}

textarea {
  vertical-align: top;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

input[type="text"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
textarea[disabled] {
  background-color: #ECEBEB;
}

/* color
------------------------------------------------------------*/
.baseTestColor {
  margin-bottom: 6px;
}

.baseTestColor li {
  display: inline-block;
  padding: 3px 10px;
  margin: 0 0 4px;
}

.baseTestColor .mainColor {
  background-color: #FF7A80;
  color: #fff;
}

.baseTestColor .mainColor02 {
  background-color: #D6ECE1;
}

.baseTestColor .mainColor03 {
  background-color: #ef7f87;
  color: #fff;
}

.baseTestColor .mainColor04 {
  background-color: #1490c8;
  color: #fff;
}

.baseTestColor .subColor {
  background-color: #B98A00;
  color: #fff;
}

.baseTestColor .subColor02 {
  background-color: #665352;
  color: #fff;
}

.baseTestColor .subColor03 {
  background-color: #ffc9cd;
  color: #fff;
}

.baseTestColor .hoverColor {
  background-color: #F99BA2;
  color: #fff;
}

.baseTestColor .hoverColor02 {
  background-color: #f5f5f5;
}

.baseTestColor .hoverColor03 {
  background-color: #FFF5F5;
}

.baseTestColor .newCateColor, .baseTestColor .newCateColor01, .baseTestColor .newCateColor02, .baseTestColor .newCateColor03, .baseTestColor .newCateColor04, .baseTestColor .newCateColor05, .baseTestColor .newCateColor06 {
  color: #fff;
}

.baseTestColor .newCateColor01 {
  background-color: #469BC0;
}

.baseTestColor .newCateColor02 {
  background-color: #5AC6C5;
}

.baseTestColor .newCateColor03 {
  background-color: #E79600;
}

.baseTestColor .newCateColor04 {
  background-color: #C5884C;
}

.baseTestColor .newCateColor05 {
  background-color: #FF9B90;
}

.baseTestColor .newCateColor06 {
  background-color: #8ABD56;
}

/* layout */
#header {
  position: relative;
  z-index: 1010;
  /* #header */
}

@media only screen and (max-width: 768px) {
  #header {
    height: 50px;
  }
}

#header .body {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media only screen and (max-width: 768px) {
  #header .body {
    display: block;
    width: auto;
  }
}

#header .siteTitle, #header .gNav {
  display: table-cell;
  vertical-align: middle;
}

#header .siteTitle {
  width: 270px;
}

@media only screen and (max-width: 768px) {
  #header .gNav {
    display: none;
  }
}

#header .contact {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  width: 62px;
  height: 50px;
}

#header .contact a {
  display: block;
  height: 50px;
  color: #f1a524;
}

#header .contact a:after {
  content: "";
  background-image: url(../img/iconMail.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px auto;
  height: 30px;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

#header .contact a span {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
}

#header .access {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 108px;
  width: 50px;
  height: 50px;
}

#header .access a {
  display: block;
  height: 50px;
  color: #FF7A80;
}

#header .access a:after {
  content: "";
  font-size: 20px;
  font-size: 2rem;
  font-family: FontAwesome;
  line-height: 1;
  width: 1em;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

#header .access a span {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
}

#header .resev {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 155px;
  width: 50px;
  height: 50px;
}

#header .resev a {
  display: block;
  height: 50px;
  color: #80ab45;
}

#header .resev a:after {
  content: "";
  background-image: url(../img/iconReservation.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px auto;
  height: 30px;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

#header .resev a span {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
}

#header .btnMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000;
}

#header .btnMenu .open, #header .btnMenu .close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  color: #FF7A80;
}

#header .btnMenu .open:after, #header .btnMenu .close:after {
  font-size: 20px;
  font-size: 2rem;
  font-family: FontAwesome;
  line-height: 1;
  width: 1em;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

#header .btnMenu .open span, #header .btnMenu .close span {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
}

#header .btnMenu .open {
  display: block;
}

#header .btnMenu .open:after {
  content: "";
}

#header .btnMenu .close {
  display: none;
}

#header .btnMenu .close:after {
  content: "";
}

#header .btnMenu.select .open {
  display: none;
}

#header .btnMenu.select .close {
  display: block;
}

#header .default {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

@media only screen and (max-width: 768px) {
  #header .default {
    position: static;
    display: none;
  }
}

#header .default .hNav {
  position: absolute;
  top: 0;
  right: 40px;
  background-image: url(../img/imgHeader01.png);
  background-position: center top;
  background-repeat: no-repeat;
  padding: 50px 0 0;
}

@media only screen and (max-width: 768px) {
  #header .default .hNav {
    display: none;
  }
}

#header .default .hNav a {
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  text-decoration: none;
  text-indent: -9999px;
}

#header .default .hNav a:hover {
  opacity: 1;
}

#header .default .hNav .navAccess a {
  background-image: url(../img/btnAccess.png);
  width: 102px;
  height: 101px;
}

#header .default .hNav .navAccess a:hover {
  background-position: 0 -101px;
}

#header .default .hNav .navResev a {
  background-image: url(../img/btnReservation.png);
  width: 97px;
  height: 100px;
}

#header .default .hNav .navResev a:hover {
  background-position: 0-100px;
}

#header .default .hNav .navContact a {
  background-image: url(../img/btnContact.png);
  width: 112px;
  height: 109px;
}

#header .default .hNav .navContact a:hover {
  background-position: 0-109px;
}

#header .second {
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: -360px;
  left: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

@media only screen and (max-width: 768px) {
  #header .second {
    position: static;
  }
}

#header .second .siteTitle {
  width: 204px;
}

@media only screen and (max-width: 768px) {
  #header .second .siteTitle {
    display: block;
    width: 100px;
    position: absolute;
    top: 14px;
    left: 10px;
  }
}

#header .second .gNav {
  padding-right: 0;
  padding-left: 15px;
}

@media only screen and (max-width: 768px) {
  #header .second .gNav {
    padding-left: 0;
  }
}

#header .second .gNav > ul > li > a > span {
  padding: 0 6px;
}

@media only screen and (max-width: 768px) {
  #header .second .gNav > ul > li > a > span {
    padding: 15px 10px;
  }
}

#header .second .hNav {
  display: table-cell;
  width: 200px;
  padding: 3px 0;
}

@media only screen and (min-width: 1160px) {
  #header .second .hNav {
    width: 268px;
  }
}

@media only screen and (max-width: 768px) {
  #header .second .hNav {
    display: none;
  }
}

#header .second .hNav ul {
  display: table;
  width: 100%;
  table-layout: fixed;
}

#header .second .hNav li {
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 3px;
  height: 88px;
}

#header .container {
  position: relative;
}

#header .mMenuBlock {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  width: 100%;
  display: none;
  padding: 40px 0 0;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock {
    position: static;
    padding: 0;
  }
}

#header .mMenuBlock .mMenuBody {
  background-color: #fff;
  border-radius: 20px;
  padding: 50px;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody {
    padding: 0;
    border-radius: 0;
  }
}

#header .mMenuBlock .mMenuBody .inner {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .inner {
    display: block;
    width: auto;
  }
}

#header .mMenuBlock .mMenuBody .image, #header .mMenuBlock .mMenuBody .detail {
  display: table-cell;
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .image, #header .mMenuBlock .mMenuBody .detail {
    display: block;
  }
}

#header .mMenuBlock .mMenuBody .image {
  width: 230px;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .image {
    display: none;
  }
}

#header .mMenuBlock .mMenuBody .detail {
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .detail {
    padding: 0;
  }
}

#header .mMenuBlock .mMenuBody a {
  color: #665352;
}

#header .mMenuBlock .mMenuBody a:hover {
  opacity: 0.6;
}

#header .mMenuBlock .mMenuBody .title {
  margin-bottom: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .title {
    margin-bottom: 0;
  }
}

#header .mMenuBlock .mMenuBody .title a {
  background-image: url(../img/arrow01.svg);
  background-position: left 0.2em;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding: 0 0 0 20px;
  font-size: 17px;
  font-size: 1.7rem;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .title a {
    display: block;
    border-top: solid 1px #ccc;
    text-decoration: none;
    padding: 15px 20px 15px 10px;
    margin-bottom: 0;
    background-position: 98% center;
    background-size: 13px 13px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#header .mMenuBlock .mMenuBody .subTitle {
  background-image: url(../img/arrow01B.svg);
  background-position: left 0.4em;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  margin-bottom: 6px;
  padding: 0 0 0 20px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .subTitle {
    display: none;
    border-top: solid 1px #ccc;
    background-color: #faf9f9;
    margin-bottom: 0;
    padding: 15px 20px 15px 10px;
    background-position: 98% center;
    cursor: pointer;
  }
  #header .mMenuBlock .mMenuBody .subTitle.on {
    background-image: url(../img/arrow01T.png);
  }
}

#header .mMenuBlock .mMenuBody .subTitle02 a, #header .mMenuBlock .mMenuBody .subTitle03 a {
  display: block;
  background-image: url(../img/arrow01.svg);
  background-position: left 0.4em;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  margin-bottom: 6px;
  padding: 0 0 0 20px;
  font-weight: 500;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .subTitle02 a, #header .mMenuBlock .mMenuBody .subTitle03 a {
    border-top: solid 1px #ccc;
    padding: 15px 20px 15px 10px;
    margin-bottom: 0;
    background-position: 98% center;
  }
}

#header .mMenuBlock .mMenuBody .subTitle02 a:hover, #header .mMenuBlock .mMenuBody .subTitle03 a:hover {
  text-decoration: underline;
}

#header .mMenuBlock .mMenuBody .subTitle03 {
  display: none;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .subTitle03 {
    display: block;
  }
}

#header .mMenuBlock .mMenuBody .list {
  display: table;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list {
    display: block;
  }
}

#header .mMenuBlock .mMenuBody .list .box {
  display: table-cell;
  vertical-align: top;
  padding: 0 20px;
  border-right: dotted 1px #ccc;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list .box {
    display: block;
    padding: 0;
  }
}

#header .mMenuBlock .mMenuBody .list .box:first-child {
  padding-left: 0;
}

#header .mMenuBlock .mMenuBody .list .box:last-child {
  border-right: none;
  padding-right: 0;
}

#header .mMenuBlock .mMenuBody .list ul {
  padding: 0 0 0 20px;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list ul {
    padding: 0;
    display: none;
  }
}

#header .mMenuBlock .mMenuBody .list li {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list li {
    border-top: dotted 1px #ccc;
    margin-bottom: 0;
  }
}

#header .mMenuBlock .mMenuBody .list li a {
  display: block;
  position: relative;
  padding: 0 0 0 14px;
  font-size: 12px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list li a {
    padding: 10px 20px;
  }
}

#header .mMenuBlock .mMenuBody .list li a:hover {
  text-decoration: underline;
}

#header .mMenuBlock .mMenuBody .list li a:before {
  content: "";
  font-family: FontAwesome;
  line-height: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 1em;
  left: 0;
  margin: auto;
  color: #A7A7A7;
  font-size: 12px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  #header .mMenuBlock .mMenuBody .list li a:before {
    right: 10px;
    left: auto;
  }
}

.mMenuModal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 88px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1010;
  display: none;
}

@media only screen and (max-width: 768px) {
  .mMenuModal {
    display: none !important;
  }
}

.gNav {
  padding: 0 124px 0 30px;
}

@media only screen and (max-width: 768px) {
  .gNav {
    padding: 0;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
  }
}

.gNav > ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.gNav > ul > li {
  float: left;
  min-width: 50px;
}

@media only screen and (min-width: 1160px) {
  .gNav > ul > li {
    min-width: 90px;
  }
}

@media only screen and (max-width: 768px) {
  .gNav > ul > li {
    float: none;
    border-bottom: solid 1px #ccc;
  }
  .gNav > ul > li.acc > a > span:after {
    content: "";
  }
  .gNav > ul > li.on > a > span:after {
    content: "";
  }
}

.gNav > ul > li > a {
  display: table;
  width: 100%;
  background-image: url(../img/bgGnav.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px auto;
  text-decoration: none;
}

@media only screen and (min-width: 1160px) {
  .gNav > ul > li > a {
    background-size: auto;
  }
}

@media only screen and (max-width: 768px) {
  .gNav > ul > li > a {
    display: block;
    width: auto;
    background-image: none;
    background-color: #fff9f6;
  }
}

.gNav > ul > li > a > span {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
  height: 88px;
  font-family: "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
}

@media only screen and (min-width: 1160px) {
  .gNav > ul > li > a > span {
    font-size: 15px;
    font-size: 1.5rem;
    padding: 0 14px;
  }
}

@media only screen and (max-width: 1172px) {
  .gNav > ul > li > a > span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .gNav > ul > li > a > span {
    display: block;
    position: relative;
    height: auto;
    padding: 15px 10px;
    text-align: left;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .gNav > ul > li > a > span:after {
    content: "";
    font-family: FontAwesome;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    height: 1em;
    margin: auto;
    color: #B98A00;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.gNav > ul > li > a > span span {
  display: block;
}

@media only screen and (max-width: 768px) {
  .gNav > ul > li > a > span span {
    display: inline;
  }
}

.gNav > ul > li > a:hover > span {
  color: #B98A00;
}

@media only screen and (max-width: 768px) {
  .gNav > ul > li > a:hover > span {
    color: #333;
  }
}

.section {
  margin-bottom: 100px;
}

.section:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .section {
    margin-bottom: 50px;
  }
}

.section .section {
  margin-bottom: 60px;
}

@media only screen and (max-width: 480px) {
  .section .section {
    margin-bottom: 30px;
  }
}

.container {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.container02 {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.container03 {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.topicpath {
  background-color: #fff;
  padding: 10px 0;
  font-size: 14px;
  font-size: 1.4rem;
}

.topicpath ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.topicpath li {
  float: left;
  position: relative;
  padding-left: 18px;
}

.topicpath li:before {
  content: "";
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  height: 1em;
  margin: auto;
  font-family: FontAwesome;
  line-height: 1;
}

.IE .topicpath li:before {
  top: -5px;
}

.topicpath a {
  display: block;
  color: #333;
}

.topicpath a:hover {
  opacity: 0.6;
}

.topicpath .home {
  padding-left: 0;
}

.topicpath .home:before {
  display: none;
}

.topicpath .home a {
  /*			
			&:before{
				content:$fa-home;
                @include iconFont;
                margin:0 4px 0 0;
			}*/
}

#footer .underMenu {
  background-image: url(../img/bgMenu01.jpg);
  background-position: center top;
  padding: 50px 0;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu {
    padding: 0;
  }
}

#footer .underMenu ul {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu ul {
    display: block;
    width: auto;
    margin: 0 -10px;
  }
}

#footer .underMenu li {
  display: table-cell;
  vertical-align: middle;
  border-right: dotted 2px #E8C1B2;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu li {
    display: block;
    border-bottom: dotted 2px #E8C1B2;
    border-right: none;
  }
}

#footer .underMenu li:first-child {
  border-left: dotted 2px #E8C1B2;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu li:first-child {
    border-left: none;
  }
}

@media only screen and (max-width: 480px) {
  #footer .underMenu li:last-child {
    border-bottom: none;
  }
}

#footer .underMenu a {
  display: block;
  padding: 10px 0;
  text-align: center;
  color: #FF7A80;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 17px;
  font-size: 1.7rem;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu a {
    padding: 10px;
    font-size: 15px;
    font-size: 1.5rem;
    text-align: left;
  }
  #footer .underMenu a:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}

#footer .underMenu a:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 480px) {
  #footer .underMenu a img {
    float: left;
    width: 60px;
  }
}

#footer .underMenu a .txt {
  margin: 10px 0 0;
}

@media only screen and (max-width: 480px) {
  #footer .underMenu a .txt {
    margin: 0 0 0 70px;
    line-height: 38px;
  }
}

#footer .sitemap {
  background-color: #D6ECE1;
  padding: 0 0 20px;
}

#footer .sitemap .btn {
  position: relative;
  padding: 20px 0 15px;
  cursor: pointer;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  color: #D6A124;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
}

#footer .sitemap .btn:before {
  content: "";
  font-family: FontAwesome;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1em;
  font-size: 22px;
  font-size: 2.2rem;
}

#footer .sitemap .btn.active:before {
  content: "";
}

#footer .sitemap .menu {
  display: none;
  padding: 20px 0 30px;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu {
    padding-bottom: 0;
  }
}

#footer .sitemap .menu .inner {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .inner {
    display: block;
    width: auto;
  }
}

#footer .sitemap .menu .box {
  display: table-cell;
  padding: 0 20px 0 0;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .box {
    padding: 0;
  }
}

#footer .sitemap .menu .box.type02 {
  border-left: dotted 1px #ccc;
  padding-left: 20px;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .box.type02 {
    padding-left: 0;
    border-left: none;
  }
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .box {
    display: block;
  }
}

#footer .sitemap .menu .box > ul > li > a {
  display: block;
  background-image: url(../img/iconCloverBlue01.png);
  background-position: left 0.3em;
  background-repeat: no-repeat;
}

#footer .sitemap .menu .box > ul > li > a span {
  display: block;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .box > ul > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    background-position: left 14px;
  }
}

#footer .sitemap .menu .box > ul > li.group02 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 480px) {
  #footer .sitemap .menu .box > ul > li.group02 {
    margin-bottom: 6px;
  }
}

#footer .sitemap .menu .box > ul > li.group02 > a {
  background-image: url(../img/iconCloverPink01.png);
}

#footer .sitemap .menu .box > ul > li.group03 > a {
  background-image: url(../img/iconCloverGreen01.png);
}

#footer .sitemap .menu li {
  margin-bottom: 6px;
}

#footer .sitemap .menu li li {
  position: relative;
}

#footer .sitemap .menu li li:before {
  content: "";
  position: absolute;
  top: 0.2em;
  margin: auto;
  left: 24px;
  font-family: FontAwesome;
  line-height: 1;
  font-size: 18px;
  font-size: 1.8rem;
}

#footer .sitemap .menu li li a {
  padding-left: 38px;
}

#footer .sitemap .menu a {
  display: block;
  padding: 0 0 0 24px;
  color: #665352;
  text-decoration: none;
}

#footer .sitemap .menu a:hover {
  opacity: 0.6;
  text-decoration: underline;
}

#footer .info {
  background-color: #FFFFF4;
  padding: 60px 0;
}

#footer .info .container {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media only screen and (max-width: 768px) {
  #footer .info .container {
    display: block;
    width: auto;
  }
}

#footer .info .detail, #footer .info .map {
  display: table-cell;
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  #footer .info .detail, #footer .info .map {
    display: block;
  }
}

#footer .info .detail {
  padding: 0 55px 0 0;
}

@media only screen and (max-width: 768px) {
  #footer .info .detail {
    padding: 0;
    margin-bottom: 20px;
  }
}

#footer .info .logo {
  margin: 0 0 15px;
}

#footer .info address {
  border-bottom: dotted 2px #C3DFD3;
  padding: 0 0 12px;
  margin: 0 0 20px;
  font-size: 14px;
  font-size: 1.4rem;
}

#footer .info .department {
  margin-bottom: 25px;
}

#footer .info .department:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

#footer .info .department li {
  float: left;
  background-image: url(../img/iconCloverBlue01.png);
  background-position: left 0.3em;
  background-repeat: no-repeat;
  padding: 0 0 0 20px;
  margin: 0 10px 0 0;
  color: #665352;
}

#footer .info .tel {
  background-image: url(../img/iconTel.svg);
  background-position: left 0.2em;
  background-repeat: no-repeat;
  background-size: 38px 38px;
  padding: 0 0 0 50px;
  margin: 0 0 18px;
  min-height: 42px;
}

#footer .info .tel .num {
  height: 40px;
  line-height: 40px;
  font-size: 34px;
  font-size: 3.4rem;
  color: #B98A00;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 480px) {
  #footer .info .tel .num {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

#footer .info .tel .num .sub {
  margin-left: 4px;
  font-size: 18px;
  font-size: 1.8rem;
  vertical-align: top;
}

#footer .info .tel a {
  text-decoration: none;
}

#footer .info .tel .time {
  font-size: 16px;
  font-size: 1.6rem;
}

#footer .info .btn {
  max-width: 320px;
  margin: 0 0 16px;
}

@media only screen and (max-width: 768px) {
  #footer .info .btn {
    margin: 0 auto 20px;
  }
}

#footer .info .caution {
  background-image: url(../img/iconCloverGreen02.png);
  background-position: left 0.2em;
  background-repeat: no-repeat;
  padding: 0 0 0 24px;
  color: #665352;
  font-size: 14px;
  font-size: 1.4rem;
}

#footer .info .map {
  width: 544px;
  padding: 0 26px 0 0;
}

@media only screen and (max-width: 768px) {
  #footer .info .map {
    width: auto;
    padding: 0;
    text-align: center;
  }
}

#footer .foot {
  background-color: #D6ECE1;
  padding: 22px 0;
  font-size: 14px;
  font-size: 1.4rem;
  color: #665352;
  text-align: center;
}

#footer .foot a {
  padding: 0 5px;
  color: #665352;
  text-decoration: none;
}

#footer .foot a:hover {
  text-decoration: underline;
  opacity: 0.6;
}

#footer .foot ul {
  margin-bottom: 8px;
}

#footer .foot li {
  display: inline;
  line-height: 1;
}

#footer .foot li:after {
  content: "\20\7C\20";
}

#footer .foot li:first-child:before {
  content: "\20\7C\20";
}

#footer .pageTop {
  display: none;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 480px) {
  #footer .pageTop {
    right: 20px;
    bottom: 20px;
  }
}

#cboxClose {
  bottom: auto;
  top: 8px;
  right: 8px !important;
  background-image: url(../img/iconClose.png) !important;
  background-position: 0 0 !important;
  background-size: 30px auto !important;
  width: 30px !important;
  height: 30px !important;
}

#cboxPrevious, #cboxNext {
  background-position: 0 0 !important;
  width: 51px !important;
  height: 51px !important;
  top: 0;
  margin: auto !important;
}

@media only screen and (max-width: 480px) {
  #cboxPrevious, #cboxNext {
    background-size: 30px auto !important;
    width: 30px !important;
    height: 30px !important;
  }
}

#cboxPrevious {
  background-image: url(../img/arrow03L.png) !important;
  left: 10px !important;
}

#cboxNext {
  background-image: url(../img/arrow03.png) !important;
  right: 10px;
  left: auto !important;
}

#cboxCurrent {
  left: auto !important;
  right: 10px;
  color: #fff !important;
  text-shadow: #333 1px 1px 0;
}

/* CSS Document */
.container {
  width: auto;
  max-width: 980px;
}

@media only screen and (min-width: 1160px) {
  .container {
    max-width: 1160px;
  }
}

@media only screen and (max-width: 979px) {
  .container {
    max-width: 768px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container02 {
  width: auto;
  max-width: 980px;
  box-sizing: border-box;
  padding: 0 70px;
}

@media only screen and (min-width: 1160px) {
  .container02 {
    max-width: 1160px;
  }
}

@media only screen and (max-width: 979px) {
  .container02 {
    max-width: 768px;
  }
}

@media only screen and (max-width: 768px) {
  .container02 {
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container03 {
  width: auto;
  max-width: 980px;
  box-sizing: border-box;
  padding: 0 120px;
}

@media only screen and (min-width: 1160px) {
  .container03 {
    max-width: 1160px;
  }
}

@media only screen and (max-width: 979px) {
  .container03 {
    max-width: 768px;
  }
}

@media only screen and (max-width: 768px) {
  .container03 {
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .modPcOnly {
    display: none;
  }
}

.modSpOnly {
  display: none;
}

@media only screen and (max-width: 768px) {
  .modSpOnly {
    display: block;
  }
}

/* modTitle
------------------------------------------------------------*/
.modPageTitle {
  background-image: url(../img/bgTitle.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 15px 0;
  text-align: center;
  /* .modPageTitle */
}

@media only screen and (max-width: 480px) {
  .modPageTitle {
    background-image: url(../img/bgTitleSp.jpg);
    background-size: cover;
  }
}

.modPageTitle .main, .modPageTitle .sub {
  display: block;
}

.modPageTitle .main {
  font-size: 38px;
  font-size: 3.8rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modPageTitle .main {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.modPageTitle .sub {
  font-size: 17px;
  font-size: 1.7rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modPageTitle .sub {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modPageTitle02 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 343px;
  text-align: center;
  /* .modPageTitle02 */
}

@media only screen and (max-width: 768px) {
  .modPageTitle02 {
    height: auto;
  }
}

.modPageTitle02.voice {
  background-image: url(../img/voice/bgPageTitle.jpg);
}

.modPageTitle02.qa {
  background-image: url(../img/qa/bgPageTitle.jpg);
}

.modPageTitle02.nursery {
  background-image: url(../img/taiyou/bgPageTitle.jpg);
}

.modPageTitle02.counsel {
  background-image: url(../img/counsel/bgPageTitle.jpg);
}

.modPageTitle02.kensyu {
  background-image: url(../img/kensyu/bgPageTitle.jpg);
}

.modPageTitle02.keisei {
  background-image: url(../img/keisei/bgPageTitle.jpg);
}

.modPageTitle02.fujinka {
  background-image: url(../img/fujinka/bgPageTitle.jpg);
}

.modPageTitle02.child {
  background-image: url(../img/child/index/bgPageTitle.jpg);
}

.modPageTitle02.childKensin {
  background-image: url(../img/child/kensin/bgPageTitle.jpg);
}

.modPageTitle02.repro {
  background-image: url(../img/repro/index/bgPageTitle.jpg);
}

.modPageTitle02.sanka {
  background-image: url(../img/sanka/index/bgPageTitle.jpg);
}

.modPageTitle02.kodawari {
  background-image: url(../img/kodawari/bgPageTitle.jpg);
}

.modPageTitle02.about {
  background-image: url(../img/about/index/bgPageTitle.jpg);
}

.modPageTitle02.rec {
  background-image: url(../img/rec/bgPageTitle.jpg);
}

.modPageTitle02.lohas {
  background-image: url(../img/lohas/index/bgPageTitle.jpg);
}

.modPageTitle02.class {
  background-image: url(../img/class/index/bgPageTitle.jpg);
}

.modPageTitle02 .title {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 0;
}

@media only screen and (max-width: 768px) {
  .modPageTitle02 .title {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 480px) {
  .modPageTitle02 .title {
    padding: 15px 0;
  }
}

.modPageTitle02 .main, .modPageTitle02 .sub {
  display: block;
}

.modPageTitle02 .main {
  font-size: 38px;
  font-size: 3.8rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modPageTitle02 .main {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.modPageTitle02 .sub {
  font-size: 17px;
  font-size: 1.7rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modPageTitle02 .sub {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modTitle01 {
  margin-bottom: 40px;
  text-align: center;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 30px;
  font-size: 3rem;
  /* .modTitle01 */
}

@media only screen and (max-width: 480px) {
  .modTitle01 {
    margin-bottom: 20px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.modTitle01 span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTitle01 span {
    display: inline;
  }
}

.modTitle01_line {
  margin: 0 0 50px;
  padding-top: 30px;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .modTitle01_line {
    padding-top: 15px;
    margin-bottom: 25px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.modTitle01_line:after {
  content: "";
  display: block;
  position: absolute;
  width: 85px;
  height: 1px;
  top: 0;
  left: 50%;
  margin-left: -42px;
  background-color: #000;
}

.modTitle01_pink {
  color: #ef7f87;
}

.modTitle01_blue {
  color: #1490c8;
}

.modTitle01_type02 {
  font-family: "游ゴシック", YuGothic, "メイリオ", Verdana, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-weight: 500;
}

.modTitle02 {
  margin: 0px 0 50px;
  text-align: center;
  /* .modTitle02 */
}

@media only screen and (max-width: 768px) {
  .modTitle02 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .modTitle02 {
    margin-bottom: 30px;
  }
}

.modTitle02 .main, .modTitle02 .sub {
  display: block;
}

.modTitle02 .main {
  font-size: 36px;
  font-size: 3.6rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modTitle02 .main {
    font-size: 20px;
    font-size: 2rem;
  }
}

.modTitle02 .small {
  margin-left: 8px;
  font-size: 22px;
  font-size: 2.2rem;
}

@media only screen and (max-width: 480px) {
  .modTitle02 .small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modTitle02 .small02 {
  display: block;
  font-size: 22px;
  font-size: 2.2rem;
}

@media only screen and (max-width: 480px) {
  .modTitle02 .small02 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modTitle02 .sub {
  font-size: 17px;
  font-size: 1.7rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  color: #FF7A80;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 480px) {
  .modTitle02 .sub {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modTitle02 .icon {
  display: block;
  background-image: url(../img/contact/iconContact.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin: -30px auto 8px;
}

@media only screen and (max-width: 480px) {
  .modTitle02 .icon {
    width: 30px;
    height: 30px;
    background-size: 30px auto;
    margin-top: 0;
  }
}

.modTitle02_khaki .sub {
  color: #82693e;
}

.modTitle02_line {
  margin: 0 0 50px;
  padding-top: 30px;
  position: relative;
}

.modTitle02_line:after {
  content: "";
  display: block;
  position: absolute;
  width: 85px;
  height: 1px;
  top: 0;
  left: 50%;
  margin-left: -42px;
  background-color: #000;
}

.modTitle02_il .title {
  position: relative;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0 160px;
}

@media only screen and (max-width: 768px) {
  .modTitle02_il .title {
    padding: 0;
  }
}

.modTitle02_il .title:before, .modTitle02_il .title:after {
  position: absolute;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .modTitle02_il .title:before, .modTitle02_il .title:after {
    display: none;
  }
}

.modTitle02_il .title:before {
  content: "";
  background-image: url(../img/contact/titleDeco01.png);
  width: 73px;
  height: 48px;
  left: 0;
  bottom: 20px;
}

.modTitle02_il .title:after {
  content: "";
  background-image: url(../img/contact/titleDeco02.png);
  width: 136px;
  height: 86px;
  right: -20px;
  bottom: 0;
}

.modTitle02_ilQa .icon {
  background-image: url(../img/repro/index/decoQa03.png);
}

.modTitle02_ilQa .title {
  position: relative;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0 153px;
}

@media only screen and (max-width: 768px) {
  .modTitle02_ilQa .title {
    padding: 0;
  }
}

.modTitle02_ilQa .title:before, .modTitle02_ilQa .title:after {
  position: absolute;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .modTitle02_ilQa .title:before, .modTitle02_ilQa .title:after {
    display: none;
  }
}

.modTitle02_ilQa .title:before {
  content: "";
  background-image: url(../img/repro/index/decoQa01.png);
  width: 136px;
  height: 60px;
  left: 0;
  bottom: 15px;
}

.modTitle02_ilQa .title:after {
  content: "";
  background-image: url(../img/repro/index/decoQa02.png);
  width: 136px;
  height: 60px;
  right: 0px;
  bottom: 15px;
}

.modTitle03 {
  margin-bottom: 0;
  text-align: center;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 30px;
  font-size: 3rem;
  border-top: 1px solid #ffa1a5;
  padding: 28px 0;
  background-image: url(../img/bgTitle02.jpg);
  background-position: left top;
  background-repeat: repeat;
  /* .modTitle03 */
}

@media only screen and (max-width: 480px) {
  .modTitle03 {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.modTitle04 {
  margin-bottom: 40px;
  text-align: center;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 30px;
  font-size: 3rem;
  color: #666;
  padding: 15px 0;
  background-image: url(../img/line.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  /* .modTitle04 */
}

.modTitle04_type02 {
  background-image: url(../img/line02.png);
  background-repeat: repeat-x;
  background-size: auto 5px;
}

@media only screen and (max-width: 480px) {
  .modTitle04 {
    margin-bottom: 20px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.modTitle05 {
  margin: 0 0 40px;
  padding-top: 30px;
  text-align: center;
  color: #ef7f87;
  font-size: 30px;
  font-size: 3rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  position: relative;
  /* .modTitle05 */
}

@media only screen and (max-width: 480px) {
  .modTitle05 {
    margin-bottom: 20px;
  }
}

.modTitle05:after {
  content: "";
  display: block;
  position: absolute;
  width: 85px;
  height: 1px;
  top: 0;
  left: 50%;
  margin-left: -42px;
  background-color: #FF7A80;
}

@media only screen and (max-width: 480px) {
  .modTitle05 {
    margin: 60px 0 20px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.modTitle05 span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTitle05 span {
    display: inline;
  }
}

.modTitle06 {
  margin-bottom: 20px;
  font-size: 25px;
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.1em;
}

/* modTxt
------------------------------------------------------------*/
.modTxtLink {
  background-image: url(../img/arrow01.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 0 0 3px 28px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  /* .modTxtLink */
}

@media only screen and (max-width: 768px) {
  .modTxtLink {
    background-position: left 0.2em;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .modTxtLink {
    background-position: left 0.2em;
    background-size: 15px auto;
    padding-left: 20px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modTxtLink:hover span {
  color: #B98A00;
  border-color: #B98A00;
}

.modTxtLink span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  border-bottom: dotted 1px #000;
}

.modTxtBlock {
  margin-bottom: 40px;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  color: #665352;
  /* .modTxtBlock */
}

@media only screen and (max-width: 480px) {
  .modTxtBlock {
    margin-bottom: 20px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.modTxtBlock p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock p {
    margin-bottom: 15px;
  }
}

.modTxtBlock span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock span {
    display: inline;
  }
}

.modTxtBlock02 {
  margin-bottom: 40px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #665352;
  /* .modTxtBlock02 */
}

@media only screen and (max-width: 480px) {
  .modTxtBlock02 {
    margin-bottom: 20px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.modTxtBlock02 p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock02 p {
    margin-bottom: 15px;
  }
}

.modTxtBlock02 span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock02 span {
    display: inline;
  }
}

.modTxtBlock03 {
  margin-bottom: 40px;
  padding: 0 80px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #665352;
  /* .modTxtBlock03 */
}

@media only screen and (max-width: 480px) {
  .modTxtBlock03 {
    margin-bottom: 20px;
    padding: 0;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.modTxtBlock03 p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock03 p {
    margin-bottom: 15px;
  }
}

.modTxtBlock03 p span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTxtBlock03 p span {
    display: inline;
  }
}

.modTxtCatch {
  margin-bottom: 20px;
  text-align: center;
  color: #ef7f87;
  font-size: 30px;
  font-size: 3rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
}

@media only screen and (max-width: 480px) {
  .modTxtCatch {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.modTxtCatch span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTxtCatch span {
    display: inline;
  }
}

.modTxtCatch_type02 {
  color: #333;
}

.modTxtPink {
  padding: 33px;
  background: url(../img/bgMenu01.jpg) repeat top left;
  text-align: center;
  font-weight: bold;
  color: #665352;
  font-size: 22px;
  font-size: 2.2rem;
}

@media only screen and (max-width: 480px) {
  .modTxtPink {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 15px;
  }
}

.modTxtPink .sub {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: normal;
}

@media only screen and (max-width: 480px) {
  .modTxtPink .sub {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

/* modBtn
------------------------------------------------------------*/
.modBtn01, .modBtn03 {
  display: block;
  border: none;
  background-color: #FF7A80;
  border: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  /* .modBtn01 */
}

@media only screen and (max-width: 480px) {
  .modBtn01, .modBtn03 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modBtn01:hover, .modBtn03:hover {
  background-color: #F99BA2;
}

.modBtn01:after, .modBtn03:after {
  content: "";
  background-image: url(../img/arrow01.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
}

.modBtn01_small, .modBtn03_small {
  font-size: 15px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 480px) {
  .modBtn01_small, .modBtn03_small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modBtn01_large, .modBtn03_large {
  font-size: 20px;
  font-size: 2rem;
}

@media only screen and (max-width: 480px) {
  .modBtn01_large, .modBtn03_large {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.modBtn01_inline, .modBtn03_inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
}

.modBtn01_back, .modBtn03_back {
  background-color: #969696;
}

.modBtn01_back:after, .modBtn03_back:after {
  background-image: url(../img/arrow01L.png);
  left: 10px;
  right: auto;
}

.modBtn01_back:hover, .modBtn03_back:hover {
  background-color: #aaa;
}

.modBtn01_arrowB:after, .modBtn03_arrowB:after {
  background-image: url(../img/arrow01B.png);
}

.modBtn01_dl:after, .modBtn03_dl:after {
  background-image: url(../img/iconDl.png);
  background-size: 25px auto;
  background-color: transparent;
  width: 25px;
  height: 26px;
  left: 20px;
  right: auto;
  border-radius: 0;
}

@media only screen and (max-width: 480px) {
  .modBtn01_dl:after, .modBtn03_dl:after {
    background-size: 20px auto;
    width: 20px;
    height: 21px;
  }
}

.modBtn01_green {
  background: #8eb355;
}

.modBtn01_green:hover {
  background-color: #a1c46a;
}

.modBtn01_yellow, .modBtn03_yellow {
  background: #f1af2d;
}

.modBtn01_yellow:hover, .modBtn03_yellow:hover {
  background-color: #f4c260;
}

.modBtn01_brown, .modBtn03_brown {
  background: #BF9250;
}

.modBtn01_brown:hover, .modBtn03_brown:hover {
  background-color: #BF9250;
}

.modBtn02 {
  display: block;
  border: solid 1px #ef7f87;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  padding: 8px 40px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  color: #ef7f87;
  text-decoration: none;
  text-align: center;
  /* .modBtn02 */
}

.modBtn02:hover {
  background-color: rgba(239, 127, 135, 0.05);
}

@media only screen and (max-width: 480px) {
  .modBtn02 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modBtn02_inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
}

.modBtn03 {
  /* .modBtn03 */
}

.modBtn03:after {
  background-image: url(../img/arrow03.png);
}

.modBtn03_back:after {
  background-image: url(../img/arrow03L.png);
}

.modBtn03_arrowB:after {
  background-image: url(../img/arrow03B.png);
}

.modBtn03_dl:after {
  background-image: url(../img/iconDl.png);
}

.modBtnRich {
  position: relative;
  margin-bottom: 40px;
  border-radius: 10px;
  background-image: url(../img/bgBtn01.png);
  background-size: cover;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 15px;
  text-align: center;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  cursor: pointer;
  /* .modBtnRich */
}

@media only screen and (max-width: 480px) {
  .modBtnRich {
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  }
}

.modBtnRich_type02 {
  background-image: url(../img/bgBtn02.png);
}

.modBtnRich:hover {
  opacity: 0.8;
}

.modBtnRich:after {
  content: "";
  background-image: url(../img/arrowAcc.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  margin: auto;
}

@media only screen and (max-width: 480px) {
  .modBtnRich:after {
    right: 10px;
    background-size: 15px auto;
    width: 15px;
    height: 15px;
  }
}

.modBtnRich.on:after {
  background-position: 0 -29px;
}

@media only screen and (max-width: 480px) {
  .modBtnRich.on:after {
    background-position: 0 -15px;
  }
}

.modBtnRich .main, .modBtnRich .sub {
  position: relative;
}

.modBtnRich .main {
  line-height: 1;
  color: #ef7f87;
  font-size: 32px;
  font-size: 3.2rem;
}

@media only screen and (max-width: 480px) {
  .modBtnRich .main {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.modBtnRich_type02 .main {
  color: #5ca895;
}

.modBtnRich .sub {
  margin-top: 10px;
  color: #665352;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 480px) {
  .modBtnRich .sub {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modBtnRich .inner {
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 480px) {
  .modBtnRich .inner {
    padding: 20px 40px;
  }
}

.modBtnRich .inner:before, .modBtnRich .inner:after {
  content: "";
  position: absolute;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .modBtnRich .inner:before, .modBtnRich .inner:after {
    display: none;
  }
}

.modBtnRich .inner:before {
  background-image: url(../img/decoBtn01_01.png);
  width: 290px;
  height: 102px;
  left: 0;
  bottom: 10px;
}

.modBtnRich .inner:after {
  background-image: url(../img/decoBtn01_02.png);
  width: 162px;
  height: 87px;
  right: 60px;
  top: 20px;
}

.modBtnRich_type02 .inner:before {
  background-image: url(../img/decoBtn02_01.png);
  width: 201px;
  height: 104px;
  bottom: auto;
  top: 16px;
  left: 16px;
}

.modBtnRich_type02 .inner:after {
  background-image: url(../img/decoBtn02_02.png);
  width: 258px;
  height: 138px;
  top: 0;
  right: 0;
}

.modBtnRich_small .inner {
  padding: 30px 0;
}

@media only screen and (max-width: 480px) {
  .modBtnRich_small .inner {
    padding: 20px 40px;
  }
}

.modBtnRich_small .inner:before {
  left: -100px;
}

.modBtnRich_small .inner:after {
  right: -40px;
  top: 0;
}

.modBtnRich_small.modBtnRich_type02 .inner:before {
  top: -10px;
  left: 16px;
}

.modBtnList {
  /* .modBtnList */
}

.modBtnList li {
  max-width: 402px;
  margin: 0 auto;
}

.modBtnList_column02 {
  position: relative;
  max-width: 890px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .modBtnList_column02 {
    max-width: none;
  }
}

.modBtnList_column02 li {
  width: 49%;
  max-width: none;
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .modBtnList_column02 li {
    width: auto;
  }
}

.modBtnList_column02 .right {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .modBtnList_column02 .right {
    position: static;
    margin-bottom: 10px;
  }
}

/* modBlock
------------------------------------------------------------*/
.modBlockInner {
  padding: 70px 0;
}

@media only screen and (max-width: 768px) {
  .modBlockInner {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 480px) {
  .modBlockInner {
    padding: 20px 0;
  }
}

.modBlockFrame {
  background-image: url(../img/bgPattern01.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame02 {
  background-color: #FFFDF8;
}

.modBlockFrame03 {
  background-color: #F6F4F0;
}

.modBlockFrame04 {
  background-image: url(../img/bgPattern03.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame05 {
  background-image: url(../img/bgPattern02.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame06 {
  background-image: url(../img/bgPattern04.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame07 {
  background-color: #FFE5D5;
}

.modBlockFrame08 {
  background-image: url(../img/bgPattern05.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame09 {
  background: #f8f7f4;
}

.modBlockFrame10 {
  background-image: url(../img/bgPattern06.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame11 {
  background-image: url(../img/bgPattern07.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame12 {
  background-image: url(../img/bgPattern08.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame13 {
  background-image: url(../img/bgPattern09.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockFrame14 {
  background-image: url(../img/bgPattern10.jpg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: repeat-y;
}

.modBlockNews {
  overflow: hidden;
  /* .modBlockNews */
}

.modBlockNews_link {
  margin-bottom: 15px;
  text-align: right;
}

.modBlockNews_link .modTitle02 {
  margin-bottom: 10px;
}

.modBlockNews .container {
  max-width: 980px;
}

.modBlockNews .tabMenu {
  margin-bottom: 30px;
}

.modBlockNews .tabMenu ul {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .modBlockNews .tabMenu ul {
    display: block;
    text-align: center;
  }
}

.modBlockNews .tabMenu li {
  display: table-cell;
  vertical-align: middle;
  border-right: solid 1px #000;
}

@media only screen and (max-width: 480px) {
  .modBlockNews .tabMenu li {
    display: inline-block;
    border-right: none;
  }
  .modBlockNews .tabMenu li:after {
    content: "\7C";
  }
}

.modBlockNews .tabMenu li:last-child {
  border-right: none;
}

.modBlockNews .tabMenu a {
  display: block;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .tabMenu a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 480px) {
  .modBlockNews .tabMenu a {
    display: inline-block;
    padding: 8px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modBlockNews .tabMenu a:hover {
  color: #B98A00;
}

.modBlockNews .tabMenu .active a {
  color: #B98A00;
}

.modBlockNews .sliderWrap {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .sliderWrap {
    padding: 0 30px;
  }
}

.modBlockNews .slick-arrow {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 50px auto;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  z-index: 100;
}

@media only screen and (max-width: 480px) {
  .modBlockNews .slick-arrow {
    background-size: 40px auto;
    width: 40px;
    height: 40px;
  }
}

.modBlockNews .slick-arrow:hover {
  opacity: 0.8;
}

.modBlockNews .slick-arrow:focus {
  outline: none;
}

.modBlockNews .slick-next {
  background-image: url(../img/arrow01.svg);
  right: -60px;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .slick-next {
    right: -30px;
  }
}

.modBlockNews .slick-prev {
  background-image: url(../img/arrow01L.svg);
  left: -60px;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .slick-prev {
    left: -30px;
  }
}

.modBlockNews .slide {
  width: 245px;
}

.modBlockNews .slide a {
  display: block;
  background-color: #F2F2F2;
  border-right: solid 1px #eee;
  position: relative;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .modBlockNews .slide a {
    border-right: none;
  }
}

.modBlockNews .slide a:hover .body {
  background-color: #fff;
}

.modBlockNews .slide a:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  width: 0px;
  margin: auto;
  border: 10px solid transparent;
  border-bottom: 10px solid #ccc;
  border-right: 10px solid #ccc;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .slide:nth-child(even) a {
    border-right: none;
  }
}

.modBlockNews .slide:nth-child(4n) a {
  border-right: none;
}

.modBlockNews .slide .image img {
  width: 100%;
}

.modBlockNews .slide.new .image:before {
  content: "";
  background-image: url(../img/iconNew.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
}

.modBlockNews .slide .body {
  padding: 10px 14px 30px;
  color: #666;
}

@media only screen and (max-width: 768px) {
  .modBlockNews .slide .body {
    padding: 10px 10px 15px;
  }
}

.modBlockNews .slide .head {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 15px;
}

.modBlockNews .slide .head .date, .modBlockNews .slide .head .category {
  display: table-cell;
  vertical-align: middle;
}

.modBlockNews .slide .head .date {
  width: 6em;
}

.modBlockNews .slide .date {
  font-size: 13px;
  font-size: 1.3rem;
}

.modBlockNews .slide .category {
  text-align: right;
}

.modBlockNews .slide .title {
  margin-bottom: 8px;
  overflow: hidden;
  height: 44px;
  line-height: 22px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.modBlockNews .slide .title span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.modBlockNews .slide .txt {
  overflow: hidden;
  height: 40px;
  line-height: 20px;
  font-size: 13px;
  font-size: 1.3rem;
}

.modBlockNews .slide .txt span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.modBlockEntryDetail {
  /* .modBlockEntryDetail */
}

.modBlockEntryDetail .body {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 40px 0;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .body {
    padding: 20px 0;
  }
}

.modBlockEntryDetail .head {
  background-image: url(../img/line.png);
  background-position: left bottom;
  background-repeat: repeat-x;
  display: table;
  width: 100%;
  table-layout: fixed;
  max-width: 710px;
  margin: 0 auto 46px;
  padding: 0 0 18px;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head {
    display: block;
    width: auto;
    padding: 0 10px 15px;
    background-size: auto 2px;
  }
}

@media only screen and (min-width: 1160px) {
  .modBlockEntryDetail .head {
    max-width: 910px;
  }
}

.modBlockEntryDetail .head .left, .modBlockEntryDetail .head .right {
  display: table-cell;
  vertical-align: bottom;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head .left, .modBlockEntryDetail .head .right {
    display: block;
  }
}

.modBlockEntryDetail .head .top {
  margin-bottom: 6px;
}

.modBlockEntryDetail .head .date {
  display: inline;
  margin: 0 10px 0 0;
  color: #666666;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head .date {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modBlockEntryDetail .head .category {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-size: 0;
  vertical-align: super;
}

.modBlockEntryDetail .head .title {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 23px;
  font-size: 2.3rem;
  line-height: 1.5;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head .title {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.modBlockEntryDetail .head .right {
  width: 220px;
  text-align: right;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head .right {
    width: auto;
  }
}

.modBlockEntryDetail .head .sns {
  font-size: 0;
}

.modBlockEntryDetail .head .sns li {
  display: inline;
  margin: 0 0 0 8px;
}

.modBlockEntryDetail .head .sns img {
  width: 40px;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .head .sns img {
    width: 30px;
  }
}

.modBlockEntryDetail .head .sns a:hover {
  opacity: 0.8;
}

.modBlockEntryDetail .main {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 10px;
}

.modBlockEntryDetail .main .modPartsTxt {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .main .modPartsTxt {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.modBlockEntryDetail .main .btnPdf {
  max-width: 402px;
  margin: 75px auto 0;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .main .btnPdf {
    margin-top: 30px;
  }
}

.modBlockEntryDetail .foot {
  background-color: #F2EBE9;
  padding: 30px 0;
  margin: 0 0 15px;
  border-radius: 0 0 5px 5px;
  color: #4d4d4d;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .foot {
    padding: 15px 0;
  }
}

.modBlockEntryDetail .foot .txt {
  margin-bottom: 10px;
}

.modBlockEntryDetail .foot .sns {
  font-size: 0;
}

.modBlockEntryDetail .foot .sns li {
  display: inline;
  margin: 0 8px;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .foot .sns li {
    margin: 0 4px;
  }
}

.modBlockEntryDetail .foot .sns img {
  width: 40px;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .foot .sns img {
    width: 30px;
  }
}

.modBlockEntryDetail .foot .sns a:hover {
  opacity: 0.8;
}

.modBlockEntryDetail .btnFoot {
  text-align: right;
}

@media only screen and (max-width: 480px) {
  .modBlockEntryDetail .btnFoot {
    text-align: center;
  }
  .modBlockEntryDetail .btnFoot a {
    display: block;
  }
}

.modBlock01 {
  font-size: 18px;
  font-size: 1.8rem;
  font-size: 1.8rem;
  border: 1px solid #f17083;
  border-radius: 10px;
  background-color: #fff;
  padding: 40px 60px;
  text-align: center;
  margin-bottom: 40px;
  /* .modBlock01 */
}

@media only screen and (max-width: 480px) {
  .modBlock01 {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 20px 30px;
  }
}

.modBlock01_type02 {
  border: none;
}

.modBlockCaseBox ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modBlockCaseBox .box {
  width: 47%;
  float: left;
  border: 1px solid #1490c8;
  border-radius: 5px;
  margin-bottom: 40px;
  background: #fff;
}

@media only screen and (max-width: 480px) {
  .modBlockCaseBox .box {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

.modBlockCaseBox .box:nth-child(even) {
  float: right;
}

@media only screen and (max-width: 480px) {
  .modBlockCaseBox .box:nth-child(even) {
    float: none;
  }
}

.modBlockCaseBox .box .title {
  color: #1490c8;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 27px;
  font-size: 2.7rem;
  text-align: center;
  background: url(../img/bgPattern02.jpg) repeat top left;
  padding: 15px 5px;
  border-radius: 4px 4px 0 0;
}

@media only screen and (max-width: 480px) {
  .modBlockCaseBox .box .title {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.modBlockCaseBox .box .title .small {
  font-size: 22px;
  font-size: 2.2rem;
  margin-left: 5px;
}

@media only screen and (max-width: 480px) {
  .modBlockCaseBox .box .title .small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modBlockCaseBox .box .txt {
  padding: 20px 25px;
}

@media only screen and (max-width: 480px) {
  .modBlockCaseBox .box .txt {
    padding: 15px;
  }
}

.modBlockCaseBox .boxLong {
  width: 100%;
  float: none;
}

/* modTable
------------------------------------------------------------*/
.modTbl01 {
  border: solid 1px #A0A0A0;
  margin: 0 0 50px;
}

@media only screen and (max-width: 480px) {
  .modTbl01 {
    margin-bottom: 20px;
  }
}

.modTbl01_fixed {
  table-layout: fixed;
}

.modTbl01 th, .modTbl01 td {
  padding: 20px 26px;
}

@media only screen and (max-width: 480px) {
  .modTbl01 th, .modTbl01 td {
    padding: 10px;
  }
}

.modTbl01 th {
  background-color: #ef7f87;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
}

@media only screen and (max-width: 480px) {
  .modTbl01 th {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modTbl01 thead th {
  border-right: dotted 2px #fff;
  border-bottom: solid 1px #fff;
}

.modTbl01 thead th:last-child {
  border-right: none;
}

.modTbl01 tbody th {
  border-bottom: dotted 2px #fff;
}

.modTbl01 tbody td {
  background-color: #fff;
  border-bottom: dotted 2px #CFB02D;
  border-right: dotted 2px #CFB02D;
  color: #665352;
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 480px) {
  .modTbl01 tbody td {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modTbl01 tbody td:last-child {
  border-right: none;
}

.modTbl01 tbody tr:last-child th, .modTbl01 tbody tr:last-child td {
  border-bottom: none;
}

.modTbl01_type02 tbody th {
  background-color: #fff;
  border-right: dotted 2px #CFB02D;
  border-bottom-color: #CFB02D;
  color: #ef7f87;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  vertical-align: top;
}

@media only screen and (max-width: 480px) {
  .modTbl01_type02 tbody th {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modTbl01_type02 .subTitle th {
  padding: 0;
  border-right: none;
}

.modTbl01_type02 .subTitle th .title {
  position: relative;
  background: #f0ebe2;
  color: #665352;
  border-bottom: 1px solid #c4b498;
  margin: -2px 0;
  padding: 20px 26px;
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .modTbl01_type02 .subTitle th .title {
    padding: 10px 16px;
  }
}

.modTbl01 .valignTop {
  vertical-align: top;
}

.modTbl_scroll {
  margin-bottom: 20px;
}

@media only screen and (max-width: 480px) {
  .modTbl_scroll {
    background: url(../img/scroll.png) no-repeat bottom center;
    background-size: 100% auto;
    padding-bottom: 50px;
  }
  .modTbl_scroll:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}

.modTbl02 {
  width: 100%;
  border: solid 1px #c5c5c5;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  background: #fff;
}

.modTbl02_head {
  border-bottom: none;
}

.modTbl02_body {
  border-top: none;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tr, .modTbl02 tr th, .modTbl02 tr td {
    display: block;
  }
  .modTbl02_head {
    float: left;
    width: 25%;
    border-bottom: #c5c5c5;
    border-right: none;
  }
  .modTbl02_body {
    float: right;
    width: 75%;
    overflow-x: auto;
    border-top: #c5c5c5;
    border-left: none;
  }
  .modTbl02_body table {
    width: 100%;
    white-space: nowrap;
    display: table;
  }
  .modTbl02_body tbody tr {
    width: auto;
    display: table-cell;
  }
}

.modTbl02 th, .modTbl02 td {
  padding: 15px 5px;
  box-sizing: border-box;
  background: #fff;
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 480px) {
  .modTbl02 th, .modTbl02 td {
    padding: 5px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .modTbl02 th {
    height: 80px;
  }
}

.modTbl02 td {
  width: 11%;
}

@media only screen and (max-width: 480px) {
  .modTbl02 td {
    width: 100%;
    height: 30px;
  }
}

.modTbl02 thead th, .modTbl02 thead td {
  background-color: #ef7f87;
  border-right: dotted 2px #fff;
  color: #fff;
  text-align: center;
}

.modTbl02 thead th:last-child, .modTbl02 thead td:last-child {
  border-right: none;
}

@media only screen and (max-width: 480px) {
  .modTbl02 thead th, .modTbl02 thead td {
    border-right: none;
    border-bottom: dotted 1px #fff;
  }
  .modTbl02 thead th:last-child, .modTbl02 thead td:last-child {
    border-bottom: none;
  }
}

.modTbl02 tbody th, .modTbl02 tbody td {
  border-right: dotted 2px #CFB02D;
  border-bottom: dotted 2px #CFB02D;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody th, .modTbl02 tbody td {
    border-right: dotted 1px #CFB02D;
    border-bottom: dotted 1px #CFB02D !important;
  }
  .modTbl02 tbody th:last-child, .modTbl02 tbody td:last-child {
    border-bottom: none !important;
  }
}

.modTbl02 tbody th {
  padding: 15px;
  color: #ef7f87;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody th {
    width: 100%;
    height: 80px;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
  }
}

.modTbl02 tbody th span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #726261;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody th span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modTbl02 tbody td {
  background-color: #fff;
  text-align: center;
  color: #665352;
  line-height: 1.4;
}

.modTbl02 tbody td:last-child {
  border-right: none;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td:last-child {
    border-right: dotted 1px #CFB02D;
  }
}

.modTbl02 tbody td span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td span {
    display: inline;
  }
}

.modTbl02 tbody td span {
  display: block;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td span {
    display: inline;
  }
}

.modTbl02 tbody td.reserve {
  background: #fff6f9;
}

.modTbl02 tbody td.reserve strong {
  color: #ef7f87;
  display: block;
  margin-bottom: 5px;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td.reserve strong {
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

.modTbl02 tbody td.reserve .small {
  font-size: 12px;
  font-size: 1.2rem;
  border-radius: 3px;
  margin-top: 5px;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td.reserve .small {
    margin-top: 0;
    margin-left: 5px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.modTbl02 tbody td.sub span {
  font-size: 16px;
  font-size: 1.6rem;
  color: #928383;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody td.sub span {
    font-size: 10px;
    font-size: 1rem;
  }
}

.modTbl02 tbody tr:last-child th, .modTbl02 tbody tr:last-child td {
  border-bottom: none;
}

@media only screen and (max-width: 480px) {
  .modTbl02 tbody tr:last-child th, .modTbl02 tbody tr:last-child td {
    border-right: none;
  }
}

.modTbl02_type02 thead th, .modTbl02_type02 thead td {
  border-right: dotted 2px #fff;
}

.modTbl02_type02 tbody th, .modTbl02_type02 tbody td {
  border-right: dotted 2px #CFB02D;
  border-bottom: dotted 2px #CFB02D;
  color: #FF7A80;
}

.modTbl02_type02 tbody th {
  font-size: 16px;
  font-size: 1.6rem;
}

.modTbl03 {
  border: 1px solid #58a0c0;
  border-collapse: separate;
  border-radius: 5px;
  margin: 20px auto;
  width: 90%;
  font-size: 20px;
  font-size: 2rem;
}

@media only screen and (max-width: 480px) {
  .modTbl03 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modTbl03 caption {
  color: #308cb8;
  font-weight: bold;
}

.modTbl03 th,
.modTbl03 td {
  padding: 20px;
}

@media only screen and (max-width: 480px) {
  .modTbl03 th,
  .modTbl03 td {
    padding: 10px;
    display: block;
  }
}

.modTbl03 th {
  background: #58a0c0;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  color: #fff;
  text-align: center;
}

.modTbl03 td {
  color: #58a0c0;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
}

.modTbl04 {
  background: #fff;
}

.modTbl04 th,
.modTbl04 td {
  padding: 10px;
}

@media only screen and (max-width: 480px) {
  .modTbl04 th,
  .modTbl04 td {
    padding: 10px;
    display: block;
    box-sizing: border-box;
  }
}

.modTbl04 th {
  width: 20%;
  background: #ffc9cd;
  color: #665352;
  font-weight: bold;
  border: 1px solid #d2d2d2;
  vertical-align: top;
}

@media only screen and (max-width: 480px) {
  .modTbl04 th {
    width: 100%;
  }
}

.modTbl04 td {
  border: 1px solid #d2d2d2;
}

/* modList
------------------------------------------------------------*/
.modListNormal {
  margin: 0 0 0 1.5em;
}

.modListNormal.inline li {
  display: inline-block;
  margin-right: 30px;
}

@media only screen and (max-width: 480px) {
  .modListNormal.inline li {
    display: block;
  }
}

.modListNormal_type2 {
  margin: 0 0 20px;
  background-color: #fff;
  padding: 25px 50px;
}

.modListNormal_type2:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 480px) {
  .modListNormal_type2 {
    padding: 25px 30px;
  }
}

.modListNormal_type2 li {
  width: 45%;
  float: left;
}

@media only screen and (max-width: 480px) {
  .modListNormal_type2 li {
    width: auto;
    float: none;
  }
}

ul.modListNormal li:before {
  content: "\0030fb";
  margin-left: -1.5em;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 1.5em;
  text-align: center;
}

ol.modListNormal li {
  list-style: decimal;
}

/* indent */
.modIndent01 li {
  text-indent: -1em;
  margin: 0 0 0 1em;
}

.modIndent02 li {
  text-indent: -2.5em;
  margin: 0 0 0 2.5em;
}

.modList01 {
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px solid #f17083;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 40px;
  /* .modList01 */
}

@media only screen and (max-width: 480px) {
  .modList01 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modList01 > li {
  padding: 30px;
  border-bottom: 1px dotted #b1afaa;
}

@media only screen and (max-width: 480px) {
  .modList01 > li {
    padding: 20px;
  }
}

.modList01 > li:last-child {
  border-bottom: none;
  border-radius: 10px;
}

.modList01 > li dt {
  font-size: 22px;
  font-size: 2.2rem;
  color: #ef7f87;
  font-weight: bold;
  margin-bottom: 15px;
}

@media only screen and (max-width: 480px) {
  .modList01 > li dt {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
}

.modList01_type2 > li:nth-child(even) {
  background-color: #fdfaf3;
}

@media only screen and (max-width: 480px) {
  .modList01_type3 > li {
    padding: 15px;
  }
}

.modList01_type3 > li dl {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.modList01_type3 > li dt, .modList01_type3 > li dd {
  display: table-cell;
  vertical-align: middle;
}

.modList01_type3 > li dt {
  width: 75px;
  padding: 0 35px 0 0;
  text-align: center;
}

.modListFlow {
  overflow: hidden;
  margin: 0 0 50px;
  /* .modListFlow */
}

@media only screen and (max-width: 480px) {
  .modListFlow {
    margin-bottom: 20px;
  }
}

.modListFlow ul {
  margin: 0 -1.5%;
}

.modListFlow ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 480px) {
  .modListFlow ul {
    margin: 0;
  }
}

.modListFlow li {
  float: left;
  width: 22%;
  margin: 0 1.5%;
  background-color: #fff;
  border: 1px solid #ef7f87;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .modListFlow li {
    float: none;
    width: auto;
    margin: 0 0 30px;
  }
}

.modListFlow .body {
  padding: 30px 12px;
}

@media only screen and (max-width: 480px) {
  .modListFlow .body {
    padding: 10px;
  }
}

.modListFlow .body:after {
  content: "";
  background-image: url(../img/arrowFlow.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 32px;
  height: 43px;
  position: absolute;
  top: 112px;
  right: -32px;
  margin: auto;
}

@media only screen and (max-width: 480px) {
  .modListFlow .body:after {
    background-image: url(../img/arrowFlowB.png);
    top: auto;
    bottom: -32px;
    right: 0;
    left: 0;
    width: 43px;
    height: 32px;
  }
}

.modListFlow li:last-child .body:after {
  display: none;
}

.modListFlow .inner {
  padding: 0 8px;
  color: #797979;
  font-size: 17px;
  font-size: 1.7rem;
}

@media only screen and (max-width: 768px) {
  .modListFlow .inner {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modListFlow .inner .txtTicket {
  margin: 0 0 10px;
}

.modListFlow .titleFlow {
  background-image: url(../img/taiyou/line03.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  margin: 0 0 24px;
  padding: 0 0 20px;
  height: 50px;
  line-height: 60px;
  text-align: center;
  color: #ef7f87;
  font-size: 21px;
  font-size: 2.1rem;
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  letter-spacing: 0.15em;
}

@media only screen and (max-width: 480px) {
  .modListFlow .titleFlow {
    margin: 0 0 10px;
    padding: 0 0 10px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modListFlow .titleFlow span {
  margin: 0 0 0 6px;
  font-size: 42px;
  font-size: 4.2rem;
  vertical-align: middle;
}

@media only screen and (max-width: 480px) {
  .modListFlow .titleFlow span {
    font-size: 26px;
    font-size: 2.6rem;
    vertical-align: top;
  }
}

.modListFlow .subTitle {
  text-align: center;
  color: #ef7f87;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 480px) {
  .modListFlow .subTitle {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modListFlow .txt {
  font-size: 14px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 480px) {
  .modListFlow .txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modDl01 {
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px solid #f17083;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  /* .modDl01 */
}

.modDl01 dt {
  width: 25%;
  float: left;
  color: #f4919f;
  font-weight: bold;
  padding: 25px 25px 0 25px;
  box-sizing: border-box;
}

@media only screen and (max-width: 480px) {
  .modDl01 dt {
    font-size: 16px;
    font-size: 1.6rem;
    width: auto;
    float: none;
    margin-bottom: 10px;
  }
}

.modDl01 dd {
  padding: 25px 25px 25px 25%;
  border-bottom: 1px dotted #b2b2b2;
}

.modDl01 dd:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 480px) {
  .modDl01 dd {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0 25px 25px 25px;
  }
}

.modDl01_type2 dt {
  width: 35%;
}

@media only screen and (max-width: 480px) {
  .modDl01_type2 dt {
    font-size: 16px;
    font-size: 1.6rem;
    width: auto;
    float: none;
    margin-bottom: 10px;
  }
}

.modDl01_type2 dd {
  padding: 25px 25px 25px 35%;
}

.modDl01_type2 dd:nth-child(4n) {
  background-color: #fcfaf3;
}

@media only screen and (max-width: 480px) {
  .modDl01_type2 dd {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0 25px 25px 25px;
  }
}

.modDl02 {
  font-size: 18px;
  font-size: 1.8rem;
  border-top: 1px dotted #bab4b3;
  border-bottom: 1px dotted #bab4b3;
  padding: 10px 0;
  margin-bottom: 40px;
  /* .modDl02 */
}

.modDl02 dt {
  width: 15%;
  float: left;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  background-color: #FF7A80;
  border-radius: 10px;
}

@media only screen and (max-width: 480px) {
  .modDl02 dt {
    font-size: 16px;
    font-size: 1.6rem;
    width: auto;
    float: none;
    margin-bottom: 10px;
  }
}

.modDl02 dd {
  padding: 10px 0 10px 17%;
  line-height: 1.4;
}

@media only screen and (max-width: 480px) {
  .modDl02 dd {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 20px;
  }
}

.modDl03 {
  background: #fff;
  border: 1px solid #ef7f87;
  border-radius: 5px;
  padding: 25px 20px;
  margin-bottom: 50px;
  /* .modDl03 */
}

@media only screen and (max-width: 480px) {
  .modDl03 {
    padding: 20px;
    margin-bottom: 30px;
  }
}

.modDl03 dt {
  float: left;
  color: #ef7f87;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  background: url(../img/line_single.png) repeat-y top right;
  padding-right: 30px;
  margin-right: 25px;
}

@media only screen and (max-width: 480px) {
  .modDl03 dt {
    float: none;
    background: url(../img/line.png) repeat-x bottom left;
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modDl03 dd {
  padding-top: 5px;
  margin-bottom: 10px;
}

.modDl03 dd:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modDl03 dd:last-child {
  margin-bottom: 0;
}

.modImgList01 {
  background-color: #fff;
  padding: 25px 80px 20px;
  width: 500px;
  margin: 0 auto 40px;
  /* .modImgList01 */
}

.modImgList01:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 480px) {
  .modImgList01 {
    width: auto;
  }
}

.modImgList01 li {
  float: left;
  margin-right: 30px;
}

@media only screen and (max-width: 480px) {
  .modImgList01 li {
    float: none;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.modImgList01 li:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.modImgList01 li img {
  margin-bottom: 10px;
}

.modImgList01 li .caption {
  text-align: center;
}

.modAnchorLink {
  font-family: "A1明朝", "游明朝体", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "メイリオ", Meiryo, serif;
  font-size: 18px;
  font-size: 1.8rem;
  overflow: hidden;
  margin-bottom: 40px;
  /* .modAnchorLink */
}

@media only screen and (max-width: 480px) {
  .modAnchorLink {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modAnchorLink li {
  width: 20%;
  float: left;
  background: url(../img/line03.png) left top repeat-y;
  padding: 0 5px 0 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li {
    width: 50%;
    margin-bottom: 8px;
  }
  .modAnchorLink li:nth-child(even) {
    background: url(../img/line03.png) left top repeat-y, url(../img/line03.png) right top repeat-y;
    padding-right: 8px;
  }
}

.modAnchorLink li:nth-child(5n) {
  background: url(../img/line03.png) left top repeat-y, url(../img/line03.png) right top repeat-y;
  padding-right: 8px;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li:nth-child(5n) {
    background: url(../img/line03.png) left top repeat-y;
    padding-left: 5px;
  }
}

.modAnchorLink li:last-child:after {
  content: "";
  background-image: url(../img/line03.png);
  background-position: right top;
  background-repeat: repeat-y;
  width: 4px;
  height: 60px;
  position: absolute;
  right: -4px;
  top: 0;
}

.modAnchorLink li a {
  display: block;
  height: 60px;
  box-sizing: border-box;
  padding: 14px 0;
  color: #665352;
  text-decoration: none;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li a {
    padding: 10px 0;
    height: 40px;
  }
}

.modAnchorLink li a:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.modAnchorLink li.row2 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li.row2 {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modAnchorLink li.row2 a {
  padding: 11px 0;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li.row2 a {
    padding: 6px 0;
  }
}

.modAnchorLink li.txtSmall {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink li.txtSmall {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modAnchorLink_col4 li {
  width: 25%;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col4 li {
    width: 50%;
  }
}

.modAnchorLink_col4 li:nth-child(4n) {
  background: url(../img/line03.png) left top repeat-y, url(../img/line03.png) right top repeat-y;
  padding-right: 8px;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col4 li:nth-child(4n) {
    padding-left: 5px;
  }
}

.modAnchorLink_col4 li:nth-child(5n) {
  background: url(../img/line03.png) left top repeat-y;
  padding-right: 5px;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col4 li:nth-child(5n) {
    padding-left: 5px;
    background: url(../img/line03.png) left top repeat-y;
  }
}

.modAnchorLink_col3 li {
  width: 33.333%;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col3 li {
    width: 50%;
  }
}

.modAnchorLink_col3 li:nth-child(3n) {
  background: url(../img/line03.png) left top repeat-y, url(../img/line03.png) right top repeat-y;
  padding-right: 8px;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col3 li:nth-child(3n) {
    padding-left: 5px;
    background: url(../img/line03.png) left top repeat-y;
  }
}

.modAnchorLink_col3 li:nth-child(5n) {
  background: url(../img/line03.png) left top repeat-y;
  padding-right: 5px;
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col3 li:nth-child(5n) {
    padding-left: 5px;
    background: url(../img/line03.png) left top repeat-y;
  }
}

@media only screen and (max-width: 480px) {
  .modAnchorLink_col3 li:nth-child(even) {
    background: url(../img/line03.png) left top repeat-y, url(../img/line03.png) right top repeat-y;
  }
}

.modListPh {
  /*.modListPh  */
}

.modListPh:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modListPh li {
  width: 31.531%;
  float: left;
  margin: 0 2.7035% 66px 0;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .modListPh li {
    width: 100%;
    float: none;
    margin: 0 0 20px 0;
  }
}

.modListPh li:nth-child(3n) {
  margin-right: 0;
}

.modListPh li img {
  margin-bottom: 17px;
}

@media only screen and (max-width: 480px) {
  .modListPh li img {
    margin-bottom: 10px;
  }
}

.modListPh li span {
  display: block;
}

.modListFlower {
  /*.modListFlower*/
}

.modListFlower li {
  background: url(../img/iconCloverBlue04.png) no-repeat 0 3px;
  padding-left: 40px;
  margin-bottom: 20px;
}

.modListFlower li:nth-child(even) {
  background: url(../img/iconCloverGreen04.png) no-repeat 0 3px;
}

.modListFlower_small_pink li {
  margin-bottom: 10px;
  padding-left: 30px;
  background: url(../img/iconCloverPink02.png) no-repeat 0 3px;
  color: #665352;
}

.modListFlower_small_pink li:nth-child(even) {
  background: url(../img/iconCloverPink02.png) no-repeat 0 3px;
}

.modListFlower_small_pink li:last-child {
  margin-bottom: 0;
}

.modListFlower_small_pink li strong {
  font-weight: bold;
}

.modListFlower_small_blue li {
  margin-bottom: 10px;
  padding-left: 30px;
  background: url(../img/iconCloverBlue02.png) no-repeat 0 3px;
  color: #665352;
}

.modListFlower_small_blue li:nth-child(even) {
  background: url(../img/iconCloverBlue02.png) no-repeat 0 3px;
}

.modListFlower_small_blue li strong {
  font-weight: bold;
}

.modListFlower02 {
  padding: 20px;
  margin-bottom: 30px;
  /*.modListFlower02*/
}

.modListFlower02 li {
  font-weight: bold;
  padding-left: 25px;
  margin-bottom: 10px;
  color: #665352;
}

.modListFlower02 li:last-child {
  margin-bottom: 0;
}

.modListFlower02_blue {
  background: #ecf7fb;
}

.modListFlower02_blue li {
  background: url(../img/iconCloverBlue01.png) no-repeat 0 3px;
}

/* wysiwyg
------------------------------------------------------------*/
.modPartsImage, .modPartsImageList, .modPartsYoutube, .modPartsTxt {
  max-width: 710px;
  margin: 0 auto 40px;
}

@media only screen and (min-width: 1160px) {
  .modPartsImage, .modPartsImageList, .modPartsYoutube, .modPartsTxt {
    max-width: 910px;
  }
}

@media only screen and (max-width: 480px) {
  .modPartsImage, .modPartsImageList, .modPartsYoutube, .modPartsTxt {
    margin-bottom: 20px;
  }
}

.modPartsImage {
  text-align: center;
  color: #959595;
  font-size: 11px;
  font-size: 1.1rem;
}

.modPartsImage .caption {
  margin: 5px 0 0;
}

.modPartsImage a:hover {
  opacity: 0.8;
}

.modPartsTitle {
  border-bottom: solid 1px #333;
  padding: 0 0 4px;
  margin: 0 0 20px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media only screen and (max-width: 480px) {
  .modPartsTitle {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modPartsImageList {
  overflow: hidden;
  text-align: center;
}

.modPartsImageList ul {
  margin: 0 -1%;
}

.modPartsImageList ul:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modPartsImageList li {
  float: left;
  width: 48%;
  margin: 0 1%;
}

@media only screen and (max-width: 480px) {
  .modPartsImageList li {
    margin: 0 0 10px;
    float: none;
    width: auto;
  }
}

.modPartsImageList_column03 li {
  width: 31.3%;
}

@media only screen and (max-width: 480px) {
  .modPartsImageList_column03 li {
    width: auto;
    margin: 0 0 10px;
  }
}

.modPartsYoutube {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .modPartsYoutube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .modPartsYoutube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.modPartsTxt iframe {
  width: 100%;
  height: 400px;
}

@media only screen and (max-width: 768px) {
  .modPartsTxt iframe {
    height: 250px;
  }
}

.modPartsTxt ul {
  margin-left: 2em;
}

.modPartsTxt ul li {
  list-style: disc;
}

.modPartsTxt ol {
  margin-left: 2.2em;
}

.modPartsTxt ol li {
  list-style: decimal;
}

.modPartsTxt strong,
.modPartsTxt th {
  font-weight: bold;
}

.modPartsTxt .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modPartsTxt img.alignright {
  padding: 4px;
  margin: 0 0 2px 7px;
  display: inline;
}

.modPartsTxt img.alignleft {
  padding: 4px;
  margin: 0 7px 2px 0;
  display: inline;
}

.modPartsTxt .alignright {
  float: right;
}

.modPartsTxt .alignleft {
  float: left;
}

.modPartsTxt strong {
  font-weight: bold;
}

.modPartsSet02 {
  max-width: 710px;
  margin: 0 auto;
}

@media only screen and (min-width: 1160px) {
  .modPartsSet02 {
    max-width: 910px;
  }
}

.modPartsSet02 .inner {
  margin: 0 -2%;
}

.modPartsSet02 .inner:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 480px) {
  .modPartsSet02 .inner {
    margin: 0;
  }
}

.modPartsSet02 .box {
  float: left;
  width: 46%;
  margin: 0 2%;
}

@media only screen and (max-width: 480px) {
  .modPartsSet02 .box {
    float: none;
    width: auto;
    margin: 0 0 20px;
  }
}

/* modForm
------------------------------------------------------------*/
.modFormTbl {
  table-layout: fixed;
  /* .modFormTbl */
}

@media only screen and (max-width: 480px) {
  .modFormTbl {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .modFormTbl tr, .modFormTbl tbody, .modFormTbl th, .modFormTbl td {
    display: block;
  }
}

.modFormTbl th, .modFormTbl td {
  padding-bottom: 30px;
  vertical-align: top;
}

@media only screen and (max-width: 480px) {
  .modFormTbl th, .modFormTbl td {
    padding-bottom: 0;
  }
}

.modFormTbl th {
  width: 260px;
  padding-top: 0.5em;
}

@media only screen and (max-width: 480px) {
  .modFormTbl th {
    width: auto;
    padding: 0;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 480px) {
  .modFormTbl td {
    margin-bottom: 20px;
  }
}

.modFormRequired {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-image: url(../img/iconCloverRequired.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 22px auto;
  padding: 0 30px 0 0;
}

@media only screen and (max-width: 480px) {
  .modFormRequired {
    background-size: 15px auto;
    padding-right: 20px;
  }
}

.modFormName {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* .modFormName */
}

.modFormName li {
  display: table-cell;
  vertical-align: middle;
  padding: 0 7px 0 0;
}

.modFormName li:last-child {
  padding: 0 0 0 7px;
}

.modFormDate {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* .modFormDate */
}

.modFormDate li {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 480px) {
  .modFormDate select {
    padding: 6px 3px;
  }
}

.modFormDate .txt {
  width: 46px;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .modFormDate .txt {
    width: 30px;
  }
}

.modFormTel {
  /* .modFormTel */
}

.modFormTel:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.modFormTel ul {
  margin: 0 -1%;
}

.modFormTel li {
  float: left;
  width: 31.3%;
  margin: 0 1%;
}

.modFormAddr {
  /* .modFormAddr */
}

.modFormAddr li {
  margin-bottom: 15px;
}

.modFormAddr .zip {
  display: table;
}

.modFormAddr .zip .input, .modFormAddr .zip .btn, .modFormAddr .zip .txt {
  display: table-cell;
  vertical-align: middle;
}

.modFormAddr .zip .input {
  width: 227px;
  padding: 0 17px 0 0;
}

@media only screen and (max-width: 768px) {
  .modFormAddr .zip .input {
    width: 150px;
  }
}

@media only screen and (max-width: 480px) {
  .modFormAddr .zip .input {
    width: 100px;
    padding-right: 8px;
  }
}

.modFormAddr .zip .btn {
  width: 150px;
  padding: 0 17px 0 0;
}

@media only screen and (max-width: 480px) {
  .modFormAddr .zip .btn {
    width: 100px;
    padding-right: 8px;
  }
}

.modFormAddr .zip .btn a {
  display: block;
  padding: 4px 0;
  background-color: #A3A3A3;
  border-radius: 5px;
  box-shadow: #828282 0 4px 0;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .modFormAddr .zip .btn a {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modFormAddr .zip .btn a:hover {
  background-color: #828282;
}

@media only screen and (max-width: 480px) {
  .modFormAddr .zip .txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.modFormCheck {
  font-size: 16px;
  font-size: 1.6rem;
  /* .modFormCheck */
}

@media only screen and (max-width: 480px) {
  .modFormCheck {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.modFormCheck a:hover {
  opacity: 0.6;
}

.modFormCheck label {
  display: block;
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.modFormCheck input {
  margin-right: 5px;
}

.modFormSubTxt {
  margin: 5px 0 0;
}

.modFormErrorTxt {
  margin: 6px 0 0;
  color: #ff4a4b;
  font-weight: bold;
}

.modFormConf {
  /* .modFormConf */
}

.modFormConf dt {
  border-bottom: dashed 2px #F3DAD1;
  margin: 0 0 10px;
  padding: 0 0 4px;
}

.modFormConf dd {
  padding: 0 22px;
  margin: 0 0 50px;
}

@media only screen and (max-width: 480px) {
  .modFormConf dd {
    padding: 0;
    margin: 0 0 20px;
  }
}

/* other
------------------------------------------------------------*/
/*↓消さないこと*/
.modLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.modLoading i {
  font-size: 24px;
  font-size: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
}

/*↑消さないこと*/
.modCategory, .modCategory01, .modCategory02, .modCategory03, .modCategory04, .modCategory05, .modCategory06 {
  border-radius: 3px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0 4px;
  font-size: 10px;
  font-size: 1rem;
  color: #fff;
  vertical-align: middle;
}

.modCategory01 {
  background-color: #469BC0;
}

.modCategory02 {
  background-color: #5AC6C5;
}

.modCategory03 {
  background-color: #E79600;
}

.modCategory04 {
  background-color: #C5884C;
}

.modCategory05 {
  background-color: #FF9B90;
}

.modCategory06 {
  background-color: #8ABD56;
}

.modAncWrapper {
  margin-top: -94px;
  padding-top: 94px;
}

@media only screen and (max-width: 768px) {
  .modAncWrapper {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

.modLazy {
  background-image: url(../img/ajax-loader.gif);
  background-position: center;
  background-repeat: no-repeat;
  /* .modLazy */
}

.modLazy:hover {
  background-image: none;
}

.modLazy.show {
  background-image: none;
}

.modLazy img {
  opacity: 0;
}

/* width
------------------------------------------------------------*/
.cosW5, .cosW10, .cosW15, .cosW20, .cosW25, .cosW30, .cosW35, .cosW40, .cosW45, .cosW50, .cosW55, .cosW60, .cosW65, .cosW70, .cosW75, .cosW80, .cosW85, .cosW90, .cosW95, .cosW100, .cosW105, .cosW110, .cosW115, .cosW120, .cosW125, .cosW130, .cosW135, .cosW140, .cosW145, .cosW150, .cosW155, .cosW160, .cosW165, .cosW170, .cosW175, .cosW180, .cosW185, .cosW190, .cosW195, .cosW200, .cosW205, .cosW210, .cosW215, .cosW220, .cosW225, .cosW230, .cosW235, .cosW240, .cosW245, .cosW250, .cosW255, .cosW260, .cosW265, .cosW270, .cosW275, .cosW280, .cosW285, .cosW290, .cosW295, .cosW300, .cosW305, .cosW310, .cosW315, .cosW320, .cosW325, .cosW330, .cosW335, .cosW340, .cosW345, .cosW350, .cosW355, .cosW360, .cosW365, .cosW370, .cosW375, .cosW380, .cosW385, .cosW390, .cosW395, .cosW400, .cosW405, .cosW410, .cosW415, .cosW420, .cosW425, .cosW430, .cosW435, .cosW440, .cosW445, .cosW450, .cosW455, .cosW460, .cosW465, .cosW470, .cosW475, .cosW480, .cosW485, .cosW490, .cosW495, .cosW500, .cosW505, .cosW510, .cosW515, .cosW520, .cosW525, .cosW530, .cosW535, .cosW540, .cosW545, .cosW550, .cosW555, .cosW560, .cosW565, .cosW570, .cosW575, .cosW580, .cosW585, .cosW590, .cosW595, .cosW600, .cosW605, .cosW610, .cosW615, .cosW620, .cosW625, .cosW630, .cosW635, .cosW640, .cosW645, .cosW650, .cosW655, .cosW660, .cosW665, .cosW670, .cosW675, .cosW680, .cosW685, .cosW690, .cosW695, .cosW700, .cosW705, .cosW710, .cosW715, .cosW720, .cosW725, .cosW730, .cosW735, .cosW740, .cosW745, .cosW750, .cosW755, .cosW760, .cosW765, .cosW770, .cosW775, .cosW780, .cosW785, .cosW790, .cosW795, .cosW800, .cosW805, .cosW810, .cosW815, .cosW820, .cosW825, .cosW830, .cosW835, .cosW840, .cosW845, .cosW850, .cosW855, .cosW860, .cosW865, .cosW870, .cosW875, .cosW880, .cosW885, .cosW890, .cosW895 {
  max-width: 100%;
}

.cosW100per {
  width: 100% !important;
}

.cosW5 {
  width: 5px !important;
}

.cosW10 {
  width: 10px !important;
}

.cosW15 {
  width: 15px !important;
}

.cosW20 {
  width: 20px !important;
}

.cosW25 {
  width: 25px !important;
}

.cosW30 {
  width: 30px !important;
}

.cosW35 {
  width: 35px !important;
}

.cosW40 {
  width: 40px !important;
}

.cosW45 {
  width: 45px !important;
}

.cosW50 {
  width: 50px !important;
}

.cosW55 {
  width: 55px !important;
}

.cosW60 {
  width: 60px !important;
}

.cosW65 {
  width: 65px !important;
}

.cosW70 {
  width: 70px !important;
}

.cosW75 {
  width: 75px !important;
}

.cosW80 {
  width: 80px !important;
}

.cosW85 {
  width: 85px !important;
}

.cosW90 {
  width: 90px !important;
}

.cosW95 {
  width: 95px !important;
}

.cosW100 {
  width: 100px !important;
}

.cosW105 {
  width: 105px !important;
}

.cosW110 {
  width: 110px !important;
}

.cosW115 {
  width: 115px !important;
}

.cosW120 {
  width: 120px !important;
}

.cosW125 {
  width: 125px !important;
}

.cosW130 {
  width: 130px !important;
}

.cosW135 {
  width: 135px !important;
}

.cosW140 {
  width: 140px !important;
}

.cosW145 {
  width: 145px !important;
}

.cosW150 {
  width: 150px !important;
}

.cosW155 {
  width: 155px !important;
}

.cosW160 {
  width: 160px !important;
}

.cosW165 {
  width: 165px !important;
}

.cosW170 {
  width: 170px !important;
}

.cosW175 {
  width: 175px !important;
}

.cosW180 {
  width: 180px !important;
}

.cosW185 {
  width: 185px !important;
}

.cosW190 {
  width: 190px !important;
}

.cosW195 {
  width: 195px !important;
}

.cosW200 {
  width: 200px !important;
}

.cosW205 {
  width: 205px !important;
}

.cosW210 {
  width: 210px !important;
}

.cosW215 {
  width: 215px !important;
}

.cosW220 {
  width: 220px !important;
}

.cosW225 {
  width: 225px !important;
}

.cosW230 {
  width: 230px !important;
}

.cosW235 {
  width: 235px !important;
}

.cosW240 {
  width: 240px !important;
}

.cosW245 {
  width: 245px !important;
}

.cosW250 {
  width: 250px !important;
}

.cosW255 {
  width: 255px !important;
}

.cosW260 {
  width: 260px !important;
}

.cosW265 {
  width: 265px !important;
}

.cosW270 {
  width: 270px !important;
}

.cosW275 {
  width: 275px !important;
}

.cosW280 {
  width: 280px !important;
}

.cosW285 {
  width: 285px !important;
}

.cosW290 {
  width: 290px !important;
}

.cosW295 {
  width: 295px !important;
}

.cosW300 {
  width: 300px !important;
}

.cosW305 {
  width: 305px !important;
}

.cosW310 {
  width: 310px !important;
}

.cosW315 {
  width: 315px !important;
}

.cosW320 {
  width: 320px !important;
}

.cosW325 {
  width: 325px !important;
}

.cosW330 {
  width: 330px !important;
}

.cosW335 {
  width: 335px !important;
}

.cosW340 {
  width: 340px !important;
}

.cosW345 {
  width: 345px !important;
}

.cosW350 {
  width: 350px !important;
}

.cosW355 {
  width: 355px !important;
}

.cosW360 {
  width: 360px !important;
}

.cosW365 {
  width: 365px !important;
}

.cosW370 {
  width: 370px !important;
}

.cosW375 {
  width: 375px !important;
}

.cosW380 {
  width: 380px !important;
}

.cosW385 {
  width: 385px !important;
}

.cosW390 {
  width: 390px !important;
}

.cosW395 {
  width: 395px !important;
}

.cosW400 {
  width: 400px !important;
}

.cosW405 {
  width: 405px !important;
}

.cosW410 {
  width: 410px !important;
}

.cosW415 {
  width: 415px !important;
}

.cosW420 {
  width: 420px !important;
}

.cosW425 {
  width: 425px !important;
}

.cosW430 {
  width: 430px !important;
}

.cosW435 {
  width: 435px !important;
}

.cosW440 {
  width: 440px !important;
}

.cosW445 {
  width: 445px !important;
}

.cosW450 {
  width: 450px !important;
}

.cosW455 {
  width: 455px !important;
}

.cosW460 {
  width: 460px !important;
}

.cosW465 {
  width: 465px !important;
}

.cosW470 {
  width: 470px !important;
}

.cosW475 {
  width: 475px !important;
}

.cosW480 {
  width: 480px !important;
}

.cosW485 {
  width: 485px !important;
}

.cosW490 {
  width: 490px !important;
}

.cosW495 {
  width: 495px !important;
}

.cosW500 {
  width: 500px !important;
}

.cosW505 {
  width: 505px !important;
}

.cosW510 {
  width: 510px !important;
}

.cosW515 {
  width: 515px !important;
}

.cosW520 {
  width: 520px !important;
}

.cosW525 {
  width: 525px !important;
}

.cosW530 {
  width: 530px !important;
}

.cosW535 {
  width: 535px !important;
}

.cosW540 {
  width: 540px !important;
}

.cosW545 {
  width: 545px !important;
}

.cosW550 {
  width: 550px !important;
}

.cosW555 {
  width: 555px !important;
}

.cosW560 {
  width: 560px !important;
}

.cosW565 {
  width: 565px !important;
}

.cosW570 {
  width: 570px !important;
}

.cosW575 {
  width: 575px !important;
}

.cosW580 {
  width: 580px !important;
}

.cosW585 {
  width: 585px !important;
}

.cosW590 {
  width: 590px !important;
}

.cosW595 {
  width: 595px !important;
}

.cosW600 {
  width: 600px !important;
}

.cosW605 {
  width: 605px !important;
}

.cosW610 {
  width: 610px !important;
}

.cosW615 {
  width: 615px !important;
}

.cosW620 {
  width: 620px !important;
}

.cosW625 {
  width: 625px !important;
}

.cosW630 {
  width: 630px !important;
}

.cosW635 {
  width: 635px !important;
}

.cosW640 {
  width: 640px !important;
}

.cosW645 {
  width: 645px !important;
}

.cosW650 {
  width: 650px !important;
}

.cosW655 {
  width: 655px !important;
}

.cosW660 {
  width: 660px !important;
}

.cosW665 {
  width: 665px !important;
}

.cosW670 {
  width: 670px !important;
}

.cosW675 {
  width: 675px !important;
}

.cosW680 {
  width: 680px !important;
}

.cosW685 {
  width: 685px !important;
}

.cosW690 {
  width: 690px !important;
}

.cosW695 {
  width: 695px !important;
}

.cosW700 {
  width: 700px !important;
}

.cosW705 {
  width: 705px !important;
}

.cosW710 {
  width: 710px !important;
}

.cosW715 {
  width: 715px !important;
}

.cosW720 {
  width: 720px !important;
}

.cosW725 {
  width: 725px !important;
}

.cosW730 {
  width: 730px !important;
}

.cosW735 {
  width: 735px !important;
}

.cosW740 {
  width: 740px !important;
}

.cosW745 {
  width: 745px !important;
}

.cosW750 {
  width: 750px !important;
}

.cosW755 {
  width: 755px !important;
}

.cosW760 {
  width: 760px !important;
}

.cosW765 {
  width: 765px !important;
}

.cosW770 {
  width: 770px !important;
}

.cosW775 {
  width: 775px !important;
}

.cosW780 {
  width: 780px !important;
}

.cosW785 {
  width: 785px !important;
}

.cosW790 {
  width: 790px !important;
}

.cosW795 {
  width: 795px !important;
}

.cosW800 {
  width: 800px !important;
}

.cosW805 {
  width: 805px !important;
}

.cosW810 {
  width: 810px !important;
}

.cosW815 {
  width: 815px !important;
}

.cosW820 {
  width: 820px !important;
}

.cosW825 {
  width: 825px !important;
}

.cosW830 {
  width: 830px !important;
}

.cosW835 {
  width: 835px !important;
}

.cosW840 {
  width: 840px !important;
}

.cosW845 {
  width: 845px !important;
}

.cosW850 {
  width: 850px !important;
}

.cosW855 {
  width: 855px !important;
}

.cosW860 {
  width: 860px !important;
}

.cosW865 {
  width: 865px !important;
}

.cosW870 {
  width: 870px !important;
}

.cosW875 {
  width: 875px !important;
}

.cosW880 {
  width: 880px !important;
}

.cosW885 {
  width: 885px !important;
}

.cosW890 {
  width: 890px !important;
}

.cosW895 {
  width: 895px !important;
}

@media only screen and (max-width: 480px) {
  .cosWper10 {
    width: 10% !important;
    max-width: 100%;
  }
  .cosWper15 {
    width: 15% !important;
    max-width: 100%;
  }
  .cosWper20 {
    width: 20% !important;
    max-width: 100%;
  }
  .cosWper25 {
    width: 25% !important;
    max-width: 100%;
  }
  .cosWper30 {
    width: 30% !important;
    max-width: 100%;
  }
  .cosWper35 {
    width: 35% !important;
    max-width: 100%;
  }
  .cosWper40 {
    width: 40% !important;
    max-width: 100%;
  }
  .cosWper45 {
    width: 45% !important;
    max-width: 100%;
  }
  .cosWper50 {
    width: 50% !important;
    max-width: 100%;
  }
  .cosWper55 {
    width: 55% !important;
    max-width: 100%;
  }
  .cosWper60 {
    width: 60% !important;
    max-width: 100%;
  }
  .cosWper65 {
    width: 65% !important;
    max-width: 100%;
  }
  .cosWper70 {
    width: 70% !important;
    max-width: 100%;
  }
  .cosWper75 {
    width: 75% !important;
    max-width: 100%;
  }
  .cosWper80 {
    width: 80% !important;
    max-width: 100%;
  }
  .cosWper85 {
    width: 85% !important;
    max-width: 100%;
  }
  .cosWper90 {
    width: 90% !important;
    max-width: 100%;
  }
  .cosWper95 {
    width: 95% !important;
    max-width: 100%;
  }
}

/* margin,padding
------------------------------------------------------------*/
.mRLAuto {
  margin-left: auto;
  margin-right: auto;
}

.cosMl0 {
  margin-left: 0px !important;
}

.cosMl5 {
  margin-left: 5px !important;
}

.cosMl10 {
  margin-left: 10px !important;
}

.cosMl20 {
  margin-left: 20px !important;
}

.cosMl30 {
  margin-left: 30px !important;
}

.cosMl40 {
  margin-left: 40px !important;
}

.cosMl50 {
  margin-left: 50px !important;
}

.cosMl60 {
  margin-left: 60px !important;
}

.cosMl70 {
  margin-left: 70px !important;
}

.cosMl80 {
  margin-left: 80px !important;
}

.cosMl90 {
  margin-left: 90px !important;
}

.cosMl100 {
  margin-left: 100px !important;
}

.cosMl110 {
  margin-left: 110px !important;
}

.cosMl120 {
  margin-left: 120px !important;
}

.cosMl130 {
  margin-left: 130px !important;
}

.cosMl140 {
  margin-left: 140px !important;
}

.cosMl150 {
  margin-left: 150px !important;
}

.cosMl160 {
  margin-left: 160px !important;
}

.cosMl170 {
  margin-left: 170px !important;
}

.cosMl180 {
  margin-left: 180px !important;
}

.cosMl190 {
  margin-left: 190px !important;
}

.cosMl200 {
  margin-left: 200px !important;
}

.cosMl210 {
  margin-left: 210px !important;
}

.cosMl220 {
  margin-left: 220px !important;
}

.cosMl230 {
  margin-left: 230px !important;
}

.cosMl240 {
  margin-left: 240px !important;
}

.cosMl250 {
  margin-left: 250px !important;
}

.cosMl260 {
  margin-left: 260px !important;
}

.cosMl270 {
  margin-left: 270px !important;
}

.cosMl280 {
  margin-left: 280px !important;
}

.cosMl290 {
  margin-left: 290px !important;
}

.cosMl300 {
  margin-left: 300px !important;
}

.cosMt0 {
  margin-top: 0px !important;
}

.cosMt5 {
  margin-top: 5px !important;
}

.cosMt10 {
  margin-top: 10px !important;
}

.cosMt15 {
  margin-top: 15px !important;
}

.cosMt20 {
  margin-top: 20px !important;
}

.cosMt30 {
  margin-top: 30px !important;
}

.cosMt40 {
  margin-top: 40px !important;
}

.cosMt50 {
  margin-top: 50px !important;
}

.cosMt60 {
  margin-top: 60px !important;
}

.cosMt70 {
  margin-top: 70px !important;
}

.cosMt80 {
  margin-top: 80px !important;
}

.cosMt90 {
  margin-top: 90px !important;
}

.cosMt100 {
  margin-top: 100px !important;
}

.cosMt110 {
  margin-top: 110px !important;
}

.cosMt120 {
  margin-top: 120px !important;
}

.cosMt130 {
  margin-top: 130px !important;
}

.cosMt140 {
  margin-top: 140px !important;
}

.cosMt150 {
  margin-top: 150px !important;
}

.cosMt160 {
  margin-top: 160px !important;
}

.cosMt170 {
  margin-top: 170px !important;
}

.cosMt180 {
  margin-top: 180px !important;
}

.cosMt190 {
  margin-top: 190px !important;
}

.cosMt200 {
  margin-top: 200px !important;
}

.cosMt210 {
  margin-top: 210px !important;
}

.cosMt220 {
  margin-top: 220px !important;
}

.cosMt230 {
  margin-top: 230px !important;
}

.cosMt240 {
  margin-top: 240px !important;
}

.cosMt250 {
  margin-top: 250px !important;
}

.cosMt260 {
  margin-top: 260px !important;
}

.cosMt270 {
  margin-top: 270px !important;
}

.cosMt280 {
  margin-top: 280px !important;
}

.cosMt290 {
  margin-top: 290px !important;
}

.cosMt300 {
  margin-top: 300px !important;
}

.cosMr0 {
  margin-right: 0px !important;
}

.cosMr5 {
  margin-right: 5px !important;
}

.cosMr10 {
  margin-right: 10px !important;
}

.cosMr20 {
  margin-right: 20px !important;
}

.cosMr30 {
  margin-right: 30px !important;
}

.cosMr40 {
  margin-right: 40px !important;
}

.cosMr50 {
  margin-right: 50px !important;
}

.cosMr60 {
  margin-right: 60px !important;
}

.cosMr70 {
  margin-right: 70px !important;
}

.cosMr80 {
  margin-right: 80px !important;
}

.cosMr90 {
  margin-right: 90px !important;
}

.cosMr100 {
  margin-right: 100px !important;
}

.cosMr110 {
  margin-right: 110px !important;
}

.cosMr120 {
  margin-right: 120px !important;
}

.cosMr130 {
  margin-right: 130px !important;
}

.cosMr140 {
  margin-right: 140px !important;
}

.cosMr150 {
  margin-right: 150px !important;
}

.cosMr160 {
  margin-right: 160px !important;
}

.cosMr170 {
  margin-right: 170px !important;
}

.cosMr180 {
  margin-right: 180px !important;
}

.cosMr190 {
  margin-right: 190px !important;
}

.cosMr200 {
  margin-right: 200px !important;
}

.cosMr210 {
  margin-right: 210px !important;
}

.cosMr220 {
  margin-right: 220px !important;
}

.cosMr230 {
  margin-right: 230px !important;
}

.cosMr240 {
  margin-right: 240px !important;
}

.cosMr250 {
  margin-right: 250px !important;
}

.cosMr260 {
  margin-right: 260px !important;
}

.cosMr270 {
  margin-right: 270px !important;
}

.cosMr280 {
  margin-right: 280px !important;
}

.cosMr290 {
  margin-right: 290px !important;
}

.cosMr300 {
  margin-right: 300px !important;
}

.cosMb0 {
  margin-bottom: 0px !important;
}

.cosMb5 {
  margin-bottom: 5px !important;
}

.cosMb10 {
  margin-bottom: 10px !important;
}

.cosMb15 {
  margin-bottom: 15px !important;
}

.cosMb20 {
  margin-bottom: 20px !important;
}

.cosMb30 {
  margin-bottom: 30px !important;
}

.cosMb40 {
  margin-bottom: 40px !important;
}

.cosMb50 {
  margin-bottom: 50px !important;
}

.cosMb60 {
  margin-bottom: 60px !important;
}

.cosMb70 {
  margin-bottom: 70px !important;
}

.cosMb80 {
  margin-bottom: 80px !important;
}

.cosMb90 {
  margin-bottom: 90px !important;
}

.cosMb100 {
  margin-bottom: 100px !important;
}

.cosMb110 {
  margin-bottom: 110px !important;
}

.cosMb120 {
  margin-bottom: 120px !important;
}

.cosMb130 {
  margin-bottom: 130px !important;
}

.cosMb140 {
  margin-bottom: 140px !important;
}

.cosMb150 {
  margin-bottom: 150px !important;
}

.cosMb160 {
  margin-bottom: 160px !important;
}

.cosMb170 {
  margin-bottom: 170px !important;
}

.cosMb180 {
  margin-bottom: 180px !important;
}

.cosMb190 {
  margin-bottom: 190px !important;
}

.cosMb200 {
  margin-bottom: 200px !important;
}

.cosMb210 {
  margin-bottom: 210px !important;
}

.cosMb220 {
  margin-bottom: 220px !important;
}

.cosMb230 {
  margin-bottom: 230px !important;
}

.cosMb240 {
  margin-bottom: 240px !important;
}

.cosMb250 {
  margin-bottom: 250px !important;
}

.cosMb260 {
  margin-bottom: 260px !important;
}

.cosMb270 {
  margin-bottom: 270px !important;
}

.cosMb280 {
  margin-bottom: 280px !important;
}

.cosMb290 {
  margin-bottom: 290px !important;
}

.cosMb300 {
  margin-bottom: 300px !important;
}

.cosPl0 {
  padding-left: 0px !important;
}

.cosPl5 {
  padding-left: 5px !important;
}

.cosPl10 {
  padding-left: 10px !important;
}

.cosPl20 {
  padding-left: 20px !important;
}

.cosPl30 {
  padding-left: 30px !important;
}

.cosPl40 {
  padding-left: 40px !important;
}

.cosPl50 {
  padding-left: 50px !important;
}

.cosPl60 {
  padding-left: 60px !important;
}

.cosPl70 {
  padding-left: 70px !important;
}

.cosPl80 {
  padding-left: 80px !important;
}

.cosPl90 {
  padding-left: 90px !important;
}

.cosPl100 {
  padding-left: 100px !important;
}

.cosPl110 {
  padding-left: 110px !important;
}

.cosPl120 {
  padding-left: 120px !important;
}

.cosPl130 {
  padding-left: 130px !important;
}

.cosPl140 {
  padding-left: 140px !important;
}

.cosPl150 {
  padding-left: 150px !important;
}

.cosPl160 {
  padding-left: 160px !important;
}

.cosPl170 {
  padding-left: 170px !important;
}

.cosPl180 {
  padding-left: 180px !important;
}

.cosPl190 {
  padding-left: 190px !important;
}

.cosPl200 {
  padding-left: 200px !important;
}

.cosPl210 {
  padding-left: 210px !important;
}

.cosPl220 {
  padding-left: 220px !important;
}

.cosPl230 {
  padding-left: 230px !important;
}

.cosPl240 {
  padding-left: 240px !important;
}

.cosPl250 {
  padding-left: 250px !important;
}

.cosPl260 {
  padding-left: 260px !important;
}

.cosPl270 {
  padding-left: 270px !important;
}

.cosPl280 {
  padding-left: 280px !important;
}

.cosPl290 {
  padding-left: 290px !important;
}

.cosPl300 {
  padding-left: 300px !important;
}

.cosPt0 {
  padding-top: 0px !important;
}

.cosPt5 {
  padding-top: 5px !important;
}

.cosPt10 {
  padding-top: 10px !important;
}

.cosPt20 {
  padding-top: 20px !important;
}

.cosPt30 {
  padding-top: 30px !important;
}

.cosPt40 {
  padding-top: 40px !important;
}

.cosPt50 {
  padding-top: 50px !important;
}

.cosPt60 {
  padding-top: 60px !important;
}

.cosPt70 {
  padding-top: 70px !important;
}

.cosPt80 {
  padding-top: 80px !important;
}

.cosPt90 {
  padding-top: 90px !important;
}

.cosPt100 {
  padding-top: 100px !important;
}

.cosPt110 {
  padding-top: 110px !important;
}

.cosPt120 {
  padding-top: 120px !important;
}

.cosPt130 {
  padding-top: 130px !important;
}

.cosPt140 {
  padding-top: 140px !important;
}

.cosPt150 {
  padding-top: 150px !important;
}

.cosPt160 {
  padding-top: 160px !important;
}

.cosPt170 {
  padding-top: 170px !important;
}

.cosPt180 {
  padding-top: 180px !important;
}

.cosPt190 {
  padding-top: 190px !important;
}

.cosPt200 {
  padding-top: 200px !important;
}

.cosPt210 {
  padding-top: 210px !important;
}

.cosPt220 {
  padding-top: 220px !important;
}

.cosPt230 {
  padding-top: 230px !important;
}

.cosPt240 {
  padding-top: 240px !important;
}

.cosPt250 {
  padding-top: 250px !important;
}

.cosPt260 {
  padding-top: 260px !important;
}

.cosPt270 {
  padding-top: 270px !important;
}

.cosPt280 {
  padding-top: 280px !important;
}

.cosPt290 {
  padding-top: 290px !important;
}

.cosPt300 {
  padding-top: 300px !important;
}

.cosPr0 {
  padding-right: 0px !important;
}

.cosPr5 {
  padding-right: 5px !important;
}

.cosPr10 {
  padding-right: 10px !important;
}

.cosPr20 {
  padding-right: 20px !important;
}

.cosPr30 {
  padding-right: 30px !important;
}

.cosPr40 {
  padding-right: 40px !important;
}

.cosPr50 {
  padding-right: 50px !important;
}

.cosPr60 {
  padding-right: 60px !important;
}

.cosPr70 {
  padding-right: 70px !important;
}

.cosPr80 {
  padding-right: 80px !important;
}

.cosPr90 {
  padding-right: 90px !important;
}

.cosPr100 {
  padding-right: 100px !important;
}

.cosPr110 {
  padding-right: 110px !important;
}

.cosPr120 {
  padding-right: 120px !important;
}

.cosPr130 {
  padding-right: 130px !important;
}

.cosPr140 {
  padding-right: 140px !important;
}

.cosPr150 {
  padding-right: 150px !important;
}

.cosPr160 {
  padding-right: 160px !important;
}

.cosPr170 {
  padding-right: 170px !important;
}

.cosPr180 {
  padding-right: 180px !important;
}

.cosPr190 {
  padding-right: 190px !important;
}

.cosPr200 {
  padding-right: 200px !important;
}

.cosPr210 {
  padding-right: 210px !important;
}

.cosPr220 {
  padding-right: 220px !important;
}

.cosPr230 {
  padding-right: 230px !important;
}

.cosPr240 {
  padding-right: 240px !important;
}

.cosPr250 {
  padding-right: 250px !important;
}

.cosPr260 {
  padding-right: 260px !important;
}

.cosPr270 {
  padding-right: 270px !important;
}

.cosPr280 {
  padding-right: 280px !important;
}

.cosPr290 {
  padding-right: 290px !important;
}

.cosPr300 {
  padding-right: 300px !important;
}

.cosPb0 {
  padding-bottom: 0px !important;
}

.cosPb5 {
  padding-bottom: 5px !important;
}

.cosPb10 {
  padding-bottom: 10px !important;
}

.cosPb20 {
  padding-bottom: 20px !important;
}

.cosPb30 {
  padding-bottom: 30px !important;
}

.cosPb40 {
  padding-bottom: 40px !important;
}

.cosPb50 {
  padding-bottom: 50px !important;
}

.cosPb60 {
  padding-bottom: 60px !important;
}

.cosPb70 {
  padding-bottom: 70px !important;
}

.cosPb80 {
  padding-bottom: 80px !important;
}

.cosPb90 {
  padding-bottom: 90px !important;
}

.cosPb100 {
  padding-bottom: 100px !important;
}

.cosPb110 {
  padding-bottom: 110px !important;
}

.cosPb120 {
  padding-bottom: 120px !important;
}

.cosPb130 {
  padding-bottom: 130px !important;
}

.cosPb140 {
  padding-bottom: 140px !important;
}

.cosPb150 {
  padding-bottom: 150px !important;
}

.cosPb160 {
  padding-bottom: 160px !important;
}

.cosPb170 {
  padding-bottom: 170px !important;
}

.cosPb180 {
  padding-bottom: 180px !important;
}

.cosPb190 {
  padding-bottom: 190px !important;
}

.cosPb200 {
  padding-bottom: 200px !important;
}

.cosPb210 {
  padding-bottom: 210px !important;
}

.cosPb220 {
  padding-bottom: 220px !important;
}

.cosPb230 {
  padding-bottom: 230px !important;
}

.cosPb240 {
  padding-bottom: 240px !important;
}

.cosPb250 {
  padding-bottom: 250px !important;
}

.cosPb260 {
  padding-bottom: 260px !important;
}

.cosPb270 {
  padding-bottom: 270px !important;
}

.cosPb280 {
  padding-bottom: 280px !important;
}

.cosPb290 {
  padding-bottom: 290px !important;
}

.cosPb300 {
  padding-bottom: 300px !important;
}

@media only screen and (max-width: 768px) {
  .cosRMl0 {
    margin-left: 0px !important;
  }
  .cosRMl5 {
    margin-left: 5px !important;
  }
  .cosRMl10 {
    margin-left: 10px !important;
  }
  .cosRMl20 {
    margin-left: 20px !important;
  }
  .cosRMl30 {
    margin-left: 30px !important;
  }
  .cosRMl40 {
    margin-left: 40px !important;
  }
  .cosRMl50 {
    margin-left: 50px !important;
  }
  .cosRMl60 {
    margin-left: 60px !important;
  }
  .cosRMl70 {
    margin-left: 70px !important;
  }
  .cosRMl80 {
    margin-left: 80px !important;
  }
  .cosRMl90 {
    margin-left: 90px !important;
  }
  .cosRMl100 {
    margin-left: 100px !important;
  }
  .cosRMl110 {
    margin-left: 110px !important;
  }
  .cosRMl120 {
    margin-left: 120px !important;
  }
  .cosRMl130 {
    margin-left: 130px !important;
  }
  .cosRMl140 {
    margin-left: 140px !important;
  }
  .cosRMl150 {
    margin-left: 150px !important;
  }
  .cosRMl160 {
    margin-left: 160px !important;
  }
  .cosRMl170 {
    margin-left: 170px !important;
  }
  .cosRMl180 {
    margin-left: 180px !important;
  }
  .cosRMl190 {
    margin-left: 190px !important;
  }
  .cosRMl200 {
    margin-left: 200px !important;
  }
  .cosRMl210 {
    margin-left: 210px !important;
  }
  .cosRMl220 {
    margin-left: 220px !important;
  }
  .cosRMl230 {
    margin-left: 230px !important;
  }
  .cosRMl240 {
    margin-left: 240px !important;
  }
  .cosRMl250 {
    margin-left: 250px !important;
  }
  .cosRMl260 {
    margin-left: 260px !important;
  }
  .cosRMl270 {
    margin-left: 270px !important;
  }
  .cosRMl280 {
    margin-left: 280px !important;
  }
  .cosRMl290 {
    margin-left: 290px !important;
  }
  .cosRMl300 {
    margin-left: 300px !important;
  }
  .cosRMt0 {
    margin-top: 0px !important;
  }
  .cosRMt5 {
    margin-top: 5px !important;
  }
  .cosRMt10 {
    margin-top: 10px !important;
  }
  .cosRMt15 {
    margin-top: 15px !important;
  }
  .cosRMt20 {
    margin-top: 20px !important;
  }
  .cosRMt30 {
    margin-top: 30px !important;
  }
  .cosRMt40 {
    margin-top: 40px !important;
  }
  .cosRMt50 {
    margin-top: 50px !important;
  }
  .cosRMt60 {
    margin-top: 60px !important;
  }
  .cosRMt70 {
    margin-top: 70px !important;
  }
  .cosRMt80 {
    margin-top: 80px !important;
  }
  .cosRMt90 {
    margin-top: 90px !important;
  }
  .cosRMt100 {
    margin-top: 100px !important;
  }
  .cosRMt110 {
    margin-top: 110px !important;
  }
  .cosRMt120 {
    margin-top: 120px !important;
  }
  .cosRMt130 {
    margin-top: 130px !important;
  }
  .cosRMt140 {
    margin-top: 140px !important;
  }
  .cosRMt150 {
    margin-top: 150px !important;
  }
  .cosRMt160 {
    margin-top: 160px !important;
  }
  .cosRMt170 {
    margin-top: 170px !important;
  }
  .cosRMt180 {
    margin-top: 180px !important;
  }
  .cosRMt190 {
    margin-top: 190px !important;
  }
  .cosRMt200 {
    margin-top: 200px !important;
  }
  .cosRMt210 {
    margin-top: 210px !important;
  }
  .cosRMt220 {
    margin-top: 220px !important;
  }
  .cosRMt230 {
    margin-top: 230px !important;
  }
  .cosRMt240 {
    margin-top: 240px !important;
  }
  .cosRMt250 {
    margin-top: 250px !important;
  }
  .cosRMt260 {
    margin-top: 260px !important;
  }
  .cosRMt270 {
    margin-top: 270px !important;
  }
  .cosRMt280 {
    margin-top: 280px !important;
  }
  .cosRMt290 {
    margin-top: 290px !important;
  }
  .cosRMt300 {
    margin-top: 300px !important;
  }
  .cosRMr0 {
    margin-right: 0px !important;
  }
  .cosRMr5 {
    margin-right: 5px !important;
  }
  .cosRMr10 {
    margin-right: 10px !important;
  }
  .cosRMr20 {
    margin-right: 20px !important;
  }
  .cosRMr30 {
    margin-right: 30px !important;
  }
  .cosRMr40 {
    margin-right: 40px !important;
  }
  .cosRMr50 {
    margin-right: 50px !important;
  }
  .cosRMr60 {
    margin-right: 60px !important;
  }
  .cosRMr70 {
    margin-right: 70px !important;
  }
  .cosRMr80 {
    margin-right: 80px !important;
  }
  .cosRMr90 {
    margin-right: 90px !important;
  }
  .cosRMr100 {
    margin-right: 100px !important;
  }
  .cosRMr110 {
    margin-right: 110px !important;
  }
  .cosRMr120 {
    margin-right: 120px !important;
  }
  .cosRMr130 {
    margin-right: 130px !important;
  }
  .cosRMr140 {
    margin-right: 140px !important;
  }
  .cosRMr150 {
    margin-right: 150px !important;
  }
  .cosRMr160 {
    margin-right: 160px !important;
  }
  .cosRMr170 {
    margin-right: 170px !important;
  }
  .cosRMr180 {
    margin-right: 180px !important;
  }
  .cosRMr190 {
    margin-right: 190px !important;
  }
  .cosRMr200 {
    margin-right: 200px !important;
  }
  .cosRMr210 {
    margin-right: 210px !important;
  }
  .cosRMr220 {
    margin-right: 220px !important;
  }
  .cosRMr230 {
    margin-right: 230px !important;
  }
  .cosRMr240 {
    margin-right: 240px !important;
  }
  .cosRMr250 {
    margin-right: 250px !important;
  }
  .cosRMr260 {
    margin-right: 260px !important;
  }
  .cosRMr270 {
    margin-right: 270px !important;
  }
  .cosRMr280 {
    margin-right: 280px !important;
  }
  .cosRMr290 {
    margin-right: 290px !important;
  }
  .cosRMr300 {
    margin-right: 300px !important;
  }
  .cosRMb0 {
    margin-bottom: 0px !important;
  }
  .cosRMb5 {
    margin-bottom: 5px !important;
  }
  .cosRMb10 {
    margin-bottom: 10px !important;
  }
  .cosRMb15 {
    margin-bottom: 15px !important;
  }
  .cosRMb20 {
    margin-bottom: 20px !important;
  }
  .cosRMb30 {
    margin-bottom: 30px !important;
  }
  .cosRMb40 {
    margin-bottom: 40px !important;
  }
  .cosRMb50 {
    margin-bottom: 50px !important;
  }
  .cosRMb60 {
    margin-bottom: 60px !important;
  }
  .cosRMb70 {
    margin-bottom: 70px !important;
  }
  .cosRMb80 {
    margin-bottom: 80px !important;
  }
  .cosRMb90 {
    margin-bottom: 90px !important;
  }
  .cosRMb100 {
    margin-bottom: 100px !important;
  }
  .cosRMb110 {
    margin-bottom: 110px !important;
  }
  .cosRMb120 {
    margin-bottom: 120px !important;
  }
  .cosRMb130 {
    margin-bottom: 130px !important;
  }
  .cosRMb140 {
    margin-bottom: 140px !important;
  }
  .cosRMb150 {
    margin-bottom: 150px !important;
  }
  .cosRMb160 {
    margin-bottom: 160px !important;
  }
  .cosRMb170 {
    margin-bottom: 170px !important;
  }
  .cosRMb180 {
    margin-bottom: 180px !important;
  }
  .cosRMb190 {
    margin-bottom: 190px !important;
  }
  .cosRMb200 {
    margin-bottom: 200px !important;
  }
  .cosRMb210 {
    margin-bottom: 210px !important;
  }
  .cosRMb220 {
    margin-bottom: 220px !important;
  }
  .cosRMb230 {
    margin-bottom: 230px !important;
  }
  .cosRMb240 {
    margin-bottom: 240px !important;
  }
  .cosRMb250 {
    margin-bottom: 250px !important;
  }
  .cosRMb260 {
    margin-bottom: 260px !important;
  }
  .cosRMb270 {
    margin-bottom: 270px !important;
  }
  .cosRMb280 {
    margin-bottom: 280px !important;
  }
  .cosRMb290 {
    margin-bottom: 290px !important;
  }
  .cosRMb300 {
    margin-bottom: 300px !important;
  }
  .cosRPl0 {
    padding-left: 0px !important;
  }
  .cosRPl5 {
    padding-left: 5px !important;
  }
  .cosRPl10 {
    padding-left: 10px !important;
  }
  .cosRPl20 {
    padding-left: 20px !important;
  }
  .cosRPl30 {
    padding-left: 30px !important;
  }
  .cosRPl40 {
    padding-left: 40px !important;
  }
  .cosRPl50 {
    padding-left: 50px !important;
  }
  .cosRPl60 {
    padding-left: 60px !important;
  }
  .cosRPl70 {
    padding-left: 70px !important;
  }
  .cosRPl80 {
    padding-left: 80px !important;
  }
  .cosRPl90 {
    padding-left: 90px !important;
  }
  .cosRPl100 {
    padding-left: 100px !important;
  }
  .cosRPl110 {
    padding-left: 110px !important;
  }
  .cosRPl120 {
    padding-left: 120px !important;
  }
  .cosRPl130 {
    padding-left: 130px !important;
  }
  .cosRPl140 {
    padding-left: 140px !important;
  }
  .cosRPl150 {
    padding-left: 150px !important;
  }
  .cosRPl160 {
    padding-left: 160px !important;
  }
  .cosRPl170 {
    padding-left: 170px !important;
  }
  .cosRPl180 {
    padding-left: 180px !important;
  }
  .cosRPl190 {
    padding-left: 190px !important;
  }
  .cosRPl200 {
    padding-left: 200px !important;
  }
  .cosRPl210 {
    padding-left: 210px !important;
  }
  .cosRPl220 {
    padding-left: 220px !important;
  }
  .cosRPl230 {
    padding-left: 230px !important;
  }
  .cosRPl240 {
    padding-left: 240px !important;
  }
  .cosRPl250 {
    padding-left: 250px !important;
  }
  .cosRPl260 {
    padding-left: 260px !important;
  }
  .cosRPl270 {
    padding-left: 270px !important;
  }
  .cosRPl280 {
    padding-left: 280px !important;
  }
  .cosRPl290 {
    padding-left: 290px !important;
  }
  .cosRPl300 {
    padding-left: 300px !important;
  }
  .cosRPt0 {
    padding-top: 0px !important;
  }
  .cosRPt5 {
    padding-top: 5px !important;
  }
  .cosRPt10 {
    padding-top: 10px !important;
  }
  .cosRPt20 {
    padding-top: 20px !important;
  }
  .cosRPt30 {
    padding-top: 30px !important;
  }
  .cosRPt40 {
    padding-top: 40px !important;
  }
  .cosRPt50 {
    padding-top: 50px !important;
  }
  .cosRPt60 {
    padding-top: 60px !important;
  }
  .cosRPt70 {
    padding-top: 70px !important;
  }
  .cosRPt80 {
    padding-top: 80px !important;
  }
  .cosRPt90 {
    padding-top: 90px !important;
  }
  .cosRPt100 {
    padding-top: 100px !important;
  }
  .cosRPt110 {
    padding-top: 110px !important;
  }
  .cosRPt120 {
    padding-top: 120px !important;
  }
  .cosRPt130 {
    padding-top: 130px !important;
  }
  .cosRPt140 {
    padding-top: 140px !important;
  }
  .cosRPt150 {
    padding-top: 150px !important;
  }
  .cosRPt160 {
    padding-top: 160px !important;
  }
  .cosRPt170 {
    padding-top: 170px !important;
  }
  .cosRPt180 {
    padding-top: 180px !important;
  }
  .cosRPt190 {
    padding-top: 190px !important;
  }
  .cosRPt200 {
    padding-top: 200px !important;
  }
  .cosRPt210 {
    padding-top: 210px !important;
  }
  .cosRPt220 {
    padding-top: 220px !important;
  }
  .cosRPt230 {
    padding-top: 230px !important;
  }
  .cosRPt240 {
    padding-top: 240px !important;
  }
  .cosRPt250 {
    padding-top: 250px !important;
  }
  .cosRPt260 {
    padding-top: 260px !important;
  }
  .cosRPt270 {
    padding-top: 270px !important;
  }
  .cosRPt280 {
    padding-top: 280px !important;
  }
  .cosRPt290 {
    padding-top: 290px !important;
  }
  .cosRPt300 {
    padding-top: 300px !important;
  }
  .cosRPr0 {
    padding-right: 0px !important;
  }
  .cosRPr5 {
    padding-right: 5px !important;
  }
  .cosRPr10 {
    padding-right: 10px !important;
  }
  .cosRPr20 {
    padding-right: 20px !important;
  }
  .cosRPr30 {
    padding-right: 30px !important;
  }
  .cosRPr40 {
    padding-right: 40px !important;
  }
  .cosRPr50 {
    padding-right: 50px !important;
  }
  .cosRPr60 {
    padding-right: 60px !important;
  }
  .cosRPr70 {
    padding-right: 70px !important;
  }
  .cosRPr80 {
    padding-right: 80px !important;
  }
  .cosRPr90 {
    padding-right: 90px !important;
  }
  .cosRPr100 {
    padding-right: 100px !important;
  }
  .cosRPr110 {
    padding-right: 110px !important;
  }
  .cosRPr120 {
    padding-right: 120px !important;
  }
  .cosRPr130 {
    padding-right: 130px !important;
  }
  .cosRPr140 {
    padding-right: 140px !important;
  }
  .cosRPr150 {
    padding-right: 150px !important;
  }
  .cosRPr160 {
    padding-right: 160px !important;
  }
  .cosRPr170 {
    padding-right: 170px !important;
  }
  .cosRPr180 {
    padding-right: 180px !important;
  }
  .cosRPr190 {
    padding-right: 190px !important;
  }
  .cosRPr200 {
    padding-right: 200px !important;
  }
  .cosRPr210 {
    padding-right: 210px !important;
  }
  .cosRPr220 {
    padding-right: 220px !important;
  }
  .cosRPr230 {
    padding-right: 230px !important;
  }
  .cosRPr240 {
    padding-right: 240px !important;
  }
  .cosRPr250 {
    padding-right: 250px !important;
  }
  .cosRPr260 {
    padding-right: 260px !important;
  }
  .cosRPr270 {
    padding-right: 270px !important;
  }
  .cosRPr280 {
    padding-right: 280px !important;
  }
  .cosRPr290 {
    padding-right: 290px !important;
  }
  .cosRPr300 {
    padding-right: 300px !important;
  }
  .cosRPb0 {
    padding-bottom: 0px !important;
  }
  .cosRPb5 {
    padding-bottom: 5px !important;
  }
  .cosRPb10 {
    padding-bottom: 10px !important;
  }
  .cosRPb20 {
    padding-bottom: 20px !important;
  }
  .cosRPb30 {
    padding-bottom: 30px !important;
  }
  .cosRPb40 {
    padding-bottom: 40px !important;
  }
  .cosRPb50 {
    padding-bottom: 50px !important;
  }
  .cosRPb60 {
    padding-bottom: 60px !important;
  }
  .cosRPb70 {
    padding-bottom: 70px !important;
  }
  .cosRPb80 {
    padding-bottom: 80px !important;
  }
  .cosRPb90 {
    padding-bottom: 90px !important;
  }
  .cosRPb100 {
    padding-bottom: 100px !important;
  }
  .cosRPb110 {
    padding-bottom: 110px !important;
  }
  .cosRPb120 {
    padding-bottom: 120px !important;
  }
  .cosRPb130 {
    padding-bottom: 130px !important;
  }
  .cosRPb140 {
    padding-bottom: 140px !important;
  }
  .cosRPb150 {
    padding-bottom: 150px !important;
  }
  .cosRPb160 {
    padding-bottom: 160px !important;
  }
  .cosRPb170 {
    padding-bottom: 170px !important;
  }
  .cosRPb180 {
    padding-bottom: 180px !important;
  }
  .cosRPb190 {
    padding-bottom: 190px !important;
  }
  .cosRPb200 {
    padding-bottom: 200px !important;
  }
  .cosRPb210 {
    padding-bottom: 210px !important;
  }
  .cosRPb220 {
    padding-bottom: 220px !important;
  }
  .cosRPb230 {
    padding-bottom: 230px !important;
  }
  .cosRPb240 {
    padding-bottom: 240px !important;
  }
  .cosRPb250 {
    padding-bottom: 250px !important;
  }
  .cosRPb260 {
    padding-bottom: 260px !important;
  }
  .cosRPb270 {
    padding-bottom: 270px !important;
  }
  .cosRPb280 {
    padding-bottom: 280px !important;
  }
  .cosRPb290 {
    padding-bottom: 290px !important;
  }
  .cosRPb300 {
    padding-bottom: 300px !important;
  }
}

/* text
------------------------------------------------------------*/
.cosTxtBold {
  font-weight: bold !important;
}

.cosTxtRight {
  text-align: right !important;
}

.cosTxtCenter {
  text-align: center !important;
}

.cosTxtLeft {
  text-align: left !important;
}

.cosTxt10 {
  font-size: 10px !important;
  font-size: 1rem !important;
}

.cosTxt11 {
  font-size: 11px !important;
  font-size: 1.1rem !important;
}

.cosTxt12 {
  font-size: 12px !important;
  font-size: 1.2rem !important;
}

.cosTxt13 {
  font-size: 13px !important;
  font-size: 1.3rem !important;
}

.cosTxt14 {
  font-size: 14px !important;
  font-size: 1.4rem !important;
}

.cosTxt16 {
  font-size: 16px !important;
  font-size: 1.6rem !important;
}

.cosTxt18 {
  font-size: 18px !important;
  font-size: 1.8rem !important;
}

.cosTxt20 {
  font-size: 20px !important;
  font-size: 2rem !important;
}

.cosTxtRed {
  color: #ee3b31;
}

.cosTxtNomalColor {
  color: #665352;
}

/* grid
------------------------------------------------------------*/
.cosGrid {
  width: 100%;
  *zoom: 1;
}

.cosGrid:before,
.cosGrid:after {
  display: table;
  line-height: 0;
  content: "";
}

.cosGrid:after {
  clear: both;
}

.cosGrid [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cosGrid [class*="span"]:first-child {
  margin-left: 0;
}

.cosGrid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.127659574468085%;
}

.cosGrid .span12 {
  width: 100%;
  *width: 99.94680851063829%;
}

.cosGrid .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%;
}

.cosGrid .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%;
}

.cosGrid .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%;
}

.cosGrid .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%;
}

.cosGrid .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%;
}

.cosGrid .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%;
}

.cosGrid .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%;
}

.cosGrid .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%;
}

.cosGrid .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%;
}

.cosGrid .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%;
}

.cosGrid .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%;
}

.cosGrid .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%;
}

.cosGrid .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%;
}

.cosGrid .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%;
}

.cosGrid .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%;
}

.cosGrid .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%;
}

.cosGrid .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%;
}

.cosGrid .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%;
}

.cosGrid .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%;
}

.cosGrid .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%;
}

.cosGrid .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%;
}

.cosGrid .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%;
}

.cosGrid .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%;
}

.cosGrid .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%;
}

.cosGrid .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%;
}

.cosGrid .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%;
}

.cosGrid .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%;
}

.cosGrid .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%;
}

.cosGrid .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%;
}

.cosGrid .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%;
}

.cosGrid .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%;
}

.cosGrid .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%;
}

.cosGrid .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%;
}

.cosGrid .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%;
}

.cosGrid .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%;
}

@media only screen and (max-width: 768px) {
  .cosGrid [class*="span"] {
    display: block;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 768px) {
  .cosGrid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 768px) {
  .cosGrid [class*="offset"]:first-child {
    margin-left: 0;
  }
}

.cosGridXs {
  width: 100%;
  *zoom: 1;
}

.cosGridXs:before,
.cosGridXs:after {
  display: table;
  line-height: 0;
  content: "";
}

.cosGridXs:after {
  clear: both;
}

.cosGridXs [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cosGridXs [class*="span"]:first-child {
  margin-left: 0;
}

.cosGridXs .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.127659574468085%;
}

.cosGridXs .span12 {
  width: 100%;
  *width: 99.94680851063829%;
}

.cosGridXs .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%;
}

.cosGridXs .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%;
}

.cosGridXs .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%;
}

.cosGridXs .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%;
}

.cosGridXs .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%;
}

.cosGridXs .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%;
}

.cosGridXs .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%;
}

.cosGridXs .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%;
}

.cosGridXs .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%;
}

.cosGridXs .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%;
}

.cosGridXs .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%;
}

.cosGridXs .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%;
}

.cosGridXs .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%;
}

.cosGridXs .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%;
}

.cosGridXs .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%;
}

.cosGridXs .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%;
}

.cosGridXs .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%;
}

.cosGridXs .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%;
}

.cosGridXs .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%;
}

.cosGridXs .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%;
}

.cosGridXs .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%;
}

.cosGridXs .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%;
}

.cosGridXs .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%;
}

.cosGridXs .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%;
}

.cosGridXs .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%;
}

.cosGridXs .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%;
}

.cosGridXs .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%;
}

.cosGridXs .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%;
}

.cosGridXs .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%;
}

.cosGridXs .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%;
}

.cosGridXs .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%;
}

.cosGridXs .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%;
}

.cosGridXs .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%;
}

.cosGridXs .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%;
}

.cosGridXs .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%;
}

/* etc
------------------------------------------------------------*/
.cosHide {
  display: none;
}
