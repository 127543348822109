@charset "utf-8";

/* modBtn
------------------------------------------------------------*/
.modBtn01{
    display:block;
    border:none;
    background-color:$mainColor;
    border:none;
    width:100%;
    box-sizing:border-box;
    position:relative;
    border-radius:4px;
    padding:10px 40px;
    color:#fff;
    text-align:center;
    text-decoration:none;
    @include fz(18);
    font-family:$fontMincho;
    
    @include sphoneP{
        @include fz(16);
    }
    
    &:hover{
        background-color:$hoverColor;
    }
    
    &:after{
       content:"";
       background-image:url(../img/arrow01.png);
       background-position:0 0;
       background-repeat:no-repeat;
       background-size:20px auto;
       background-color:#fff;
       border-radius:50%;
       width:20px;
       height:20px;
       position:absolute;
       top:0;
       right:10px;
       bottom:0;
       margin:auto;
    }
    
    &_small{
        @include fz(15);
        @include sphoneP{
            @include fz(13);
        }
    }
    &_large{
        @include fz(20);
        @include sphoneP{
            @include fz(15);
        }
    }
    &_inline{
        @include inline-block;
        width:auto;
    }
    
    &_back{
        background-color:#969696;
        &:after{
        	background-image:url(../img/arrow01L.png);
            left:10px;
            right:auto;
        }
        
        &:hover{
        	background-color:#aaa;
        }
    }
    
    &_arrowB{
       &:after{
            background-image:url(../img/arrow01B.png);
       }
    }
    
    &_dl{
       &:after{
            background-image:url(../img/iconDl.png);
            background-size: 25px auto;
            background-color:transparent;
            width: 25px;
            height: 26px;
            left:20px;
            right: auto;
            border-radius:0;
            @include sphoneP{
                background-size: 20px auto;
                width: 20px;
                height: 21px;
            }
       }
    }
    
    &_green {
      background:#8eb355;

        &:hover{
        	background-color:#a1c46a;
        }

    }

    &_yellow {
      background:#f1af2d;

        &:hover{
        	background-color:#f4c260;
        }

    }

    &_brown {
      background:#BF9250;

        &:hover{
        	background-color:#BF9250;
        }

    }

/* .modBtn01 */}

.modBtn02{
    display:block;
    border:solid 1px $mainColor03;
    border-radius:5px;
    background-color:#fff;
    width:100%;
    padding:8px 40px;
    box-sizing:border-box;
    font-weight:bold;
    @include fz(18);
    color:$mainColor03;
    text-decoration:none;
    text-align:center;
    &:hover{
        background-color:rgba($mainColor03,0.05);
    }
    
    @include sphoneP{
        @include fz(16);
    }
    
    &_inline{
        @include inline-block;
        width:auto;
    }
/* .modBtn02 */}

.modBtn03{
    @extend .modBtn01;
    &:after{
       background-image:url(../img/arrow03.png);
    }
    
    &_small{
        @extend .modBtn01_small;
    }
    &_large{
        @extend .modBtn01_large;
    }
    &_inline{
        @extend .modBtn01_inline;
    }
    &_back{
        @extend .modBtn01_back;
        &:after{
           background-image:url(../img/arrow03L.png);
        }
    }
    
    &_yellow {
        @extend .modBtn01_yellow;
    }
    &_brown {
        @extend .modBtn01_brown;
    }
    
    &_arrowB{
       @extend .modBtn01_arrowB;
       &:after{
            background-image:url(../img/arrow03B.png);
       }
    }
    
    &_dl{
       @extend .modBtn01_dl;
        &:after{
           background-image:url(../img/iconDl.png);
        }
    }

/* .modBtn03 */}


.modBtnRich{
    position:relative;
    margin-bottom: 40px;
    border-radius:10px;
    background-image: url(../img/bgBtn01.png);
    background-size:cover;
    background-position: center;
    box-shadow: rgba(0,0,0,0.1) 0 0 15px;
    text-align:center;
    font-family: $fontMincho;
    cursor: pointer;
    @include sphoneP{
        margin-bottom: 20px;
        border-radius:5px;
        box-shadow: rgba(0,0,0,0.1) 0 0 10px;
    }
    
    &_small{
    }
    
    
    &_type02{
    	background-image: url(../img/bgBtn02.png);
    }
    
    &:hover{
        opacity:0.8;
    }
    
    &:after{
        content:"";
        background-image: url(../img/arrowAcc.png);
        background-position: 0 0;
        background-repeat:no-repeat;
        width: 29px;
        height:(58/2)+px;
        position:absolute;
        top:0;
        bottom:0;
        right:30px;
        margin:auto;
        @include sphoneP{
            right:10px;
            background-size:15px auto;
            width: 15px;
            height:(30/2)+px;
        }
        
    }
    
    &.on{
        &:after{
            background-position: 0 (-(58/2)+px);
            @include sphoneP{
            	background-position: 0 (-(30/2)+px);            
            }
        }
    }
    
    .main,.sub{
        position:relative;
    }
    
    .main{
        line-height:1;
        color:$mainColor03;
        @include fz(32);
        @include sphoneP{
              @include fz(18);
        }
    }
    
    &_type02{
        .main{
            color:#5ca895;
        }
    }
    
    .sub{
        margin-top: 10px;
        color:$subColor02;
        @include fz(16);
        @include sphoneP{
            @include fz(13);
        }
    }
    
    .inner{
    	padding:50px 0 ;
        position: relative;
        overflow: hidden;
        @include sphoneP{
        	padding:20px 40px;
        }
        
        &:before,&:after{
            content:"";
            position:absolute;
            background-position: 0 0;
            background-repeat: no-repeat;
            @include tabletP{
                display:none;
            }
        }
        
        &:before{
            background-image: url(../img/decoBtn01_01.png);
            width: 290px;
            height:102px;
            left:0;
            bottom:10px;
        }
        
        &:after{
            background-image: url(../img/decoBtn01_02.png);
            width: 162px;
            height:87px;
            right:60px;
            top:20px;
        }    
    }
    
    &_type02{
        .inner{
            &:before{
            	background-image: url(../img/decoBtn02_01.png);
                width: 201px;
                height:104px;
                bottom:auto;
                top:16px;
                left:16px;
            }
            &:after{
            	background-image: url(../img/decoBtn02_02.png);
                width: 258px;
                height:138px;
                top:0;
                right:0;
            }
        }
    }
    
    
    &_small{
        .inner{
            padding:30px 0;
            @include sphoneP{
                padding:20px 40px;
            }
            
            
            &:before{
                left:-100px;
            }
            
            &:after{
                right:-40px;
                top:0;
            }


        }
        &.modBtnRich_type02{
            .inner{
                
                
                &:before{
                    top:-10px;
                    left:16px;
                }
                
                &:after{
                }
    
    
            }
        }
    }
    
    
    
/* .modBtnRich */}


.modBtnList{
    li{
        max-width:402px;
        margin:0 auto;
    }
    
	&_column02{
		position:relative;
		max-width:890px;
		margin:0 auto;
		@include tabletP{
			max-width:none;
		}
		
		li{
			width:49%;
			max-width:none;
			margin-left:0;
			margin-right:0;
			
			@include tabletP{
				width:auto;
			}
		}
		.right{
			position:absolute;
			top:0;
			right:0;
			@include tabletP{
				position:static;
				margin-bottom:10px;
			}
		}
	}
/* .modBtnList */}