@charset "utf-8";

/* modList
------------------------------------------------------------*/

.modListNormal {
	margin: 0 0 0 1.5em;
	&.inline {
		li {
			display:inline-block;
			margin-right:30px;
			@include sphoneP{
				display:block;
			}
		}
	}
	&_type2 {
		margin:0 0 20px;
		background-color:#fff;
		padding:25px 50px;
		@include clearfix;
		@include sphoneP{
			padding:25px 30px;
		}
		
		li {
			width:45%;
			float:left;
			@include sphoneP{
			width:auto;
			float:none;
			}
		}
	}
}

ul.modListNormal li{
	&:before { content: "\0030fb"; margin-left:-1.5em; @include inline-block; width:1.5em; text-align:center;}
}
ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

.modList01 {
	@include fz(18);
	border:1px solid #f17083;
	border-radius:10px;
	background-color:#fff;
	margin-bottom:40px;
		@include sphoneP{
			@include fz(16);
		}

	
	&>li {
		padding:30px;
		border-bottom:1px dotted #b1afaa;
		@include sphoneP{
			padding:20px;
		}
		&:last-child {
			border-bottom:none;
			border-radius:10px;
		}
		
		dt {
			@include fz(22);
			color:#ef7f87;
			font-weight:bold;
			margin-bottom:15px;
			@include sphoneP{
				@include fz(18);
				margin-bottom:5px;
			}
		}
	}
	
	&_type2 {
		&>li {
			&:nth-child(even) {
				background-color:#fdfaf3;
			}
		}
	}
    
    &_type3 {
        &>li{
            @include sphoneP{
                padding: 15px;
            }
            
            dl{
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            
            dt,dd{
                display: table-cell;
                vertical-align: middle;
            }
            
            dt{
                width: (110-35)+px;
                padding: 0 35px 0 0;
                text-align: center;
            }
                        
        }    
    }
    
/* .modList01 */}

.modListFlow{
    overflow:hidden;
    margin:0 0 50px;
    @include sphoneP{
        margin-bottom: 20px;
    }
    
    ul{
        @include clearfix;
        margin: 0 -1.5%;
        @include sphoneP{
            margin:0;
        }
    }
    
    li{
        float:left;
        width: 22%;
        margin:0 1.5%;
        background-color: #fff;
        border: 1px solid $mainColor03;
        border-radius:10px ;
        box-sizing:border-box;
        position:relative;
        @include sphoneP{
            float: none;
            width: auto;
            margin:0 0 30px;
        }
    }
    
    .body{
        padding:30px 12px;
        @include sphoneP{
            padding:10px;
        }
        
        &:after{
            content:"";
            background-image:url(../img/arrowFlow.png);
            background-position: 0 0;
            background-repeat: no-repeat;
            width: 32px;
            height:43px;
            position:absolute;
            top:112px;
            right:-32px;
            margin:auto;
            @include sphoneP{
                  background-image:url(../img/arrowFlowB.png);
                top:auto;
                bottom:-32px;
                right:0;
                left:0;
                width: 43px;
                height:32px;            
            }
        }        
    }
    
    li{
        &:last-child{
            .body{
                &:after{
                    display:none;
                }
            }
        }
    }
    
    .inner{
        padding:0 8px;
        color:#797979;
        @include fz(17);
        @include tabletP{
            @include fz(14);
        }
        
        .txtTicket{
            margin:0 0 10px;
        }
        
    }
    
    .titleFlow{
        background-image: url(../img/taiyou/line03.png);
        background-position: center bottom;
        background-repeat: repeat-x ;
        margin:0 0 24px;
        padding:0 0 20px;
        height:50px;
        line-height:60px;
        text-align:center;
        color:$mainColor03;
        @include fz(21);
        font-family: $fontMincho;
        letter-spacing:0.15em;
        @include sphoneP{
            margin:0 0 10px;
            padding:0 0 10px;
            height:30px;
            line-height:30px;
            @include fz(16);
        }
        
        span{
            margin: 0 0 0 6px;
            @include fz(42);
            vertical-align:middle;
            @include sphoneP{
                @include fz(26);
                vertical-align: top;                
            }
        }
    }
    
    .subTitle{
        text-align: center;
        color:$mainColor03;
        font-weight: bold;
        @include fz(18);
        @include sphoneP{
            @include fz(14);
        }
    }
    
    .txt{
        @include fz(14);
        @include sphoneP{
            @include fz(12);
        }
    }
      
    
/* .modListFlow */}

.modDl01 {
	@include fz(18);
	border:1px solid #f17083;
	border-radius:10px;
	background-color:#fff;
	overflow:hidden;
	dt {
		width:25%;
		float:left;
		color:#f4919f;
		font-weight:bold;
		padding:25px 25px 0 25px;
		box-sizing:border-box;
		@include sphoneP{
			@include fz(16);
			width:auto;
			float:none;
			margin-bottom:10px;
		}
	}
	dd {
		padding:25px 25px 25px 25%;
		border-bottom:1px dotted #b2b2b2;
		&:last-child{
			border-bottom:none;
		}
		@include sphoneP{
			@include fz(16);
			padding:0 25px 25px 25px;
		}
	}
	&_type2 {
		dt {
			width:35%;
			@include sphoneP{
				@include fz(16);
				width:auto;
				float:none;
				margin-bottom:10px;
			}
		}
		dd {
			padding:25px 25px 25px 35%;
			&:nth-child(4n){
				background-color:#fcfaf3;
			}
			@include sphoneP{
				@include fz(16);
				padding:0 25px 25px 25px;
			}
		}
	}
/* .modDl01 */}

.modDl02 {
	@include fz(18);
	border-top:1px dotted #bab4b3;
	border-bottom:1px dotted #bab4b3;
	padding:10px 0;
	margin-bottom:40px;
	dt {
		width:15%;
		float:left;
		color:#fff;
		padding:15px 0;
		text-align:center;
		background-color:$mainColor;
		border-radius:10px;
		@include sphoneP{
			@include fz(16);
			width:auto;
			float:none;
			margin-bottom:10px;
		}
	}
	dd {
		padding: 10px 0 10px 17%;
    	line-height: 1.4;
		@include sphoneP{
			@include fz(16);
			padding:20px;
		}
	}
/* .modDl02 */}

.modDl03 {
  background:#fff;
  border:1px solid $mainColor03;
  border-radius:5px;
  padding:25px 20px;
  margin-bottom:50px;

  @include sphoneP{
    padding:20px;
    margin-bottom:30px;
  }

  dt {
    float:left;
    color:$mainColor03;
    @include fz(20);
    font-weight:bold;
    background:url(../img/line_single.png) repeat-y top right;
    padding-right:30px;
    margin-right:25px;
    
    @include sphoneP{
      float:none;
      background:url(../img/line.png) repeat-x bottom left;
      padding:0 0 10px 0;
      margin:0 0 10px 0;
      text-align:center;
      @include fz(16);
    }
  }
  dd {
    padding-top:5px;
    margin-bottom:10px;
    @include clearfix;
    
    &:last-child {
      margin-bottom:0;
    }
  }
/* .modDl03 */}


.modImgList01 {
	background-color:#fff;
	padding:25px 80px 20px;
	@include clearfix;
	width:500px;
	margin: 0 auto 40px;
	@include sphoneP{
		width:auto;
	}
	
	li {
		float:left;
		margin-right:30px;
		@include sphoneP{
			float:none;
			margin-right:0;
			margin-bottom:10px;
		}
		&:last-child {
			margin-right:0;
			margin-bottom:0;
		}
		img { margin-bottom:10px; }
		.caption { text-align:center;}
	}
	
/* .modImgList01 */}


.modAnchorLink {
	font-family:$fontMincho;
	@include fz(18);
	overflow:hidden;
	margin-bottom:40px;
	@include sphoneP{
		@include fz(13);
	}
	li {
		width:20%;
		float:left;
		background:url(../img/line03.png) left top repeat-y;
		padding:0 5px 0 8px;
		margin-bottom:12px;
		box-sizing:border-box;
		position:relative;
		@include sphoneP{
			width:50%;
			margin-bottom:8px;
			&:nth-child(even) {
				background:url(../img/line03.png) left top repeat-y,url(../img/line03.png) right top repeat-y;
				padding-right:8px;
			}
		}
		&:nth-child(5n) {
			background:url(../img/line03.png) left top repeat-y,url(../img/line03.png) right top repeat-y;
			padding-right:8px;
			@include sphoneP{
				background:url(../img/line03.png) left top repeat-y;
				padding-left:5px;
			}
		}
		&:last-child {
			&:after {
				content:"";
				background-image:url(../img/line03.png);
				background-position: right top;
				background-repeat: repeat-y;
				width: 4px;
				height: 60px;
				position: absolute;
				right: -4px;
				top: 0;
			}
		}
		a {
			display:block;
			height: 60px;
    		box-sizing: border-box;
			padding:14px 0;
			color:$subColor02;
			text-decoration:none;
			background-color:rgba(255,255,255,1);
			border-radius:8px;
			text-align:center;
			@include sphoneP{
				padding:10px 0;
				height: 40px;
			}
			&:hover {
				background-color:rgba(255,255,255,0.6);
			}
		}
		&.row2 {
			@include fz(16);
			line-height:1.2;
			@include sphoneP{
				@include fz(13);
			}
			a {
				padding:11px 0;
				@include sphoneP{
					padding:6px 0;
				}
			}
		}
		&.txtSmall {
			@include fz(16);
			@include sphoneP{
				@include fz(12);
			}
		}
	}
	&_col4 {
		li {
			width:25%;
			@include sphoneP{
				width:50%;
			}
			&:nth-child(4n) {
			background:url(../img/line03.png) left top repeat-y,url(../img/line03.png) right top repeat-y;
			padding-right:8px;
				@include sphoneP{
					padding-left:5px;
				}
			}
			&:nth-child(5n) {
				background:url(../img/line03.png) left top repeat-y;
				padding-right:5px;
				@include sphoneP{
					padding-left:5px;
					background:url(../img/line03.png) left top repeat-y;
				}
			}
		}
	}
	&_col3 {
		li {
			width:33.333%;
			@include sphoneP{
				width:50%;
			}
			&:nth-child(3n) {
			background:url(../img/line03.png) left top repeat-y,url(../img/line03.png) right top repeat-y;
			padding-right:8px;
				@include sphoneP{
					padding-left:5px;
					background:url(../img/line03.png) left top repeat-y;
				}
			}
			&:nth-child(5n) {
				background:url(../img/line03.png) left top repeat-y;
				padding-right:5px;
				@include sphoneP{
					padding-left:5px;
					background:url(../img/line03.png) left top repeat-y;
				}
			}
			&:nth-child(even) {
				@include sphoneP{
					background:url(../img/line03.png) left top repeat-y,url(../img/line03.png) right top repeat-y;
				}
			}
		}
	}
/* .modAnchorLink */}

.modListPh {
  @include clearfix;
  
  li {
    width:31.531%;
    float:left;
    margin:0 2.7035% 66px 0;
    text-align:center;

    @include sphoneP{
      width:100%;
      float:none;
      margin:0 0 20px 0;
    }
    
    &:nth-child(3n) {
      margin-right:0;
    }
    
    img {
      margin-bottom:17px;
      
      @include sphoneP{
        margin-bottom:10px;
        
      }
    }
    
    span {
      display:block;
    }
    
  }
/*.modListPh  */}

.modListFlower {
  li {
    background:url(../img/iconCloverBlue04.png) no-repeat 0 3px;
    padding-left:40px;
    margin-bottom:20px;
    
    &:nth-child(even) {
      background:url(../img/iconCloverGreen04.png) no-repeat 0 3px;
    }
  }
  &_small {
    
    &_pink {
      li {
        margin-bottom:10px;
        padding-left:30px;
        background:url(../img/iconCloverPink02.png) no-repeat 0 3px;
        color:$subColor02;
        
        &:nth-child(even) {
          background:url(../img/iconCloverPink02.png) no-repeat 0 3px;
        }
        
        &:last-child {
          margin-bottom:0;
        }
        
        strong {
          font-weight:bold;
        }
      }
    }
    
    &_blue {
      li {
        margin-bottom:10px;
        padding-left:30px;
        background:url(../img/iconCloverBlue02.png) no-repeat 0 3px;
        color:$subColor02;
        
        &:nth-child(even) {
          background:url(../img/iconCloverBlue02.png) no-repeat 0 3px;
        }
        
        strong {
          font-weight:bold;
        }
      }
    }
  }

/*.modListFlower*/}

.modListFlower02 {
  padding:20px;
  margin-bottom:30px;
  
  li {
    font-weight:bold;
    padding-left:25px;
    margin-bottom:10px;
    color:$subColor02;
    
    &:last-child {
      margin-bottom:0;
    }
  }
  
  &_blue {
    background:#ecf7fb;

    li {
      background:url(../img/iconCloverBlue01.png) no-repeat 0 3px;
    }
  }
/*.modListFlower02*/}

