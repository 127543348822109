@charset "utf-8";

/* modTxt
------------------------------------------------------------*/

.modTxtLink{
    background-image:url(../img/arrow01.svg);
    background-position:left center;
    background-repeat:no-repeat;
    background-size:20px 20px;
    padding:0 0 3px 28px;
    @include fz(18);
    line-height:1.5;
    @include tabletP{
    	background-position:left 0.2em;
        @include fz(15);
    }
    @include sphoneP{
    	background-position:left 0.2em;
        background-size:15px auto;
        padding-left:20px;
        @include fz(13);
    }
    
    &:hover{
        span{
            color:$subColor;
            border-color:$subColor;
        }
    }
    
    span{
        @include inline-block;
        border-bottom:dotted 1px #000;
    }
/* .modTxtLink */}


.modTxtBlock{
    margin-bottom:40px;
    text-align:center;
    @include fz(18);
    color:$subColor02;
    @include sphoneP{
        margin-bottom:20px;
        @include fz(15);
    }
    
    p{
        margin-bottom:30px;
        @include sphoneP{
            margin-bottom:15px;
        }
    }
    
    span{
        display:block;
        @include sphoneP{
            display:inline;
        }
    }
    
/* .modTxtBlock */}

.modTxtBlock02{
    margin-bottom:40px;
    @include fz(18);
    color:$subColor02;
    @include sphoneP{
        margin-bottom:20px;
        @include fz(15);
    }
    
    p{
        margin-bottom:30px;
        @include sphoneP{
            margin-bottom:15px;
        }
    }
    
    span{
        display:block;
        @include sphoneP{
            display:inline;
        }
    }
    
/* .modTxtBlock02 */}

.modTxtBlock03{
    margin-bottom:40px;
	padding:0 80px;
    @include fz(18);
    color:$subColor02;
    @include sphoneP{
        margin-bottom:20px;
		padding:0;
        @include fz(15);
    }
    p{
        margin-bottom:30px;
        @include sphoneP{
            margin-bottom:15px;
        }
		span{
			display:block;
			@include sphoneP{
				display:inline;
			}
		}
    }
/* .modTxtBlock03 */}

.modTxtCatch{
    margin-bottom: 20px;
    text-align:center;
    color:$mainColor03;
    @include fz(30);
    font-family:$fontMincho;
    
    @include sphoneP{
        @include fz(22);
    }
    
    span{
        display:block;
        @include sphoneP{
            display:inline;
        }
    }
	&_type02 {
		color:#333;
	}
}

.modTxtPink {
  padding:33px;
  background:url(../img/bgMenu01.jpg) repeat top left;
  text-align:center;
  font-weight:bold;
  color:$subColor02;
  @include fz(22);
  
  @include sphoneP{
    @include fz(16);
    padding:15px;
    
  }
  
  .sub {
    @include fz(16);
    font-weight:normal;

    @include sphoneP{
      @include fz(14);
      
    }
  }

}
