@charset "utf-8";

// font
$baseFont: 15;
$baseLineHeight: 1.8;
$baseColor: #333;
$baseLinkColor: #333;
$mainColor: #FF7A80;//ピンク1
$mainColor02:#D6ECE1;//水色
$mainColor03:#ef7f87;//ピンク2
$mainColor04:#1490c8;//青
$subColor:#B98A00;//茶色
$subColor02:#665352;//こげ茶
$subColor03:#ffc9cd;//薄ピンク
$subColor04:#82693e;//カーキ
$hoverColor:#F99BA2;//ピンク系
$hoverColor02:#f5f5f5;//灰色系
$hoverColor03:#FFF5F5;//ピンク系ffc9cd

$newCateColor:#469BC0,#5AC6C5,#E79600,#C5884C,#FF9B90,#8ABD56;

$highlightColor:#ee3b31;//強調テキストの赤色

// font-family
$baseFontFamily : "游ゴシック", YuGothic,"メイリオ",Verdana, "Hiragino Kaku Gothic ProN",Meiryo, sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontMincho : "A1明朝", "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;


// icon webfont
$fa-angle-right : "\f105";
$fa-angle-left : "\f104";
$fa-angle-down:"\f107";
$fa-angle-up:"\f106";
$fa-home : "\f015";
$fa-phone : "\f095";
$fa-file-pdf-o : "\f1c1";
$fa-bars : "\f0c9";
$fa-times : "\f00d";
$fa-search : "\f002";
$fa-caret-right: "\f0da";
$fa-caret-down: "\f0d7";
$fa-caret-left: "\f0d9";
$fa-caret-up: "\f0d8";
$fa-chevron-down: "\f078";
$fa-chevron-up: "\f077";
$fa-plus: "\f067";
$fa-minus: "\f068";
$fa-chevron-right : "\f054";
$fa-chevron-left : "\f053";
$fa-book : "\f02d";
$fa-square  : "\f0c8";
$fa-twitter : "\f099";
$fa-facebook :"\f09a";
$fa-envelope :"\f0e0";
$fa-bars : "\f0c9";
$fa-remove:"\f00d";
$fa-pencil : "\f040";
$fa-check :"\f00c";
$fa-circle-o : "\f10c";
$fa-dot-circle-o : "\f192";
$fa-close : "\f00d";
$fa-map-marker:"\f041";
$fa-microphone :"\f130";
$fa-external-link :"\f08e";
$fa-car :"\f1b9";
$fa-train :"\f238";
$fa-bus :"\f207";
$fa-subway :"\f239";
$fa-chevron-circle-right :"\f138";
$fa-chevron-circle-up :"\f139";
$fa-chevron-circle-down :"\f13a";

// width
$baseWidth: 980px;
$baseWidthWide: 1160px;

// point
$bpTabletL: 979px;
$bpTabletP: 768px;
$bpSphoneP: 480px;