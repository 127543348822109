@charset "utf-8";

/* other
------------------------------------------------------------*/
/*↓消さないこと*/
.modLoading{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:rgba(255,255,255,0.8);
	
	i{
		@include fz(24);
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-0.5em;
		margin-top:-0.5em;
	}
}
/*↑消さないこと*/

.modCategory{
    border-radius:3px;
    @include inline-block;
    padding:0 4px;
    @include fz(10);
    color:#fff;
    vertical-align:middle;

    $categoryLen:6;

    @for $i from 1 through $categoryLen {
      &0#{$i} {
        @extend .modCategory;
        background-color:nth($newCateColor,$i);
      }
    }
}

.modAncWrapper{
    margin-top:-94px;
    padding-top:94px;
    @include tabletP{
        margin-top:0!important;
        padding-top:0!important;
    }
}

.modLazy{
    background-image: url(../img/ajax-loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    &:hover{
        background-image:none;
    }

    &.show{
        background-image: none;
    }

    img{
        opacity: 0;
    }
/* .modLazy */}