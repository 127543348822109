@charset "utf-8";

/* modTable
------------------------------------------------------------*/


.modTbl01{
    border:solid 1px #A0A0A0;
    margin: 0 0 50px;
    @include sphoneP{
        margin-bottom: 20px;
    }
    
    &_fixed{
        table-layout: fixed;
    }
    
    th,td{
        padding: 20px 26px;
        @include sphoneP{
            padding: 10px;
        }
    }
    th{
        background-color: $mainColor03;
        color:#fff;
        @include fz(20);
        @include sphoneP{
            @include fz(12);
        }
    }
    
    thead{
        th{
            border-right:dotted 2px #fff;
			border-bottom:solid 1px #fff;
            &:last-child{
                border-right: none;
            }
        }
    }
    
    tbody{
        th{
            border-bottom:dotted 2px #fff;
        }
        
        td{
            background-color: #fff;
            border-bottom:dotted 2px #CFB02D;
            border-right:dotted 2px #CFB02D;
            color:$subColor02;
            @include fz(18);
            @include sphoneP{
                @include fz(12);
            }
            &:last-child{
                border-right: none;
            }
        }
        
        tr{
            &:last-child{
                th,td{
                    border-bottom: none;
                }
            }
        }
    }
    
    &_type02{
        tbody{
            
            th{
                background-color: #fff;
            	border-right:dotted 2px #CFB02D;
                border-bottom-color:#CFB02D;
                color:$mainColor03;
                @include fz(18); 
                text-align: center;
                vertical-align: top; 
                @include sphoneP{
                    @include fz(12);
                }             
            }
        
        }
        .subTitle {
          th  {
            padding:0;
            border-right:none;
            .title {
              position:relative;
              background:#f0ebe2;
              color:$subColor02;
              border-bottom:1px solid #c4b498;
              margin:-2px 0;
              padding: 20px 26px;
              text-align:left;
              
              @include sphoneP{
                padding: 10px 16px;
              }
            }
          }
        }
    
    }
    
    .valignTop{
        vertical-align: top;
    }
}

.modTbl_scroll {
  margin-bottom:20px;    

  @include sphoneP{
    @include clearfix;
    background:url(../img/scroll.png) no-repeat bottom center;
    background-size:100% auto;
    padding-bottom:50px;
  }
}
.modTbl02 {
  
    width:100%;
    border:solid 1px #c5c5c5;
    font-family:$fontMincho;
    background:#fff;

    &_head {
      border-bottom:none;
    }
    &_body {
      border-top:none;
    }
    @include sphoneP{
      
      tr {
        &,th,td {
          display:block;
        }
      }
      
      &_head {
        float:left;
        width:25%;
        border-bottom:#c5c5c5;
        border-right:none;
      }
      &_body {
        float:right;
        width:75%;
        overflow-x: auto;
        border-top:#c5c5c5;
        border-left:none;
        
        table {
          width:100%;
          white-space: nowrap;
          display:table;
        }
        
        tbody {

          tr {
            width:auto;
            display:table-cell;
          }
        }
      }
    }
    th,td{
        padding: 15px 5px;
        box-sizing:border-box;
        background:#fff;
        @include fz(18);
        @include sphoneP{
          padding:5px;
          @include fz(12);
        }
    }
    th {
      @include sphoneP{
        height:80px;
      }
    }
    td {
      width:11%;
      @include sphoneP{
        width:100%;
        height:30px;
      }
    }
    thead{
        th,td{
          background-color: $mainColor03;
          border-right:dotted 2px #fff;
          color:#fff;
          text-align:center;
          &:last-child{
              border-right: none;
          }
          @include sphoneP{
            border-right:none;
            border-bottom:dotted 1px #fff;
            &:last-child{
                border-bottom: none;
            }
          }
        }
    }
    tbody{
        th,td{
            border-right:dotted 2px #CFB02D;
            border-bottom:dotted 2px #CFB02D;
            @include sphoneP{
              border-right:dotted 1px #CFB02D;
              border-bottom:dotted 1px #CFB02D !important;
              &:last-child{
                  border-bottom: none !important;
              }
            }
        }
        th{
          padding:15px;
          color:$mainColor03;

          @include sphoneP{
            width:100%;
            height:80px;
            padding:5px;
            box-sizing:border-box;
            text-align:center;
          }
          
          span {
            display:block;
            margin-top:10px;
            @include fz(14);
            color:#726261;
            
            @include sphoneP{
              @include fz(12);
            }
          }
        }
        
        td{
            background-color: #fff;
            text-align:center;
            color:$subColor02;
            line-height:1.4;

            &:last-child{
                border-right: none;
                @include sphoneP{
                  border-right:dotted 1px #CFB02D;
                }
            }
            span {
              display:block;
              @include sphoneP{
                display:inline;
              }
            }
            span {
              display:block;
              @include sphoneP{
                display:inline;
              }
            }
            &.reserve {
              background:#fff6f9;
              strong {
                color:$mainColor03;
                display:block;
                margin-bottom:5px;
                @include sphoneP{
                  display:inline-block;
                  margin:0 5px 0 0;
                }
              }
              .small {
                @include fz(12);
                border-radius:3px;
                margin-top:5px;
                
                @include sphoneP{
                  margin-top:0;
                  margin-left:5px;
                  @include fz(10);
                }
              }
            }
            &.sub {
              span {
                @include fz(16);
                color:#928383;
                @include sphoneP{
                  @include fz(10);
                }
              }
            }
        }
        tr{
            &:last-child{
                th,td{
                    border-bottom: none;
                    @include sphoneP{
                      border-right:none;
                    }
                }
            }
        }
    }
	
	&_type02 {
		thead{
			th,td{
			  border-right:dotted 2px #fff;
			}
		}
		tbody{
			th,td{
			  border-right:dotted 2px #CFB02D;
			  border-bottom:dotted 2px #CFB02D;
			  color:$mainColor;
			}
			th {
			  @include fz(16);
			}
		}
	}		
}
.modTbl03 {
  border:1px solid #58a0c0;
  border-collapse:separate;
  border-radius:5px;
  margin:20px auto;
  width:90%;
  @include fz(20);

  @include sphoneP{
    @include fz(14);
    
  }
  
  caption {
    color:#308cb8;
    font-weight:bold;
  }
  th,
  td {
    padding:20px;
    
    @include sphoneP{
      padding:10px;
      display:block;   
    }
  }
  
  th {
    background:#58a0c0;
    font-family:$fontMincho;
    color:#fff;
    text-align:center;
    
  }
  td {
    color:#58a0c0;
    font-family:$fontMincho;    
  }
  
}
.modTbl04 {
  background:#fff;

  th,
  td {
    padding:10px;
    
    @include sphoneP{
      padding:10px;
      display:block;
      box-sizing:border-box;
    }
  }
  
  th {
    width:20%;
    background:$subColor03;
    color:$subColor02;
    font-weight:bold;
    border:1px solid #d2d2d2;
    vertical-align:top;

    @include sphoneP{
      width:100%;
    }
  }
  td {
    border:1px solid #d2d2d2;
  }
    
}

